$button-duration: 500ms;
$button-text-color: $purple;
$button-text-color-white: white;
$button-line-color: $purple;
$button-line-color-white: $white;
$button-padding: 10px 0;

a.new-button,
.new-button,
.fui-btn-container,
{
    display: inline-block;

    text-decoration: none;

    /* IE11 */
    @media all and (-ms-high-contrast:none)
    {
        position: relative;

        height: 105%;
        *::-ms-backdrop
        {
            position: relative;
        }
    }
    //old form contact button is an input
    //ex. <input type="submit" name="submit" value="Submit" class="submit button">
    input[type='submit'],
    button[type='submit'],
    a
    {
        @include button-sofia-pro;
        @include padding(0);

        cursor: hand;

        color: $button-text-color;
        border: 0 solid transparent;
        outline: none;
        background-color: transparent;

        -webkit-appearance: button;

        + .underline
        {
            margin-top: -15px;

            /* IE11 */
            @media all and (-ms-high-contrast:none)
            {
                height: 105%;
                margin-top: -12%;
                *::-ms-backdrop
                {
                    margin-top: -12%;
                }
            }
        }
    }
    span
    {
        @include button-sofia-pro;

        font-size: 14px;
        line-height: 22px;

        letter-spacing: 4px;

        color: $button-text-color;
    }
    > .underline
    {
        margin-right: -4%;
        margin-left: -2%;

        /* IE11 */
        @media all and (-ms-high-contrast:none)
        {
            position: absolute;
            top: -20%;
            right: auto;
            left: auto;

            width: 110%;
            height: 100%;
            margin-right: 0;
            margin-left: -2%;

            transform: translateY(100%);
            *::-ms-backdrop
            {
                position: absolute;
                top: -20%;
                right: auto;
                left: auto;

                width: 110%;
                height: 100%;
                margin-right: 0;
                margin-left: -2%;

                transform: translateY(100%);
            }
        }

        .underline_wrapper
        {
            .path-underline
            {
                opacity: 0;

                fill: none;
                stroke: $button-line-color;
                stroke-width: 1px;
                stroke-linecap: round;
                stroke-miterlimit: 10;
            }
            #wiggle-one
            {
                opacity: 1;
            }
            #wiggle-two
            {
                opacity: 0;
            }
            #wiggle-three
            {
                opacity: 0;
            }
            #wiggle-four
            {
                opacity: 0;
            }
            #wiggle-five
            {
                opacity: 0;
            }
        }
    }
    &:hover
    {
        cursor: hand;
        .underline
        {
            .underline_wrapper
            {
                .path-underline
                {
                    // opacity: 0;
                }
                #wiggle-one
                {
                    animation: wiggle-1 $button-duration ease infinite;
                }
                #wiggle-two
                {
                    animation: wiggle-2 $button-duration ease infinite;
                }
                #wiggle-three
                {
                    animation: wiggle-3 $button-duration ease infinite;
                }
                #wiggle-four
                {
                    animation: wiggle-4 $button-duration ease infinite;
                }
                #wiggle-five
                {
                    animation: wiggle-5 $button-duration ease infinite;
                }
            }
        }
    }
    &.white
    {
        input[type='submit']
        {
            color: $button-text-color-white;
        }
        span
        {
            color: $button-text-color-white;
        }
        .underline
        {
            .underline_wrapper
            {
                .path-underline
                {
                    stroke: $button-line-color-white;;
                }
            }
        }
    }
}

.touch.safari-10
{
    .underline_wrapper
    {
        svg
        {
            width: 200px;
        }
    }
}



.ie-11
{
    .underline_wrapper
    {
        svg
        {
            width: 200px;
            margin-top: -50px;
        }
    }
}

.edge-16,
.edge-15
{
    .new-homepage
    {
        .underline_wrapper
        {
            svg
            {
                width: 200px;
                margin-top: 10px;
            }
        }
    }
}

@keyframes wiggle-1
{
    0%
    {
        opacity: 1;
    }
    20%,
    40%
    {
        opacity: 0;
    }
    41%,
    60%
    {
        opacity: 0;
    }
    61%,
    80%
    {
        opacity: 0;
    }
    81%,
    100%
    {
        opacity: 0;
    }
}
@keyframes wiggle-2
{
    0%
    {
        opacity: 0;
    }
    20%,
    40%
    {
        opacity: 1;
    }
    41%,
    60%
    {
        opacity: 0;
    }
    61%,
    80%
    {
        opacity: 0;
    }
    81%,
    100%
    {
        opacity: 0;
    }
}
@keyframes wiggle-3
{
    0%
    {
        opacity: 0;
    }
    20%,
    40%
    {
        opacity: 0;
    }
    41%,
    60%
    {
        opacity: 1;
    }
    61%,
    80%
    {
        opacity: 0;
    }
    81%,
    100%
    {
        opacity: 0;
    }
}
@keyframes wiggle-4
{
    0%
    {
        opacity: 0;
    }
    20%,
    40%
    {
        opacity: 0;
    }
    41%,
    60%
    {
        opacity: 0;
    }
    61%,
    80%
    {
        opacity: 1;
    }
    81%,
    100%
    {
        opacity: 0;
    }
}
@keyframes wiggle-5
{
    0%
    {
        opacity: 0;
    }
    20%,
    40%
    {
        opacity: 0;
    }
    41%,
    60%
    {
        opacity: 0;
    }
    61%,
    80%
    {
        opacity: 0;
    }
    81%,
    100%
    {
        opacity: 1;
    }
}
