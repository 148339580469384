header[role='header']
{
    @include position(fixed, 0 null null 0);

    z-index: 5000;

    width: 100vw;
    //fixes horizontal scrollbar appear bug
    max-width: 100%;

    .logo
    {
        @include animate;
        @include position(absolute, 5px null null 10px);
        @include padding(.5vh .5vw);

        text-decoration: none;

        &.hide
        {
            opacity: 0;
        }

        &:hover
        {
            span
            {
                color: $dark-purple;
            }
        }

        span
        {
            @include font-sofia-pro-bold;

            font-size: .88vw;

            transition: 250ms;
            letter-spacing: .3vw;

            color: $purple;

            @media (max-width: 1024px)
            {
                font-size: 12px;

                letter-spacing: 4px;
            }

            @media (max-width: 480px)
            {
                font-size: 12px;

                letter-spacing: 4px;
            }
        }
    }
}

.p-hamburger-icon
{
    position: absolute;
    top: 0;
    right: 0;

    overflow: hidden;

    width: 180px;
    height: 180px;

    cursor: pointer;
    transition: 250ms;
    transform: translateY(-60%) translateX(60%) rotateZ(45deg);

    background-color: $purple;
    &:after
    {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: '';

        box-shadow: inset 0 -5px 5px rgba(0, 0, 0, .25);
    }
    .p-hamburger-icon-container
    {
        @include position(absolute, 50% null null 50%);

        width: 50px;
        height: 50px;

        transition: 250ms;
        transform: translateY(100%) translateX(-50%) rotateZ(-45deg);
    }
    &:hover
    {
        @media (min-width: 481px)
        {
            transform: translateY(-50%) translateX(50%) rotateZ(45deg);
            .p-hamburger-icon-container
            {
                transform: translateY(50%) translateX(-50%) rotateZ(-45deg);
            }
        }
    }
}

.p-icon
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    animation: p-slide-out .5s ease-in-out .5s forwards;

    opacity: 1;

    @media all and (-ms-high-contrast:none)
    {
        top: -100%; /* IE10 */
        *::-ms-backdrop
        {
            top: -100%;
        } /* IE11 */
    }

    .color
    {
        fill: $white;
    }
}

//nav icon class sits on root because it lives in two places
.nav-icon
{
    position: relative;

    width: 31px;
    height: 35px;
    margin: 10px auto;

    cursor: pointer;
    &#top-level
    {
        animation: nav-slide-in .5s ease-in-out .5s forwards;

        opacity: 0;
    }

    &:hover
    {
        span
        {
            background: $yellow;
        }
    }

    span
    {
        @include position(absolute, null null null 0);

        display: block;

        width: 100%;
        height: 4px;

        transition: 250ms;

        opacity: 1;
        border-radius: 9px;
        background: $white;

        &:nth-child(1)
        {
            top: 0;

            width: 86%;

            transition: .25s ease-in-out;

            opacity: 1;
        }
        &:nth-child(2),
        &:nth-child(3)
        {
            @include transform(rotate(0deg));

            top: 10px;

            transition: .25s ease-in-out;
        }
        &:nth-child(4)
        {
            top: 20px;

            width: 50%;

            transition: .25s ease-in-out;

            opacity: 1;
        }
    }

    &[data-view-state='open']
    {
        span
        {
            &:nth-child(1)
            {
                top: 10px;

                animation: nav-top-bottom-move-fade-out .25s ease-in-out forwards;
            }
            &:nth-child(2)
            {
                animation: nav-middle-left-open .25s ease-in-out .25s forwards;
            }
            &:nth-child(3)
            {
                animation: nav-middle-right-open .25s ease-in-out .25s forwards;
            }
            &:nth-child(4)
            {
                top: 10px;

                animation: nav-top-bottom-move-fade-out .25s ease-in-out forwards;
            }
        }
    }

    &[data-view-state='opened']
    {
        span
        {
            &:nth-child(1)
            {
                top: 10px;
            }
            &:nth-child(2)
            {
                @include transform(rotate(45deg));
            }
            &:nth-child(3)
            {
                @include transform(rotate(-45deg));
            }
            &:nth-child(4)
            {
                top: 10px;
            }
        }
    }

    &[data-view-state='close']
    {
        span
        {
            &:nth-child(1)
            {
                animation: nav-top-bottom-move-fade-in;
                animation-delay: 2s;
            }
            &:nth-child(2)
            {
                animation: nav-middle-left-close .25s ease-in-out forwards;
            }
            &:nth-child(3)
            {
                animation: nav-middle-right-close .25s ease-in-out forwards;
            }
            &:nth-child(4)
            {
                animation: nav-top-bottom-move-fade-in;
                animation-delay: 2s;
            }
        }
    }
}

//navigation tag container
nav
{
    .nav-icon
    {
        top: 20px;
        right: 19px;

        margin: 0 auto;
        margin-right: 0;
    }
    .logo
    {
        &.white
        {
            &:hover
            {
                span
                {
                    color: $yellow !important;
                }
            }
            span
            {
                transition: 500ms ease;

                color: $white !important;
            }
        }
    }
}

@keyframes nav-top-bottom-move-fade-out
{
    0%
    {
        opacity: 1;
    }
    99%
    {
        opacity: 1;
    }
    100%
    {
        opacity: 0;
    }
}

@keyframes nav-top-bottom-move-fade-in
{
    0%
    {
        opacity: 0;
    }
    1%
    {
        opacity: 1;
    }
    100%
    {
        opacity: 1;
    }
}

@keyframes nav-middle-left-open
{
    0%
    {
        @include transform(rotate(0deg));
    }
    100%
    {
        @include transform(rotate(45deg));
    }
}

@keyframes nav-middle-right-open
{
    0%
    {
        @include transform(rotate(0deg));
    }
    100%
    {
        @include transform(rotate(-45deg));
    }
}

@keyframes nav-middle-left-close
{
    0%
    {
        @include transform(rotate(45deg));
    }
    100%
    {
        @include transform(rotate(0deg));
    }
}

@keyframes nav-middle-right-close
{
    0%
    {
        @include transform(rotate(-45deg));
    }
    100%
    {
        @include transform(rotate(0deg));
    }
}

@keyframes nav-slide-in
{
    0%
    {
        transform: translateX(-120px) translateY(0) translateZ(0);

        opacity: 0;
    }
    100%
    {
        transform: translateX(0) translateY(0) translateZ(0);

        opacity: 1;
    }
}

@keyframes p-slide-out
{
    0%
    {
        transform: translateX(0) translateY(0) translateZ(0);

        opacity: 1;
    }
    100%
    {
        transform: translateX(120px) translateY(0) translateZ(0);

        opacity: 0;
    }
}
