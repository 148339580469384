.vertical-slider
{
    position: relative;
    @media($phone-and-tablet)
    {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;

        .background-slider
        {
            display: none;
        }
    }
    @media($desktop-only)
    {
        width: 100vw;
        height: 100vh;
        min-height: 100vh;
        padding-top: 7vh;

        .background-slider
        {
            position: absolute;
            top: 0;
            left: 0;

            width: 100% !important;
            height: 100%;
            margin-left: 0 !important;
            &:after
            {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                content: '';

                background-color: rgba($black, .9);
            }
            .background-slider-container
            {
                > div
                {
                    width: 100vw;
                    height: 100vh;
                }
            }
            .tns-outer
            {
                button
                {
                    display: none;
                }
            }
        }
    }
    .block__wrapper
    {
        position: relative;
        z-index: 1;
        @media($phone-and-tablet)
        {
            width: 100% !important;
            margin-left: 0 !important;
            padding-left: 0;
        }
        .title
        {
            color: $yellow !important;
            @media($phone-and-tablet)
            {
                margin-bottom: 90px;
            }
            @media($desktop-only)
            {
                margin-bottom: 7%;
            }
        }
        ul.vertical-accordion
        {
            margin-left: 0;
            padding-left: 0;

            list-style: none;
            @media($desktop-only)
            {
                @include columnizer(6, 3, $collapse: true);
            }
            li
            {
                color: $purple-grey;

                @media($phone-only)
                {
                    padding: 40px 8%;
                }
                @media($tablet-only)
                {
                    padding: 40px 5%;
                }
                @media($phone-and-tablet)
                {
                    position: relative;
                    &:last-child
                    {
                        padding-bottom: 150px;
                    }
                    &:after
                    {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;

                        width: 100%;
                        height: 100%;

                        content: '';

                        background-color: rgba($black, .9);
                    }
                }

                @media($desktop-only)
                {
                    position: relative;

                    background: none !important;

                    + li
                    {
                        .item-title
                        {
                            margin-top: 10px;
                        }
                    }
                }

                &.active
                {
                    &:not(:first-child)
                    {
                        .item-info
                        {
                            a.arrow
                            {
                                @media($desktop-only)
                                {
                                    top: 0;
                                }
                            }
                        }
                    }
                    .item-title
                    {
                        color: $white;

                        > a
                        {
                            + div
                            {
                                @include animate;
                            }
                            @media($desktop-only)
                            {
                                position: absolute;
                                top: 0;
                                left: 0;

                                width: 100%;
                                height: 100%;

                                &:hover
                                {
                                    + div
                                    {
                                        color: $grey;
                                    }
                                }
                            }
                        }
                    }
                    .item-info
                    {
                        visibility: visible;

                        opacity: 1;
                        color: $white;
                        > p
                        {
                            color: $white !important;
                        }
                        .arrow
                        {
                            @media($desktop-only)
                            {
                                visibility: visible;

                                opacity: 1;
                            }
                        }
                    }
                }
                .item-title
                {
                    @include animate;
                    @include t-h2;

                    position: relative;
                    z-index: 2;

                    text-decoration: none;

                    color: $purple-grey;

                    @media($phone-and-tablet)
                    {
                        color: $white;
                    }
                }
                .item-info
                {
                    @include animate;

                    z-index: 2;

                    @media($phone-and-tablet)
                    {
                        position: relative;

                        color: $white !important;
                    }

                    @media($desktop-only)
                    {
                        visibility: hidden;

                        opacity: 0;
                    }

                    .client-industry
                    {
                        @include t-slider-label;

                        margin-top: 10px;

                        > div
                        {
                            display: inline-block;

                            + div
                            {
                                margin-left: 40px;
                            }
                        }
                    }
                    > p
                    {
                        margin-bottom: 0 !important;

                        @media($phone-and-tablet)
                        {
                            color: $white !important;
                        }

                        @media($desktop-only)
                        {
                            &:last-child
                            {
                                margin-bottom: 40px;
                            }
                        }
                    }
                    a.arrow
                    {
                        display: block;

                        width: 42px;
                        height: 42px;
                        margin-top: 10px;

                        text-decoration: none;

                        @media($desktop-only)
                        {
                            position: absolute;
                            top: -10px;
                            left: 0;

                            visibility: hidden;

                            transform: translateX(-160%);

                            opacity: 0;
                        }

                        .arrow-round-icon
                        {
                            position: relative;

                            display: block;

                            width: 100%;
                            height: 100%;

                            &:before
                            {
                                @include animate;
                                @include fontello($icon-right);

                                font-size: 100%;

                                display: flex;

                                width: 100%;
                                height: 100%;

                                opacity: 1;
                                color: $white;
                                border-radius: 50%;
                                background-color: $purple;
                                background-color: rgba($purple, 1);

                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .new-button-container
    {
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100% !important;
        margin: 0 !important;

        a.new-button
        {
            @include new-button($purple-grey);
        }
    }
}
