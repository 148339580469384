$global-nav-height: 85px;
$global-nav-height-condensed: 60px;
header
{
    &.global-nav
    {
        position: fixed;
        z-index: 999;

        max-width: 100%;
        height: $global-nav-height;
        .container
        {
            padding: 0;
        }
        .top-bar
        {
            @include shadow;

            position: absolute;

            width: 100%;
            height: $global-nav-height;

            transition: all .5s ease;

            background-color: $white;
            .title-area
            {
                float: left;
                // width:50%;

                padding-left: 50px;
                .name
                {
                    .logo
                    {
                        float: left;
                        a
                        {
                            width: 140px;
                            height: $global-nav-height;

                            transition: all .5s ease;
                            text-indent: -9999px;

                            background-image: url('../images/ui/pennebaker-red.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            .no-svg
                            {
                                background-image: url('../images/ui/pennebaker-red.png');
                            }
                        }
                    }
                    .tagline
                    {
                        font-family: $header-font-family;
                        font-size: 1.3em;
                        line-height: $global-nav-height;

                        float: left;

                        margin: 0;
                        margin-left: 30px;

                        opacity: 1;
                        color: $dark-grey;
                    }
                }
            }
        }
        &.condensed
        {
            height: $global-nav-height-condensed;
            .top-bar
            {
                height: $global-nav-height-condensed;
                .title-area .name
                {
                    height: $global-nav-height-condensed;
                    .logo a
                    {
                        height: $global-nav-height-condensed;
                    }
                    .tagline
                    {
                        line-height: $global-nav-height-condensed;

                        opacity: 0;
                    }
                }
                .top-bar-section
                {
                    li:not(.has-form) a:not(.button)
                    {
                        line-height: $global-nav-height-condensed;
                    }
                    .nav-items
                    {
                        height: $global-nav-height-condensed;
                    }
                }
            }
        }
    }
}
.plus
{
    #plus-icon
    {
        z-index: 9999;

        display: block;

        width: 30px;
        height: 60px;

        transition: all 500ms ease-in-out;

        fill: $grey;
        span:before,
        span:after
        {
            position: absolute;
            top: 17px;

            display: block;

            width: 30px;
            height: 3px;

            content: '';
            cursor: pointer;
            transition: all 500ms ease-in-out;

            border-radius: 1px;
            background: $grey;
        }
        span:before
        {
            transform: rotate(90deg) !important;
        }
        span:after
        {
            transform: rotate(0deg) !important;
        }
    }
    &.active
    {
        #plus-icon
        {
            transform: rotate(45deg);
        }
        #plus-icon span:before
        {
            transform: rotate(45deg) !important;
        }
        #plus-icon span:after
        {
            transform: rotate(-45deg) !important;
        }
    }
}
@keyframes fadeIn
{
    0%
    {
        display: none;

        opacity: 0;
    }
    1%
    {
        display: block;

        opacity: 0;
    }
    100%
    {
        display: block;

        opacity: 1;
    }
}
.global-nav .nav-overlay
{
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: none;

    opacity: 0;
    background-color: $black-65;

    // -webkit-transition: opacity 0.5s ease 0s, z-index 0s ease 0.5s;
    // -o-transition: opacity 0.5s ease 0s, z-index 0s ease 0.5s;
    // transition: opacity 0.5s ease 0s, z-index 0s ease 0.5s;
    &.active
    {
        display: block;

        -webkit-animation-name: fadeIn;
        -webkit-animation-duration: .5s;

        opacity: 1;
    }
}

// Megamenu Mega Menu
nav#navigation
{
    @include position(fixed, 0 null null 0);

    visibility: hidden;

    width: 100vw;
    //fixes horizontal scrollbar appear bug
    max-width: 100%;
    height: 100vh;

    pointer-events: none;

    opacity: 0;

    &[data-view-state='open']
    {
        // display: block;
        visibility: visible;

        pointer-events: auto;

        opacity: 1;
    }
    &[data-view-state='closing']
    {
        // display: block;
        visibility: visible;

        pointer-events: none;

        opacity: 1;
    }
    &[data-view-state='close']
    {
        visibility: hidden;

        pointer-events: none;
        // display: none;

        opacity: 0;
    }

    .new-mega-menu
    {
        @include transform(skewY(45deg));

        font-size: 96px;

        position: absolute;
        top: -300vh;
        right: 0;
        left: 0;

        overflow: hidden;

        margin: 0 auto;

        text-align: center;

        background: $purple;

        &.open
        {
            animation: unmask 1s forwards;
        }
        &.closing
        {
            animation: unmask 1s reverse;
        }
        &.close
        {
            height: 12vh;
        }

        .new-mega-menu-container
        {
            @include position(absolute, 300vh 0 null 0);
            @include margin(0 auto);
            @include transform(skewY(-45deg));

            height: 100vh;
            @media (min-width: 1025px)
            {
                display: flex;

                align-items: center;
            }

            .new-mega-menu-wrapper
            {
                @-moz-document url-prefix()
                {
                    width: 100%;
                }
                @media (min-width: 1025px)
                {
                    @include columnizer(1.35 6.9 6.75, 0 .5 .5, $set-neat-grid: $neat-grid-bleed, $set-max-width: false);

                    display: flex;

                    width: 100%;

                    align-items: center;
                }
                @media (max-width: 1024px)
                {
                    @include columnizer(0 12 12, 0 0 -12, $set-neat-grid: $neat-grid-bleed, $set-max-width: false);
                }

                .social-menu
                {
                    // line-height: 2.5;
                    position: relative;

                    text-align: center;

                    @media (max-width: 1024px)
                    {
                        @include position(absolute, null 0 0);
                        @include margin(0);

                        z-index: 3;

                        width: 100% !important;
                        height: 60px;

                        background: $purple;
                    }

                    .social-menu__wrapper
                    {
                        //icons
                        div
                        {
                            font-size: 50px;
                            @media (max-width: 1024px)
                            {
                                display: inline-block;
                            }
                            a
                            {
                                text-decoration: none;

                                color: $white;

                                @media (max-width: 1024px)
                                {
                                    @include padding(0 12px);
                                    // font-size: 2.5em;

                                    position: relative;
                                }

                                &:after
                                {
                                    font-size: 1.18vw;

                                    display: inline-block;

                                    width: 100%;

                                    content: '';
                                    text-align: center;

                                    color: $white;

                                    @media (max-width: 1024px)
                                    {
                                        font-size: 16px;

                                        position: absolute;
                                        top: -50%;
                                        left: -50%;

                                        transform: translateX(50%);
                                    }
                                }
                                &:hover
                                {
                                    &:after
                                    {
                                        color: $yellow;
                                    }
                                }

                                &.icon-facebook
                                {
                                    &:after
                                    {
                                        @include fontello($icon-facebook);
                                    }
                                }
                                &.icon-twitter
                                {
                                    &:after
                                    {
                                        @include fontello($icon-twitter);
                                    }
                                }
                                &.icon-linkedin
                                {
                                    &:after
                                    {
                                        @include fontello($icon-linkedin);
                                    }
                                }
                                &.icon-pinterest
                                {
                                    &:after
                                    {
                                        @include fontello($icon-pinterest);
                                    }
                                }
                            }
                        }
                    }
                    .social-menu__border
                    {
                        @include position(absolute, 50% 0 null null);
                        @include transform(translateY(-50%));

                        height: 70vh;

                        border-right: 1px solid $dark-purple;

                        @media (max-width: 1024px)
                        {
                            @include transform(translateX(50%));

                            top: 0;
                            right: 50%;

                            width: 60%;
                            height: auto;

                            border-top: 1px solid $dark-purple;
                            border-right: none;
                        }
                    }
                }
                .main-menu
                {
                    @media (max-width: 1024px)
                    {
                        @include position(absolute, 60px null);
                        @include transform(translateX(-25vw));

                        overflow: auto;
                        overflow-y: scroll;

                        height: auto;

                        transition: 250ms ease-out;

                        opacity: 0;

                        -webkit-overflow-scrolling: touch;

                        &.show
                        {
                            @include transform(translateX(0));

                            width: 100%;

                            opacity: 1;
                        }
                    }
                    .main-menu__wrapper
                    {
                        font-size: 0;

                        @media (max-width: 1024px)
                        {
                            @include position(inherit, 0);
                            @include transform(none);
                            @include padding(32px null);
                        }

                        > div
                        {
                            display: table;

                            width: 100%;

                            @media (max-width: 1024px)
                            {
                                display: block;

                                text-align: center;
                            }

                            + div
                            {
                                margin-top: 20px;
                            }
                            a
                            {
                                @include font-noe-display-black;

                                font-size: 3.65vw;

                                transition: color $transition-speed $transition-easing;
                                white-space: nowrap;
                                text-decoration: none;

                                color: $white;

                                @media (max-width: 1024px)
                                {
                                    @include padding(10px null);

                                    font-size: 32px;
                                    line-height: 46px;

                                    display: inline-block;
                                }

                                &:hover
                                {
                                    color: $yellow;
                                }
                            }
                            &.show
                            {
                                a
                                {
                                    color: $yellow;
                                }
                                &:after
                                {
                                    opacity: 1;
                                }
                            }
                            &:after
                            {
                                @include position(relative, 50% null null null);

                                left: 20px;

                                display: table-cell;

                                width: 100%;

                                content: '';
                                transition: all $transition-speed $transition-easing;
                                transform: translateY(50%);

                                opacity: 0;
                                // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAABAQMAAAASFptnAAAAA1BMVEUxMTEVULX1AAAAC0lEQVQI12MYigAAALUAATljZRkAAAAASUVORK5CYII=');
                                // background-repeat: no-repeat;
                                // background-position: left rem(40px) top 52%;
                                border-top: 1px solid $dark-purple;

                                @media (max-width: 1024px)
                                {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .side-menu
                {
                    position: relative;

                    @media (max-width: 1024px)
                    {
                        @include position(absolute, 60px null 0);

                        z-index: 2;

                        visibility: hidden;
                        overflow: auto;
                        overflow-y: scroll;

                        height: auto;
                        margin-left: 0;

                        background-color: $purple;

                        -webkit-overflow-scrolling: touch;

                        &.show
                        {
                            transform: translateX(100%);
                        }

                        .side-header
                        {
                            @include position(absolute, 0 0 null);
                            @include transform(none);
                            @include font-sofia-pro;

                            font-size: 10px;
                            line-height: 18px;

                            width: 100%;
                            height: 60px;

                            transition: 250ms ease;
                            transform: translateX(0);
                            letter-spacing: 3px;
                            text-transform: uppercase;

                            opacity: 0;
                            &.show
                            {
                                @include position(absolute, 0 0 null);

                                transform: translateX(0);

                                opacity: 1;
                            }
                            a
                            {
                                display: block;

                                text-decoration: none;

                                color: $white;

                                &:hover
                                {
                                    color: $yellow;
                                }
                                &[href='#back']
                                {
                                    box-sizing: border-box;
                                    height: 60px;

                                    border-bottom: 1px solid $dark-purple;
                                    &:before
                                    {
                                        @include position(relative, 1px null null null);
                                        @include margin(null 16px null -24px);
                                        @include fontello($icon-angle-left);
                                    }
                                }
                            }
                        }
                    }
                    > *
                    {
                        @include position(absolute, 50% 0 null 0);
                        @include transform(translateY(-50%));

                        z-index: 1;

                        visibility: hidden;

                        transition: visibility 250ms, opacity 250ms linear; // Double () on purpose weird sass issue not sending as a list

                        opacity: 0;

                        @media (max-width: 1024px)
                        {
                            @include transform(none);
                            @include position(absolute, 60px null 0);
                            @include margin(null auto);

                            transition: visibility 100ms, opacity 100ms linear, transform 100ms ease; // Double () on purpose weird sass issue not sending as a list
                            pointer-events: none;

                            background-color: $purple;
                        }
                        &.show
                        {
                            z-index: 2;

                            visibility: visible;

                            transition: visibility 0ms, opacity 125ms linear; // Double () on purpose weird sass issue not sending as a list

                            opacity: 1;
                        }
                        @media (max-width: 1024px)
                        {
                            &.show,
                            &.hover
                            {
                                @include transform(none);
                                @include position(absolute, 60px null 0);
                                @include margin(null auto);

                                transition: visibility 0ms, opacity 50ms linear, transform 50ms ease; // Double () on purpose weird sass issue not sending as a list;
                                pointer-events: auto;
                            }
                        }
                        &#capabilities,
                        &#work
                        {
                            @include font-sofia-pro;

                            font-size: 1.5vw;

                            min-height: 325px;

                            @media (max-width: 1024px)
                            {
                                font-size: 20px;
                                line-height: 3;

                                overflow: auto;
                                overflow-y: scroll;

                                min-height: 0;
                                padding-top: 8px;

                                -webkit-overflow-scrolling: touch;
                            }
                            a
                            {
                                display: block;

                                text-align: left;
                                text-decoration: none;

                                color: $white;

                                @media (max-width: 1024px)
                                {
                                    @include margin(null auto);

                                    font-size: 18px;

                                    width: 75%;

                                    text-align: center;
                                }

                                &:hover
                                {
                                    @media (min-width: 768px)
                                    {
                                        color: $yellow;
                                    }
                                }
                                &[href='/work']
                                {
                                    display: none;

                                    @media (max-width: 1024px)
                                    {
                                        display: block;

                                        border-bottom: 1px solid $dark-purple !important;
                                    }
                                }
                            }
                            @media (max-width: 1024px)
                            {
                                > a
                                {
                                    padding-top: 20px;
                                    padding-bottom: 20px;

                                    &:not(:last-child)
                                    {
                                        // margin-bottom: 20px;
                                    }
                                }
                                > div
                                {
                                    &.hide
                                    {
                                        display: none;
                                    }
                                    &.show
                                    {
                                        > a
                                        {
                                            border-bottom: 1px solid $dark-purple !important;
                                            // padding-top: 20px;
                                            // padding-bottom: 20px;
                                        }
                                    }
                                    //2nd level nav items
                                    > a
                                    {
                                        width: 100%;
                                        padding-top: 20px;
                                        padding-bottom: 20px;
                                    }
                                    &:not(:last-child)
                                    {
                                        > a
                                        {
                                            border-bottom: 1px solid $dark-purple;
                                        }
                                    }
                                    //3rd level nav item
                                    > div
                                    {
                                        line-height: 3;
                                        &.show
                                        {
                                            overflow: auto;
                                            overflow-y: scroll;

                                            height: calc(100% - 70px - 60px);

                                            background: $purple;
                                        }
                                        a
                                        {
                                            padding-top: 20px;
                                            padding-bottom: 20px;

                                            &:not(:last-child)
                                            {
                                                border-bottom: 1px solid $dark-purple;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &#capabilities
                        {
                            line-height: 1.25;

                            @media (min-width: 1025px)
                            {
                                @include columnizer(6 6, null, null, $vertical-gutter: 64px, $set-neat-grid: $neat-grid-bleed);

                                margin: 0;
                            }

                            @media (max-width: 1024px)
                            {
                                @include columnizer(12, null, null, 0, $set-max-width: false);
                            }

                            > div
                            {
                                > div
                                {
                                    line-height: 1.5;

                                    margin-top: 8px;
                                    a
                                    {
                                        font-size: 1vw;

                                        color: $white;

                                        @media (max-width: 1024px)
                                        {
                                            font-size: 15px;
                                        }
                                        &:hover
                                        {
                                            @media (min-width: 768px)
                                            {
                                                color: $yellow;
                                            }
                                        }
                                    }
                                    @media (max-width: 1024px)
                                    {
                                        display: none;

                                        &.hide
                                        {
                                            display: none;
                                        }
                                        &.show
                                        {
                                            @include position(absolute, 63px 0 0 0);

                                            display: block;
                                            > div
                                            {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &#work
                        {
                            line-height: 1.75;

                            max-width: 20vw;
                            @media (max-width: 1440px)
                            {
                                max-width: 300px;
                            }
                            @media (max-width: 1024px)
                            {
                                height: calc(100% - 70px - 60px);
                            }
                        }
                        &#journal
                        {
                            max-width: 20vw;

                            text-align: left;
                            @media (max-width: 1440px)
                            {
                                max-width: 300px;
                            }

                            img
                            {
                                width: 100%;
                                margin-bottom: 16px;
                            }
                            .title
                            {
                                @include font-sofia-pro-bold;
                                @include margin(0);

                                font-size: 1.5vw;
                                line-height: 1.5;

                                @media (max-width: 1024px)
                                {
                                    font-size: 22px;
                                }

                                a
                                {
                                    @include font-sofia-pro-bold;

                                    text-decoration: none;

                                    color: $white;
                                    &:hover
                                    {
                                        color: $yellow;
                                    }
                                }
                            }
                            p
                            {
                                @include font-sofia-pro;

                                font-size: 1vw;

                                text-align: left;

                                color: $white;

                                @media (max-width: 1024px)
                                {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.touch.safari-10
{
    .social-menu
    {
        /**
         * iPad with portrait orientation.
         */
        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
        {
            @include position(absolute, null 0 50px);
        }

        /**
         * iPad with landscape orientation.
         */
        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)
        {
            @include position(absolute, null 0 50px);
        }
    }
    .side-menu
    {
        > div#work
        {
            height: calc(100% - 110px - 60px);
        }
    }
}
