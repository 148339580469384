main
{
    &.journal
    {
        position: relative;

        background-color: $white;
        .intro
        {
            height: 330px;
            p
            {
                @include font-sofia-pro-bold;

                font-size: 20px;
                font-style: normal;
                line-height: 26px;

                color: $black-50;
            }
        }
        .landing-intro
        {
            height: auto;
            .wrapper
            {
                display: table;

                max-width: none;
                height: 480px;

                text-align: center;

                background-attachment: scroll;
                background-position: center;
                .inner
                {
                    position: relative;

                    width: 80%;
                    max-width: 700px;
                    margin: 0 auto;
                }
                .vertical
                {
                    display: table-cell;

                    vertical-align: middle;
                }
            }
        }
        article.post
        {
            a
            {
                word-wrap: break-word;
            }
            > .content
            {
                padding: 80px 0;
            }
            .content
            {
                p a,
                figcaption a
                {
                    text-decoration: underline;

                    color: $dark-grey;
                    &:hover
                    {
                        color: $red;
                    }
                }

                .primary
                {
                    font-size: 1.5rem;
                    font-style: italic;
                }
                .byline
                {
                    font-size: 1.15em;
                    font-style: italic;

                    text-align: center;

                    color: #6b6b6b;
                    .author
                    {
                        @include font-sofia-pro;

                        font-size: .8em;
                        font-weight: 500;
                        font-style: normal;

                        padding-left: 6px;

                        letter-spacing: 2px;
                        text-transform: uppercase;
                    }
                }
            }
            header,
            header.content
            {
                padding-bottom: 0;
                h1,
                h2,
                h3,
                h4,
                h5,
                h6
                {
                    text-align: center;
                }
                .subheader
                {
                    font-size: 1.85em;
                    line-height: 1.45;

                    margin-bottom: 25px;

                    text-align: center;

                    color: #313131;
                }
                + .content:not(.grey)
                {
                    margin-top: 20px;
                    padding-top: 0;
                }
                + .content.grey
                {
                    margin-top: 50px;
                }
            }
            .grey
            {
                background-color: $light-grey;
            }
            figure
            {
                padding: 30px 0;

                text-align: center;
                figcaption
                {
                    @include font-sofia-pro;

                    font-size: 14px;
                    font-weight: 700;

                    margin-top: 5px;

                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
                &.border
                {
                    img
                    {
                        border: 2px solid #dadada;
                    }
                }
            }
            figure + figure
            {
                padding-top: 0;
            }
            .outset
            {
                margin-right: -200px;
                margin-left: -200px;
            }
            hr
            {
                &.divider
                {
                    clear: both;

                    height: 0;
                    margin: 30px 0;
                    margin-right: auto;
                    margin-left: auto;

                    border-width: 3px 0 0;
                }
                &.short
                {
                    width: 15%;

                    border-color: $light-grey;
                }
            }
            &.puzzle
            {
                background-color: $light-grey;
                .button
                {
                    @include transition(all ease .3s);

                    font-size: 17px;
                    font-weight: 700;

                    display: inline-block;

                    margin-left: 10px;
                    padding: 12px 20px;

                    letter-spacing: 2px;
                    text-transform: uppercase;

                    color: #ffffff;
                    border: 2px solid #4c4c4c;
                    border-radius: 3px;
                    background-color: #f11f43;
                    &:hover
                    {
                        color: #f11f43;
                        border: 2px solid #4c4c4c;
                        background-color: #ffffff;
                    }
                }
            }
            .content.gallery
            {
                padding: 0;
                .item
                {
                    position: relative;

                    padding: 0;

                    border: 0;
                    .description
                    {
                        @include transition(opacity ease .5s);

                        position: absolute;
                        z-index: 10;

                        width: 100%;
                        height: 100%;

                        text-align: center;

                        opacity: 0;
                        border: 0;
                        background-color: rgba(0,0,0,.8);
                        .subhead
                        {
                            @include font-sofia-pro;

                            font-size: 26px;
                            font-weight: 300;
                            font-style: italic;

                            padding-top: 15px;

                            text-indent: 0;

                            color: $white;
                        }
                        .opera
                        {
                            font-size: 1.15rem;
                            font-weight: 400;

                            margin: 0;
                            padding-top: 0;

                            letter-spacing: 0;
                            text-transform: none;

                            color: $light-grey;
                        }
                        .title
                        {
                            @include transition(color ease .5s);
                            @include font-sofia-pro;

                            font-size: 24px;
                            font-weight: 400;

                            box-sizing: border-box;
                            margin-bottom: 0;
                            padding: 15px;

                            text-indent: 0;
                            letter-spacing: 8px;
                            text-transform: uppercase;

                            color: $white;
                            &:hover
                            {
                                @include transition(color ease .5s);

                                color: $red;
                            }
                        }
                        hr
                        {
                            width: 15%;
                            margin: 10px auto;

                            border-top: 2px solid $white;
                        }
                        .content
                        {
                            display: table;

                            width: 100%;
                            height: 100%;
                            padding: 0;
                            span
                            {
                                display: table-cell;

                                vertical-align: middle;
                            }
                        }
                    }
                    &:hover a.gallerybox .description
                    {
                        @include transition(opacity ease .5s);

                        opacity: 1;
                    }
                    img
                    {
                        z-index: 5;

                        margin: 0;
                        padding: 0;

                        border: 0;
                    }
                    a.preview
                    {
                        line-height: 0;

                        display: block;
                    }
                }
            }
        }
        .list
        {
            article.post
            {
                padding: 45px 0;

                word-break: break-word;

                border-bottom: #e7e7e7 1px solid;
                &:first-child
                {
                    padding-top: 0;
                }
                &:last-child
                {
                    padding-bottom: 0;

                    border-bottom: 0;
                }
                a
                {
                    @include transition(color ease .3s);

                    color: $black-50;
                    &:hover
                    {
                        color: $purple;
                    }
                }
                header.post-header
                {
                    margin-bottom: 18px;
                }
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6
            {
                &.post-title
                {
                    padding-top: 0;

                    text-align: left;
                }
            }
            img
            {
                &.post-header
                {
                    width: 100%;
                }
            }
            .quote
            {
                padding: 0;
                .columns
                {
                    padding: 0;
                }
                blockquote
                {
                    margin: 0 auto;
                    padding: 10px 0;

                    text-align: center;

                    border-left: 0;
                    &:before
                    {
                        @include font-noe-display-black;

                        font-size: 80px;
                        line-height: 0;

                        display: block;

                        content: '“';

                        color: #f11f43;
                    }
                    p
                    {
                        font-size: 30px;
                    }
                    footer:before
                    {
                        content: '\2014 \00A0';
                    }
                }
                img
                {
                    width: 100%;
                }
            }
            .puzzle
            {
                @include transition(background-color .3s ease);

                box-sizing: border-box;

                text-align: center;

                background-color: $light-grey;
                &:hover
                {
                    background-color: darken($light-grey, 4);
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6
                {
                    text-align: center;
                }
                h2
                {
                    font-size: rem-calc(40);
                    font-weight: 400;

                    color: $dark-grey;
                }
                h3
                {
                    font-family: $header-font-family;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 120%;

                    margin: 5px 0;
                    padding: 0;

                    letter-spacing: 2px;
                    text-transform: uppercase;

                    color: #9eabb3;
                }
                p
                {
                    margin: 0;
                }
                a
                {
                    &.game
                    {
                        display: inline;
                        img
                        {
                            width: 175px;
                            margin-bottom: -75px;
                        }
                    }
                }
            }
        }
    }
}

// Details
.blog-title
{
    font-size: 46px;

    margin: 10px 0;

    letter-spacing: -1px;
}
.blog-description
{
    @include font-noe-display-black;

    font-size: 18px;
    font-weight: 300;
    line-height: 15px;

    margin: 0;

    letter-spacing: 0;
}
h1.post-title
{
    font-size: 2.1rem;

    margin: 0;
    a
    {
        font-weight: 400;
        line-height: 1.2;

        display: block;

        margin: 0;

        text-decoration: none;
    }
}
.post-excerpt
{
    font-size: 1.3rem;
    line-height: 1.5;
}
.post-header img
{
    float: right;

    margin-left: 3.2em;
}
.post-meta
{
    font-family: $header-font-family;
    font-size: .9em;
    font-weight: 700;

    display: inline-block;

    margin: 10px 0;

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #9eabb3;
    a
    {
        text-decoration: none;

        color: #9eabb3;
        &:hover
        {
            text-decoration: underline;
        }
    }
}

// Post Details
.user-meta
{
    position: relative;

    min-height: 77px;
    padding: 3px 40px 0 100px;
}
.user-image
{
    position: absolute;
    top: 0;
    left: 0;
}
.user-name
{
    font-weight: bold;

    display: block;
}
.user-bio
{
    font-size: 14px;
    line-height: 1.5em;

    display: block;

    max-width: 440px;
}
.publish-meta
{
    position: absolute;
    top: 0;
    right: 0;

    padding: 43px 4px 0;

    text-align: right;
}
.publish-heading
{
    font-weight: bold;

    display: block;
}
.publish-date
{
    font-size: 14px;
    line-height: 1.5em;

    display: block;
}
.journal .content
{
    .row
    {
        display: flex;
        clear: both;

        width: 100%;
        max-width: 667px;
        margin: 0 auto;

        @media($phone-only)
        {
            display: block;
        }

        .large-8
        {
            width: 100%;

            @media($phone-only)
            {
                width: fit-content;
                padding: 20px;
            }
        }
        .medium-6
        {
            float: left;

            width: 50%;
            padding: 0 20px 0 0;

            @media($phone-only)
            {
                float: none;

                width: 100%;
            }
            img
            {
                max-width: 100%;
                height: auto;
            }
        }
        .medium-6:last-child
        {
            float: right;

            padding: 0 0 0 20px;

            @media($phone-only)
            {
                float: none;

                padding: 0;
            }
        }
    }
    ul
    {
        @include clearfix;

        line-height: 1.5;

        margin: 0;
        margin: 30px 0;
        padding: 0;

        list-style-type: none;
        li
        {
            display: block;
            float: left;

            width: 100%;
            margin-bottom: 15px;
            margin-left: 20px;
            &:last-child
            {
                margin-bottom: 0;
                padding-bottom: 0;

                border-bottom: 0 solid !important;
            }
            &:before
            {
                font-size: 15px;
                line-height: 30px;

                display: block;
                float: left;

                margin-left: -20px;

                content: '\002022';

                color: $red;
            }
        }
        &.featured
        {
            font-family: $header-font-family;
            font-size: 22px;
            font-weight: 400;
            li
            {
                padding-bottom: 15px;

                border-bottom: 2px solid $light-grey;
            }
        }
    }
    p img
    {
        display: block;

        max-width: 66.66%;
        margin: 0 auto;
    }
}
.journal ul.social.icons li a
{
    color: $grey;
    &:hover
    {
        color: $red;
    }
}
.journal ul.image-slider li
{
    margin: 0;
    padding: 0;
}
.featured-ordered-list
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    margin: 50px 0;
    padding: 50px;

    border: 4px solid $light-grey;
    h2
    {
        font-family: $header-font-family;
        font-size: 30px;
        font-weight: 700;

        margin: 0 0 35px;

        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: #333333 !important;
    }
    h3
    {
        font-size: 27px;
        font-weight: normal;
        line-height: 46px;

        margin-top: 0;

        letter-spacing: 0;
        text-transform: none;

        color: #333333 !important;
    }
    > p,
    > ul,
    > ol
    {
        font-family: $header-font-family;
        font-size: 1.25rem;

        margin-bottom: 1rem;
        margin-left: 32px;
    }
    &.white
    {
        border: 4px solid $white;
        ol
        {
            > li
            {
                border-bottom: 2px solid $white;
            }
        }
        .fancybox-overlay
        {
            background: rgba(255, 255, 255, .9);
        }
    }
    ol
    {
        font-family: $header-font-family;
        font-size: 22px;
        font-weight: 400;
        line-height: 1.5;

        margin: 0;
        padding: 0;

        list-style-type: none;
        list-style-type: none;

        counter-reset: item;
        > li
        {
            /* copied from h3 to make text work in craft */
            font-size: 27px;
            font-weight: normal;
            line-height: 46px;

            display: block;
            float: left;

            width: 100%;
            margin-top: 0;
            margin-bottom: 15px;
            padding-bottom: 15px;

            letter-spacing: 0;
            text-transform: none;

            color: #333333 !important;
            /* endcopy */
            border-bottom: 2px solid $light-grey;
            &:last-child
            {
                margin-bottom: 0;
                padding-bottom: 0;

                border-bottom: 0 solid !important;
            }
            &:before
            {
                font-size: 30px;
                line-height: 38px;

                display: block;
                float: left;

                width: 32px;

                content: counter(item) ' ';
                counter-increment: item;

                color: $red;
            }
        }
        &:before,
        &:after
        {
            display: table;
            clear: both;

            content: ' ';
        }
    }
}
.related
{
    /* Hide this until we have relevant content! */
    display: none;
    float: none;
    clear: both;

    border-top: #ffffff 2px solid;
    border-bottom: #ffffff 2px solid;
    background-color: #eeeeee;
    .articles
    {
        img
        {
            float: left;

            margin-right: 20px;

            border: 1px solid #dddddd;
        }
        h4
        {
            font-family: $header-font-family-serif-alt;
            font-size: 20px;
            font-weight: 400;
            line-height: 1;

            margin-top: 0;
            margin-bottom: 0;

            text-transform: none;

            text-rendering: optimizeLegibility;
        }
        .post-excerpt
        {
            font-size: 16px;
            line-height: 1.45em;

            margin-top: 10px;
        }
        .columns .columns
        {
            padding-top: 40px;
            padding-bottom: 40px;
            &:first-child
            {
                border-right: 2px solid white;
            }
        }
        a,
        a h4
        {
            @include transition(all .5s ease);

            color: #4a4a4a;
            &:hover,
            &:hover h4
            {
                color: #f11f43;
            }
        }
    }
    .prev
    {
        .icon
        {
            float: left;

            color: red;
        }
    }
    .next
    {
        .icon
        {
            float: left;

            color: red;
        }
    }
}
.full-width.row
{
    overflow: hidden;

    max-width: 1600px;
}
