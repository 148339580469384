/* -----------------------------------------
	Mixins
----------------------------------------- */
@import 'columnizer';

@mixin tracking($tracking)
{
    letter-spacing: $tracking / 1000 * 1em;
}

// Fontello Mixin
@mixin fontello($icon)
{
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    content: $icon;
    text-transform: none;

    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin animate($speed: $transition-speed)
{
    transition: $speed;
}

@mixin shadow
{
    -webkit-box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
       -moz-box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
}
@mixin text-shadow
{
    text-shadow: 0 0 7px #888888;
}
@mixin transition($args)
{
    -webkit-transition: $args;
       -moz-transition: $args;
        -ms-transition: $args;
         -o-transition: $args;
            transition: $args;
}
@mixin background-cover
{
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
}
@mixin transform($args)
{
    -webkit-transform: $args;
       -moz-transform: $args;
        -ms-transform: $args;
         -o-transform: $args;
            transform: $args;
}

// ex. usage:
//a.new-button {
//    @include new-button($grey);
//}
@mixin new-button($color)
{
    span
    {
        color: $color;
    }
    .underline
    {
        .underline_wrapper
        {
            .path-underline
            {
                stroke: $color;
            }
        }
    }
}

//button option to disable select
@mixin disable-select
{
    -webkit-user-select: none; /* Chrome/Safari */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
            user-select: none;
    /* Rules below not implemented in browsers yet */

         -o-user-select: none;
}

//Link hover mixin used on `a` elements
@mixin inline-link
{
    padding-top: 2px;
    padding-bottom: 1px;

    cursor: pointer;
    text-decoration: none;

    border-bottom: 2px solid $yellow;

    &:hover
    {
        background-color: $yellow;
    }

    &:visited
    {
        color: rgba($purple, .5);
        border-bottom: 2px solid rgba($yellow, .5);
    }
}

@mixin bullet-before
{
    @include animate;

    position: absolute;
    top: 7px;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: -35px;

    content: '';

    border-radius: 16px;
    background-color: rgba($purple-grey, .3);
}

@mixin bullet-before-hover
{
    @include fontello($icon-right);

    font-size: 14px;

    position: absolute;
    top: 4px;

    display: inline-block;

    width: 20px;
    height: 18px;
    margin-left: -35px;
    padding-top: 4px;
    padding-left: 2px;

    text-align: center;

    color: $white;
    border-radius: 15px;
    background-color: rgba($purple, 1);
}
// circle purple arrow default state
@mixin arrow-before
{
    @include animate;

    font-size: calc(1px + 1vw);

    position: absolute;
    top: 0;

    display: flex;

    width: 100%;
    height: 100%;

    content: '';

    color: $white;
    border-radius: 50%;
    background-color: rgba($purple-grey, 0);

    justify-content: center;
    align-items: center;
}
// circle purple arrow default state
@mixin arrow-before-hover
{
    @include fontello($icon-right);

    text-align: center;

    background-color: rgba($purple, 1);
}


@mixin word-break-hyphens
{
    word-break: break-word;
    hyphens: auto;
}
