

/* Filter drops */

.select-selected:after
{
    @include animate;
    //icon arrow down
    @include fontello($icon-chevron-right);

    font-size: 1.5rem;
    line-height: 2rem;

    position: absolute;
    position: absolute;
    top: 8px;
    right: 15px;

    width: 0;
    height: 10px;

    transition: all 250ms ease 0s;
    transform: rotate(90deg);
    transform-origin: bottom;

    color: #8c8c8c;
}




.form-container,
.clear-container
{
    display: flex;

    width: 100%;
    max-width: 667px;
    margin: 0 auto;
    margin-top: 2rem;

    justify-content: space-between;
    align-items: center;
    @media (max-width:767px)
    {
        display: block;

        width: unset;
        padding: 20px;
    }

    .buttons-container
    {
        display: flex;

        margin-top: 1.2rem;
        @media (max-width:767px)
        {
            margin-top: 2rem;

            justify-content: space-around;
        }
        .new-button
        {
            &:first-of-type
            {
                margin-right: 2rem;
            }
            a
            {
                text-decoration: none;
                &:hover
                {
                    color: #9d00ff;
                }
            }
        }
    }
    .new-button
    {
        a
        {
            text-decoration: none;
        }
    }
}


.pagination-container
{
    display: flex;

    width: 100%;
    max-width: 667px;
    margin: 0 auto;
    margin-top: 4rem;

    justify-content: center;
    align-items: center;
    @media (max-width:767px)
    {
        display: block;

        text-align: center;
    }
    a,
    span
    {
        font-family: 'NoeDisplay-Black';
        font-size: 2rem;

        padding: 1vw;

        transition: color 250ms ease;
        text-decoration: none;

        color: rgba(37, 31, 41, .5);
    }
    .current
    {
        cursor: not-allowed;

        color: #9d00ff;
    }
    .disabled
    {
        cursor: not-allowed;

        opacity: .5;
    }
}

.no-results
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-size: 20px;
    font-weight: 700;

    display: flex;

    padding: 20px;

    justify-content: center;
    align-items: center;

    a
    {
        font-weight: bold;

        margin-left: 8px;
        &:hover
        {
            color: #9d00ff;
        }
    }
}

.custom-select
{
    font-family: 'sofia-pro', 'Trebuchet MS', sans-serif;
    font-size: rem(20px);
    font-weight: 600;
    line-height: 1;

    position: relative;

    width: 100%;

    text-align: center;

    border-bottom: 3px solid rgba(0, 0, 0, .25);
}

.custom-select select
{
    display: none; /*hide original SELECT element:*/
}

.select-selected
{
    font-size: 2rem;

    text-transform: capitalize;

    color: #8c8c8c;
    background-color: white;
}

/*style the arrow inside the select element:*/


/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after
{
    top: 16px;

    transform: rotate(-90deg);
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected
{
    padding: 8px 16px;

    cursor: pointer;
    user-select: none;

    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, .1) transparent;
}

/*style items (options):*/
.select-items
{
    position: absolute;
    z-index: 100;
    top: 95%;
    right: 0;
    left: 0;

    overflow-x: hidden;
    overflow-y: auto;

    margin: 0;
    padding: 16px 20px;

    list-style: none;

    color: #8c8c8c;
    background-color: #f9f9f9;
}

.select-items div
{
    font-family: 'sofia-pro', 'Trebuchet MS', sans-serif;
    font-size: 2rem;
    font-weight: 100;

    color: #8c8c8c;
    border: none;
    background-color: transparent;
}
/*hide the items when the select box is closed:*/
.select-hide
{
    display: none;
}

.select-items div:hover,
.same-as-selected
{
    color: #313131;
}


.custom-select
{
    margin-right: 30px;
}
.same-as-selected
{
    color: #9d00ff !important;
}
