// Default Animation Values
$transition-speed: 250ms;
$transition-easing: ease;

// Colors
$purple-black: #241f28;

$yellow: #ffff00;
$purple: #9d00ff;
$dark-purple: #66208c;
$teal: #46af91;
$orange: #ffc400;
$old-gold: #cdc63b;

$white: #ffffff;
$black: #251f29;
$red: #ee3124;
$light-grey: #ececec;
$meta-txt: darken($light-grey, 15);
$grey: #727272;
$dark-grey: #313131;
$blue: #51b0d3;
$blue-quote: #1482c5;
$purple-grey: #9688a6;
$blue-configurator: #15adff;
$accordion-navigation-bg-color: #efefef;
$accordion-navigation-font-color: #222222;
$featured-list-separator-color: #9688a6;
$table-alt-row-bg-color: #9688a6;
$subnav-color: #251f29;
$speednump-feature-link-border: rgba(#9688a6, .3);

// Gradient Colors
$black-gradient: linear-gradient(180deg, transparent 10%, $black 60%);

// Transparent Colors
$black-25: rgba($black, .25);
$black-30: rgba($black, .30);
$black-50: rgba($black, .5);
$black-65: rgba($black, .65);
$white-30: rgba($white, .3);
$white-50: rgba($white, .5);
$white-70: rgba($white, .7);
$dark-purple-50: rgba($dark-purple, .5);
$yellow-92: rgba($yellow, .92);
$yellow-50: rgba($yellow, .5);

$intro-duration: 1s;
$intro-p-fill-color: #ffffff;
$intro-p-height: 60vh;
$intro-p-width: auto;
$intro-text-font-family: 'sofia-pro', sans-serif;
$intro-text-font-color: #ffffff;

// Font Colors
$base-txt-color: $black;

// Variables
$screen: 'only screen';
$small-range: (0, 40em);
$medium-range: (40.063em, 64em);
$large-range: (64.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);
$medium-down: '#{$screen} and (max-width: #{upper-bound($medium-range)})';
$medium-portrait: '#{$screen} and (max-width: #{upper-bound($medium-range)}) and (orientation: portrait)';
$large-down: '#{$screen} and (max-width: #{upper-bound($large-range)})';

// Topbar
$medium-up: '#{$screen} and (min-width:#{lower-bound($medium-range)})';
$topbar-bg-color: $white;
$topbar-height: rem-calc(85);
$topbar-link-color: $dark-grey;
$topbar-link-color-hover: $red;
$topbar-link-color-active: $dark-grey;
$topbar-link-color-active-hover: $topbar-link-color-hover;
$topbar-link-font-size: rem-calc(19);
$topbar-link-bg: $light-grey;
$topbar-link-bg-hover: $light-grey;
$topbar-link-padding: rem-calc(15);
$topbar-button-font-size: rem-calc(17);
$topbar-media-query: $medium-up;
$topbar-link-bg-active: transparent;
$topbar-link-bg-active-hover: $topbar-link-bg-active;

// Header
$header-font-family: 'sofia-pro', Helvetica, Arial, sans-serif;
$header-font-family-serif: 'sofia-pro', Georgia, serif;
$header-font-family-serif-alt: 'NoeDisplay-Black', Georgia, serif;
$header-font-weight: 300;
$header-font-color: $dark-grey;
$header-line-height: 1.3;
$header-bottom-margin: .8rem;

// Paragraph and Body
$paragraph-font-family: 'sofia-pro', Georgia, serif;
$paragraph-font-weight: 400;
$paragraph-font-size: 1.30rem;
$anchor-font-color: $red;
$hr-border-color: darken($light-grey, 7);
$body-font-color: $dark-grey;
$body-font-family: 'sofia-pro', Helvetica, Arial, sans-serif;

// Gradients

// Borders

// Sizes

// Social Media Colors
$facebook-color: #3b5998;
$twitter-color: #00aced;
$linkedin-color: #0077b5;
$pinterest-color: #bd081c;
$yelp-color: #af0606;

$instagram-color: #bc318f;

$instagram-gradient: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

$amp: '\46';
$lt: '\74';
$gt: '\76';
$cent: '\242';
$copy: '\252';
$laquo: '\253';
$reg: '\256';
$deg: '\260';
$raquo: '\273';
$divide: '\367';
$ndash: '\2013';
$mdash: '\2014';
$ldquo: '\201C';
$rdquo: '\201D';
$lsaquo: '\2039';
$rsaquo: '\203A';
$bull: '\2219';
$hellip: '\2026';
$trade: '\2122';
$lang: '\2329';
$rang: '\232A';
