.gallery
{
    padding-bottom: 45px;

    &.gallery--third
    {
        .block__wrapper
        {
            > div > div > div
            {
                @media($tablet-up)
                {
                    line-height: 0;

                    display: flex;
                }

                > div:first-child
                {
                    @media($phone-only)
                    {
                        background: none !important;
                    }
                    @media($tablet-up)
                    {
                        width: 50%;
                        img
                        {
                            opacity: 0;
                        }
                    }
                    @media($tablet-only)
                    {
                        position: relative;

                        margin-right: 16px;
                    }
                    @media($desktop-only)
                    {
                        position: relative;

                        margin-right: 30px;
                    }
                    // > div
                    //{
                    //    @media($tablet-up)
                    //    {
                    //        opacity: 0;
                    //    }
                    //}
                }

                > div:last-child
                {
                    @media($tablet-up)
                    {
                        display: flex;
                        flex-direction: column;

                        width: 50%;
                        padding-top: 5%;
                        padding-bottom: 5%;

                        flex-shrink: 2;

                        @media all and (-ms-high-contrast:none)
                        {
                            /* IE11 start */
                            display: inline;
                            *::-ms-backdrop
                            {
                                display: inline;
                            }
                        }/* IE11 end */
                    }
                    @media($desktop-only)
                    {
                        flex-shrink: 5;

                        @media all and (-ms-high-contrast:none)
                        {
                            /* IE11 start */
                            display: inline;
                            *::-ms-backdrop
                            {
                                display: inline;
                            }
                        }/* IE11 end */
                    }
                    > div
                    {
                        @media($phone-only)
                        {
                            margin-top: 10px;

                            background: none !important;
                        }
                        + div
                        {
                            @media($phone-only)
                            {
                                margin-top: 10px;
                            }
                            @media($tablet-only)
                            {
                                margin-top: 16px;
                            }
                            @media($desktop-only)
                            {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.gallery--half
    {
        .block__wrapper
        {
            > div > div > div
            {
                @media($tablet-up)
                {
                    line-height: 0;

                    display: flex;
                }

                > div:first-child
                {
                    @media($tablet-only)
                    {
                        margin-right: 16px;
                    }
                    @media($desktop-only)
                    {
                        margin-right: 30px;
                    }
                    > div
                    {
                        @media($tablet-up)
                        {
                            opacity: 0;
                        }
                    }
                }

                > div:last-child
                {
                    @media($tablet-up)
                    {
                        display: flex;
                        flex-direction: column;

                        padding-top: 5%;
                        padding-bottom: 5%;
                    }
                    @media all and (-ms-high-contrast:none)
                    {
                        /* IE11 start */
                        display: inline;
                        *::-ms-backdrop
                        {
                            display: inline;
                        }
                    }/* IE11 end */
                    > div
                    {
                        @media($phone-only)
                        {
                            margin-top: 10px;

                            background: none !important;
                        }
                        + div
                        {
                            @media($phone-only)
                            {
                                margin-top: 10px;
                            }
                            @media($tablet-only)
                            {
                                margin-top: 16px;
                            }
                            @media($desktop-only)
                            {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.gallery--bottom
    {
        .block__wrapper
        {
            > div
            {
                > div:first-of-type
                {
                    @media($tablet-up)
                    {
                        display: flex;
                    }
                    > div
                    {
                        @media($phone-only)
                        {
                            background: none !important;
                        }
                        + div
                        {
                            @media($phone-only)
                            {
                                margin-top: 10px;
                            }
                        }
                        > img
                        {
                            @media($tablet-up)
                            {
                                opacity: 0;
                            }
                        }
                    }
                    @media($phone-only)
                    {
                        @include columnizer(4, $set-neat-grid: $neat-grid-phone, $collapse: true);

                        padding-bottom: 10px;
                    }
                    @media($tablet-only)
                    {
                        @include columnizer(2.6667 2.6667 2.6667, $set-neat-grid: $neat-grid-tablet, $collapse: true);

                        padding-bottom: 16px;
                    }
                    @media($desktop-only)
                    {
                        @include columnizer(4 4 4, $collapse: true);

                        padding-bottom: 30px;
                    }
                }
            }
        }
    }
}

.featured-links
{
    .title
    {
        @include t-slider-label;

        color: $dark-purple;

        + ul
        {
            margin-top: 85px !important;
        }
    }
    ul
    {
        margin-left: 0;
        padding-left: 0;

        list-style: none;

        li
        {
            position: relative;

            display: block;

            padding-bottom: 25px;
            padding-left: 35px;

            border-bottom: 1px solid $featured-list-separator-color;

            + li
            {
                margin-top: 30px;
            }

            a
            {
                font-size: 22px;

                position: relative;

                text-decoration: none;

                &:before
                {
                    @include bullet-before;
                }

                &:hover
                {
                    color: $purple;
                    &:before
                    {
                        @include bullet-before-hover;
                    }
                }

                span
                {
                    display: block;
                }
                small
                {
                    font-size: 14px;
                    font-style: normal;

                    display: block;

                    color: $purple-grey;
                }
            }
        }
    }
}

#blocks-builder,
section
{
    // html elements
    h1
    {
        @include t-h1;

        color: $dark-purple;
        @media($phone-and-tablet)
        {
            @include word-break-hyphens;
        }
    }

    h2
    {
        @include t-h2;

        color: $dark-purple;
        @media($phone-and-tablet)
        {
            @include word-break-hyphens;
        }

        + p
        {
            margin-top: -20px;
        }
    }

    h3
    {
        @include t-h3;

        color: $dark-purple;
        @media($phone-and-tablet)
        {
            @include word-break-hyphens;
        }
    }

    h4
    {
        @include t-h4;

        color: $dark-purple;
        @media($phone-and-tablet)
        {
            @include word-break-hyphens;
        }
    }

    p
    {
        font-size: 1.8rem;
        line-height: 3.0rem;

        color: $purple-grey;

        @media($phone-only)
        {
            font-size: 1.6rem;
            line-height: 2.8rem;
        }

        + figure
        {
            margin-top: 35px;
        }

        a
        {
            @include inline-link;
        }
    }

    .button
    {
        @include font-sofia-pro-bold;

        font-size: 12px;

        letter-spacing: 400;
    }

    label
    {
        @include t-label;
    }

    img
    {
        line-height: 0;

        width: 100%;
        height: auto;

        + figcaption
        {
            @include t-figcaption;

            margin-top: 12px;

            color: $purple-grey;

            p
            {
                @include t-figcaption;
            }

            a
            {
                @include inline-link;
            }
        }
    }

    p
    {
        img
        {
            width: auto;
            max-width: 100%;
        }
    }

    figure
    {
        line-height: 0;

        padding-bottom: 20px;

        + figure
        {
            margin-top: 25px;
        }

        + p
        {
            margin-top: 45px;
        }
    }

    .new-button
    {
        margin-bottom: 40px;
    }
    .full-image-link
    {
        @include animate;

        position: relative;

        display: block;

        box-sizing: border-box;
        height: 500px;
        padding-top: 80px;

        text-decoration: none;

        background-size: cover;
        @media(max-width:767px)
        {
            height: 250px;
            padding-top: 30px;
        }
        &.desktop
        {
            @media(max-width: 767px)
            {
                background-image: none !important;
            }
        }
        &.mobile
        {
            height: 350px;
            @media(min-width: 768px)
            {
                display: none;
            }
        }

        &__content
        {
            @include columnizer(4, 1);
            @media (max-width: 767px)
            {
                @include columnizer(12);
            }
            .full-image-link__label
            {
                @include t-card-label;

                position: relative;

                color: $purple-black;
                &:before
                {
                    @include fontello($icon-right);

                    font-size: 28px;
                    line-height: 50px;

                    position: absolute;
                    top: -15px;
                    left: -70px;

                    display: inline-block;

                    box-sizing: border-box;
                    width: 50px;
                    height: 50px;
                    padding-left: 5px;

                    text-align: center;

                    color: $white;
                    border-radius: 30px;
                    background-color: $purple;
                    @media (max-width: 767px)
                    {
                        opacity: 0;
                    }
                }
            }
            .new-button
            {
                @include t-card-label;

                position: relative;

                display: block;

                color: $black;
                &:before
                {
                    @include animate;

                    position: absolute;
                    bottom: -8px;

                    width: 125px;
                    height: 2px;

                    content: '';

                    opacity: 0;
                    background-color: $purple;
                    @media(max-width:767px)
                    {
                        opacity: 1;
                    }
                }
            }
            .full-image-link__heading
            {
                color: $black;
            }
        }
        &.light-text
        {
            @media(min-width: 768px)
            {
                color: $white;
                .full-image-link__heading
                {
                    color: $white;
                }
                .full-image-link__label
                {
                    color: $white;
                }
                .new-button
                {
                    color: $white;
                }
            }
        }
        .full-image-link__heading
        {
            text-decoration: none;
        }


        &:before
        {
            @include animate;

            position: absolute;
            top: 0;
            left: 0;

            box-sizing: border-box;
            width: 100%;
            height: 100%;

            content: '';
        }

        &:hover
        {
            @include animate;
            .new-button
            {
                &:before
                {
                    opacity: 1;
                }
            }

            &:before
            {
                border: 10px solid $yellow-92;
            }
        }
    }
    .three-column-copy
    {
        display: flex;

        padding: 80px 200px;
        @media(max-width: 1024px)
        {
            padding: 80px 100px;
        }
        @media(max-width: 767px)
        {
            display: block;

            padding: 80px 50px;
        }
        > div
        {
            width: 30%;
            padding-left: 40px;

            flex-grow: 1;
            @media(max-width: 767px)
            {
                width: initial;
            }
            h2
            {
                @include t-copy;

                font-weight: 600;

                margin-bottom: 0;

                font-color: initial;
            }
            p
            {
                @include t-copy;

                margin-top: 0;

                font-color: initial;
            }
        }
    }

    blockquote
    {
        @include font-noe-display-black;
        @include padding(0 0 0 13%);

        font-size: 24px;
        font-weight: 400;
        line-height: 38px;

        position: relative;

        text-align: left;

        color: $purple-grey;

        @media ($phone-and-tablet)
        {
            @include padding(0);
        }

        &:not(first-child)
        {
            margin-top: 100px;
        }
        &:before
        {
            font-size: 100px;
            line-height: 0;

            display: block;

            content: '\201C'; /*Unicode for Left Double Quote*/
        }

        > p
        {
            font-size: 24px;
            font-weight: 400;
            line-height: 38px;

            text-align: left;
        }

        a
        {
            @include inline-link;
        }

        p
        {
            font-size: 2.4rem;
            font-weight: 400;

            margin-left: 0;

            text-align: left;
        }

        + blockquote
        {
            margin-top: 80px;
        }

        footer
        {
            @include font-sofia-pro;

            font-size: 1.8rem;
            line-height: 2.4rem;

            margin-top: 20px;
        }
    }

    .callout
    {
        @include font-sofia-pro-bold;

        font-size: 24px;
        line-height: 34px;

        float: left;

        max-width: 35%;
        padding: 30px 30px 30px 0;

        color: $dark-purple;

        @media($phone-only)
        {
            float: none;

            max-width: none;
            padding: 0 0 10px;
        }

        a
        {
            @include inline-link;

            color: $dark-purple;
        }

        > p
        {
            @media($desktop-only)
            {
                min-height: 200px;
            }
        }
    }

    .blocks-builder__container
    {
        padding-top: 170px;

        @media ($phone-and-tablet)
        {
            padding-top: 40px;
        }
    }
}

//Block
.block
{
    .block__wrapper
    {
        padding-bottom: 50px;
        > .title
        {
            @include t-label;

            font-weight: bold;

            color: $dark-purple;

            + ul
            {
                margin-top: 85px;

                @media($phone-and-tablet)
                {
                    margin-top: 60px;
                }
            }
        }
    }
    &--grey-background
    {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 120px;
        padding-bottom: 120px;

        background-color: $light-grey;

        @media($phone-and-tablet)
        {
            padding-top: 90px;
            padding-bottom: 90px;
        }
    }
    &--black-background
    {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 120px;
        padding-bottom: 120px;

        background-color: $black;

        @media($phone-and-tablet)
        {
            padding-top: 90px;
            padding-bottom: 90px;
        }
    }
    &:not([class*='background'])
    {
        + .block[class*='background']
        {
            margin-top: 100px !important;
        }
    }
    + .block
    {
        margin-top: 50px;

        &.introduction.two-columns
        {
            @media($phone-only)
            {
                margin-top: 0;
            }
        }

        &.featured-links
        {
            margin-top: 100px;
        }

        &.cards
        {
            margin-top: 100px;
            margin-bottom: 100px;
        }
    }
    + .gallery
    {
        padding-top: 45px;
    }
    &.featured-image
    {
        @include t-copy;
        p
        {
            + a.new-button
            {
                margin-top: 10px;
            }
        }
        &.featured-image--no-flip
        {
            .block__wrapper
            {
                > div
                {
                    @media($desktop-only)
                    {
                        @include columnizer(4 7, 0 1, $collapse: true);
                    }
                }
            }
        }
        &.featured-image--flip
        {
            .block__wrapper
            {
                > div
                {
                    @media($desktop-only)
                    {
                        @include columnizer(4 7, 8 -12, $collapse: true);
                    }
                }
            }
        }
        &.featured-image--hero
        {
            .block__wrapper
            {
                > div
                {
                    div + div
                    {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
    &.quick-bullet-accordion,
    .quick-bullet-accordion
    {
        @media ($phone-and-tablet)
        {
            padding-left: 25px;
        }

        .title
        {
            @include t-label;

            @media ($phone-and-tablet)
            {
                margin-left: -25px;
            }
        }

        ul
        {
            margin-left: 0;
            padding-left: 0;

            list-style: none;

            li
            {
                position: relative;

                &:not(.active)
                {
                    &:hover
                    {
                        div > .item-title
                        {
                            cursor: pointer;

                            border-bottom: 1px solid $purple;
                        }
                    }
                }

                &.no-description
                {
                    + li
                    {
                        &:not(.no-description)
                        {
                            margin-top: 15px;
                        }
                    }
                    @media($phone-and-tablet)
                    {
                        left: -25px;
                    }
                    &:hover
                    {
                        div > .item-title
                        {
                            cursor: default !important;

                            border-bottom: 1px solid transparent !important;;
                        }
                    }
                    &:before
                    {
                        visibility: hidden;

                        opacity: 0;
                    }
                    &:after
                    {
                        visibility: hidden;

                        opacity: 0;
                    }
                }

                + li
                {
                    margin-top: 5px;
                }

                &:before
                {
                    @include fontello($icon-browse-dots);

                    font-size: 12px;

                    position: absolute;
                    top: 12px;
                    left: -23px;

                    width: 12px;
                    height: 12px;

                    transition: all 250ms ease-out;
                    transform: rotate(45deg) scale(1);

                    color: $purple;
                }
                &:after
                {
                    position: absolute;
                    top: 15px;
                    left: -20px;

                    width: 5px;
                    height: 5px;

                    content: '';
                    transition: all 250ms ease-out;

                    opacity: 1;
                    border-radius: 2px;
                    background-color: $white;
                }
                .close
                {
                    display: none;
                }
                div
                {
                    .item-title
                    {
                        @include font-sofia-pro;
                        @include animate;

                        font-size: 1.4rem;
                        line-height: 2.2rem;

                        display: inline;

                        padding-bottom: 2px;

                        text-decoration: none;

                        color: $purple;
                        border-bottom: 1px solid transparent;

                        @extend .noselect;
                    }
                    .item-description
                    {
                        @include font-sofia-pro;
                        @include animate;

                        font-size: 1.4rem;
                        line-height: 2.2rem;

                        visibility: hidden;

                        padding-top: 10px;

                        opacity: 0;
                        color: $purple-grey;
                    }
                }
                //active item
                &.active
                {
                    &:before
                    {
                        transform: rotate(0deg) scale(1);
                    }
                    &:after
                    {
                        opacity: 0;
                    }
                    div
                    {
                        &.close
                        {
                            position: absolute;
                            z-index: 1;
                            left: 0;

                            display: block;

                            width: 30px;
                            height: 30px;

                            cursor: pointer;
                            transform: translateX(-100%);
                        }
                        .item-title
                        {
                            user-select: auto;
                        }
                        .item-description
                        {
                            visibility: visible;

                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    &.introduction
    {
        color: $purple-grey;

        &:not(.two-columns)
        {
            + .block.copy
            {
                @media($phone-and-tablet)
                {
                    margin-top: 20px;
                }
            }
            .block__wrapper
            {
                h2
                {
                    @include t-intro;

                    color: $purple-grey !important;
                    @media($desktop-only)
                    {
                        margin-top: 0 !important;
                    }
                }
                *
                {
                    @include t-intro;

                    color: $purple-grey !important;
                }
            }
        }

        &.two-columns
        {
            > .block__wrapper
            {
                @media ($desktop-only)
                {
                    @include columnizer(4.5 7.5, $vertical-gutter: 30px);
                }

                > div:first-child
                {
                    margin-right: 0 !important;
                    //columnizer margin overrides
                    margin-left: 0 !important;
                }
            }

            //accordion
            .quick-bullet-accordion
            {
                @media ($phone-and-tablet)
                {
                    margin-top: 85px;
                    padding-left: 25px;
                }
                @media ($desktop-only)
                {
                    @include margin(0);

                    max-width: none;
                    padding-right: 0;
                    padding-left: 75px;
                }
                li
                {
                    &:before
                    {
                        top: 10px;

                        transform: rotate(45deg) scale(.8);
                    }
                    &:after
                    {
                        position: absolute;
                        top: 13px;
                    }
                    &.active
                    {
                        &:before
                        {
                            transform: rotate(0deg) scale(.8);
                        }
                    }
                }
            }
        }
    }
    &.copy
    {
        @include t-copy;

        //unordered list style
        ul
        {
            display: table;

            margin-left: 0;
            padding-left: 0;

            list-style: none;

            li
            {
                line-height: 30px;

                display: table-row;

                @media($phone-only)
                {
                    font-size: 1.6rem;
                    line-height: 2.8rem;
                }

                + li
                {
                    margin-top: 12px;
                }

                &:before
                {
                    @include fontello($icon-bullet-dots);

                    line-height: 20px;

                    display: table-cell;

                    width: 20px;
                    height: 20px;
                    padding-right: 10px;

                    color: $dark-purple;
                }
            }
        }

        //ordered list style
        ol
        {
            display: table;

            margin-left: 0;
            padding-right: 0;
            padding-left: 0;

            list-style: none;

            li
            {
                line-height: 30px;

                display: table-row;

                counter-increment: step-counter;

                @media($phone-only)
                {
                    font-size: 1.6rem;
                    line-height: 2.8rem;
                }

                + li
                {
                    margin-top: 12px;
                }

                &:before
                {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 20px;

                    position: relative;
                    top: -1px;

                    display: table-cell;

                    width: 20px;
                    height: 20px;

                    content: counter(step-counter);

                    color: $dark-purple;
                }
            }
        }
    }

    &.slider
    {
        .tns-outer
        {
            position: relative;

            .tns-controls
            {
                position: absolute;
                z-index: 1;

                width: 100%;

                transition: all 250ms ease;
                @media($phone-only)
                {
                    display: none;
                }

                &:focus
                {
                    outline: 0;
                }

                button
                {
                    @include font-sofia-pro-bold;

                    font-size: 11px;

                    padding-bottom: 10px;

                    transition: all 250ms ease;
                    letter-spacing: 5px;
                    text-transform: uppercase;

                    color: $purple;
                    border: none;
                    background-color: $white;

                    &:hover
                    {
                        color: $dark-purple;
                    }

                    &:focus
                    {
                        outline: 0;
                    }

                    &[disabled]
                    {
                        pointer-events: none;

                        opacity: 0;
                    }

                    &[data-controls='prev']
                    {
                        float: left;

                        margin-left: -7px;

                        text-align: left;

                        @extend .noselect;
                    }
                    &[data-controls='next']
                    {
                        float: right;

                        margin-right: -11px;

                        text-align: right;

                        @extend .noselect;
                    }
                }
            }
            .tns-nav
            {
                margin-bottom: 8px;

                text-align: center;

                &[data-current-slide]:before
                {
                    @include font-sofia-pro-bold;

                    font-size: 11px;
                    line-height: 20px;

                    position: absolute;
                    left: 0;

                    width: 20px;
                    height: 20px;

                    content: attr(data-current-slide);
                    text-align: left;
                }

                &[data-total-slides]:after
                {
                    @include font-sofia-pro-bold;

                    font-size: 11px;
                    line-height: 20px;

                    position: absolute;
                    right: 0;

                    width: 20px;
                    height: 20px;

                    content: attr(data-total-slides);
                    text-align: right;
                }

                button
                {
                    position: relative;

                    width: 20px;
                    height: 20px;

                    border: none;
                    border-radius: 10px;
                    outline: none;
                    background: none;

                    &:before
                    {
                        position: absolute;
                        top: 50%;
                        left: 50%;

                        width: 6px;
                        height: 6px;

                        content: '';
                        transition: all 250ms ease;
                        transform: translateY(-50%) translateX(-50%);

                        border-radius: 3px;
                        background-color: $purple-grey;
                    }

                    + button
                    {
                        margin-left: 10px;
                    }

                    &.tns-nav-active
                    {
                        &:before
                        {
                            background-color: $purple;
                        }
                    }
                }
            }

            .tns-inner
            {
                .slide
                {
                    figure
                    {
                        figcaption
                        {
                            line-height: 20px;

                            padding-right: 55px;
                            padding-left: 55px;

                            text-align: center;

                            @media ($phone-only)
                            {
                                padding-right: 20px;
                                padding-left: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.video
    {
        footer
        {
            @include font-sofia-pro;

            font-size: 14px;
            line-height: 22px;

            margin-top: 12px;

            color: $purple-grey;

            a
            {
                @include inline-link;
            }
        }
    }
    &.inline-table
    {
        table
        {
            width: 100%;
            th
            {
                @include t-copy;

                font-weight: 700;

                padding: 15px;

                text-align: left;

                color: $dark-purple;
                background-color: $light-grey;
            }
            tr
            {
                td
                {
                    @include t-copy;

                    padding: 15px;
                }

                &:nth-child(odd) td
                {
                    @media($phone-only)
                    {
                        background-color: rgba($table-alt-row-bg-color, .12);
                    }
                    @media($tablet-up)
                    {
                        background-color: $white;
                    }
                }
                &:nth-child(even) td
                {
                    @media($phone-only)
                    {
                        background-color: $white;
                    }
                    @media($tablet-up)
                    {
                        background-color: rgba($table-alt-row-bg-color, .12);
                    }
                }
            }

            @media screen and ($phone-only)
            {
                thead
                {
                    display: none;
                }
                tbody td
                {
                    display: block;
                }
                tbody td:before
                {
                    font-weight: bold;

                    display: inline-block;

                    margin-right: 20px;

                    content: attr(data-th);

                    color: $purple;
                }
            }
        }
    }

    &.speedbump
    {
        @include t-copy;
        .speedbump__top-spacer
        {
            float: left;

            width: 0;
            height: auto;

            @media screen and ($phone-only)
            {
                float: none;

                height: 0 !important;
            }
        }

        .speedbump__feature
        {
            float: right;
            clear: both;

            width: 375px;
            height: auto;
            margin: 10px 0 10px 30px;

            @media screen and ($phone-only)
            {
                float: none;

                width: 100%;
                margin: 0;
            }

            &.top
            {
                margin-top: 0;
            }
            &.left
            {
                float: left;

                margin-right: 30px;
                margin-left: 0;
            }

            .feature-container
            {
                .featured-links
                {
                    margin-bottom: 0;
                    padding-right: 0;
                    padding-left: 0;

                    .title
                    {
                        @include t-label;
                        + ul
                        {
                            margin-top: 30px !important;
                        }
                    }

                    ul
                    {
                        margin-top: 15px;
                        padding: 30px;

                        border-top: 1px solid $speednump-feature-link-border;
                        border-left: 1px solid $speednump-feature-link-border;

                        @media($phone-only)
                        {
                            padding: 20px 0 20px 20px;
                        }
                        li
                        {
                            padding: 0 0 0 30px;

                            border-bottom: 0;

                            & + li
                            {
                                margin-top: 20px;
                            }

                            a
                            {
                                font-size: 14px;

                                position: relative;

                                display: inline-block;

                                &:after
                                {
                                    @include animate;

                                    position: absolute;
                                    z-index: -1;
                                    bottom: 0;
                                    left: 0;

                                    width: 100%;
                                    height: 1px;

                                    content: '';

                                    background: $yellow;
                                }

                                &:hover
                                {
                                    &:after
                                    {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .speedbump__bottom-spacer
        {
            float: left;
            clear: both;

            width: 0;
            height: 100px;
        }
    }

    &.featured-speedbump
    {
        .title
        {
            @include t-label;

            margin-bottom: 60px;

            color: $dark-purple;
        }
        .featured-speedbump__container
        {
            //display: flex;
            @media screen and ($phone-only)
            {
                display: block;
            }
            figure
            {
                float: left;

                width: 100%;
                max-width: 280px;
                margin-right: 20px;

                @media screen and ($phone-only)
                {
                    float: none;

                    max-width: 100%;
                }
            }
            div
            {
                @include font-sofia-pro;
                @media($tablet-up)
                {
                    position: relative;
                    top: -10px;
                }
                a
                {
                    @include inline-link;

                    font-size: 22px;
                }
                p
                {
                    font-size: 1.4rem !important;
                    line-height: 2.2rem !important;

                    margin-top: 12px;

                    color: $purple-grey;
                }
            }
        }
    }
}
