.journal-page
{
    //@include outer-container;
    margin-top: 85px;
    &:after
    {
        display: block;
        clear: both;

        content: '';
    }

    ~ .related-case-study
    {
        @include margin(64px null);
        @include padding(260px null);

        position: relative;

        display: block;
        overflow: hidden;

        text-align: center;

        > .inner
        {
            @include position(absolute, 0);

            transition: all 250ms ease;

            background-position: center;
            background-size: cover;
            &:after
            {
                @include position(absolute, 0);

                display: block;

                content: '';
                transition: all 250ms ease;

                opacity: 0;
                background: rgba($black, .25);
            }
        }
        &:hover
        {
            > .inner
            {
                @include transform(scale(1.15));
                &:after
                {
                    opacity: 1;
                }
            }
        }
        &:before
        {
            @include position(absolute, 0);

            content: '';

            background-color: rgba($black, .35);
        }
        &:after
        {
            display: table;
            clear: both;

            content: '';
        }
        span
        {
            @include font-noe-display-black;

            font-size: 4.8rem;
            font-weight: 300;

            position: relative;
            z-index: 1;

            display: inline-block;

            color: $white;
            &:after
            {
                @include fontello($icon-right);

                font-size: 3.6rem;

                margin-left: 16px;
            }
        }
    }
    ~ .related-journals
    {
        //@include columnizer(7 7, 1 0);
        @include padding(80px null 64px);

        clear: both;
        .related-journal-wrapper
        {
            @include columnizer(6 6, null, null, $vertical-gutter: 64px, $set-neat-grid: $neat-grid-bleed);

            box-sizing: border-box;
            max-width: 1176px;
            margin-right: auto;
            margin-left: auto;

            @media ($phone-only)
            {
                @include columnizer(12, null, null, $vertical-gutter: 64px, $set-neat-grid: $neat-grid-bleed);
            }

            > .journal
            {
                position: relative;
                @media ($phone-only)
                {
                    margin-top: 20px;
                }
                .journal-border
                {
                    margin-right: 20px;
                    margin-left: 33%;
                    padding: 32px 30px 24px 10%;

                    border: 2px solid $yellow;

                    @media ($phone-only)
                    {
                        margin: 0 20px;
                        padding: 32px;
                    }
                }

                h1
                {
                    @include h4-sofia-pro;

                    font-size: 2.0rem;

                    transition: color 250ms ease;
                    text-transform: initial;

                    color: $purple;
                    &:hover
                    {
                        color: $dark-purple;
                    }
                }
                p
                {
                    @include font-sofia-pro;

                    font-size: 1.4rem;
                }
                a
                {
                    @include label-sofia-pro;

                    display: block;

                    margin-bottom: 25px;

                    transition: all 250ms ease;
                    text-decoration: none;

                    color: $black-50;
                    &:hover
                    {
                        color: $purple;
                    }
                }
                > figure
                {
                    position: absolute;
                    top: 50%;
                    left: 0;

                    max-width: 40%;

                    transform: translateY(-50%);
                    @media ($phone-only)
                    {
                        position: relative;
                        top: 0;
                        left: 0;

                        max-width: 100%;

                        transform: none;
                    }
                    img
                    {
                        max-width: 100%;
                        height: auto;
                        padding: 0 0 0 20px;
                        @media ($phone-only)
                        {
                            padding: 0 20px;
                        }
                    }
                    a
                    {
                        line-height: 0;

                        margin: 0;
                    }
                }
                &:nth-child(2n)
                {
                    .journal-border
                    {
                        margin-right: 33%;
                        margin-left: 20px;
                        padding: 32px 10% 24px 30px;

                        @media ($phone-only)
                        {
                            margin: 0 20px;
                            padding: 32px;
                        }
                    }
                    > figure
                    {
                        right: 0;
                        left: auto;

                        img
                        {
                            padding: 0 20px 0 0;
                            @media ($phone-only)
                            {
                                padding: 0 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .tags
    {
        @include label-sofia-pro;

        font-weight: 700;

        display: inline-block;

        color: $black-50;
    }
    header
    {
        @include margin(85px 0);
        @include padding(80px null null);
        .header-wrapper
        {
            @media (min-width: 1025px)
            {
                @include columnizer(10, 3, $set-neat-grid: $neat-grid-16, $set-max-width: 100%);
            }

            @media (max-width: 1024px)
            {
                @include columnizer(12, 2, $set-neat-grid: $neat-grid-16, $set-max-width: 100%);
            }

            @media($phone-only)
            {
                @include columnizer(16, 0, $set-neat-grid: $neat-grid-16, $set-max-width: 100%);
            }
        }
        > div
        {
            color: $black-50;
        }
        > :nth-child(2)
        {
            // @include omega();
        }
        h1
        {
            @include h1-noe-display;
            @include tracking(25);
            @include margin(24px null 30px);

            font-size: 7.5rem;
            line-height: 8.0rem;

            color: $dark-purple;
            &:after
            {
                display: block;

                width: 78px;
                height: 2px;
                margin-top: 32px;

                content: '';

                background-color: $black-50;
            }

            @media($phone-only)
            {
                font-size: 3.0rem;
                line-height: 4.0rem;
            }
        }
        .published
        {
            @include label-sofia-pro;

            font-weight: 700;

            display: inline-block;

            margin-right: 30px;

            color: $black-50;
        }
        > .figure-wrapper
        {
            $width: 19.9;
            // @include grid-column($width);
            // @include grid-shift((16-$width) / 2);

            margin-top: 85px;
            img
            {
                width: 100%;
            }
            @media (max-width: 1199px)
            {
                // @include grid-column(16);
                // @include grid-shift(0);
            }
        }
        .meta
        {
            @include font-sofia-pro;

            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2.8rem;

            margin-bottom: 10px;
        }
        .author
        {
            @include font-sofia-pro;

            font-size: 1.8rem;
            font-weight: 700;
            line-height: 2.8rem;
        }
    }
    .article-content
    {
        // @include grid-column(12);
        // @include grid-shift(2);
        // @include omega();
        @include columnizer(10, 3, $set-neat-grid: $neat-grid-16, $set-max-width: 100%);

        @media ($phone-only)
        {
            @include columnizer(16, 0, $set-neat-grid: $neat-grid-16, $set-max-width: 100%);
        }
        .article-content-wrapper
        {
            blockquote
            {
                @include margin(0 null);
                @include padding(null 0px);
            }
            figure
            {
                text-align: center;
                img
                {
                    max-width: 100%;
                    height: auto;
                }
            }
            ol.listicle
            {
                @include margin(0);

                list-style: none;
                > li
                {
                    position: relative;

                    margin-top: 64px;

                    h2
                    {
                        @include h3-noe-display;
                        @media (max-width: 480px)
                        {
                            margin-top: 10px;
                        }
                    }
                    ul
                    {
                        &:last-child
                        {
                            margin-bottom: 20px;
                        }
                    }
                    @for $i from 1 through 20
                    {
                        &:nth-child(#{$i})
                        {
                            &:before
                            {
                                content: '#{$i}';
                            }
                        }
                    }
                    &:before
                    {
                        @include position(absolute, -18px null null -72px);

                        font-family: $header-font-family;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 25px;

                        display: block;

                        box-sizing: border-box;
                        width: 60px;
                        margin-left: 0;
                        padding-top: 10px;
                        padding-bottom: 20px;

                        content: '';
                        text-align: center;

                        color: $dark-purple;
                        border: 2px solid $yellow;
                    }
                    &:after
                    {
                        display: block;
                        clear: both;

                        content: '';
                    }
                }
                &:before,
                &:after
                {
                    @include margin(80px auto);

                    display: block;

                    width: 92px;
                    height: 3px;

                    content: '';

                    background-color: $light-grey;
                }
            }
        }
        p
        {
            + blockquote,
            + figure,
            + .images
            {
                margin-top: 32px;

                &.left
                {
                    + .right
                    {
                        margin-top: 32px;
                    }
                }
            }
            + h1,
            + h2
            {
                margin-top: 50px;
            }
        }
        .images,
        .captions
        {
            &.slick-initialized
            {
                figure
                {
                    float: left;

                    margin-right: 0;
                }
            }
        }
        .images
        {
            img
            {
                width: 100%;
                max-width: 100%;
            }
            &.slick-initialized
            {
                figure
                {
                    @include user-select(none);
                }
            }
        }
        :not(aside) > blockquote,
        :not(aside) > .images,
        > blockquote,
        > .images
        {
            // @include grid-column(20);
            // @include grid-shift(-2);
            margin-top: 0;
            + .captions
            {
                @include margin(0 null 32px);

                clear: both;
            }
            &:after
            {
                display: block;
                clear: both;

                content: '';
            }
        }
        > h1,
        > h2,
        > h3,
        p
        {
            @include margin(0 null 16px);
        }
        h1,
        h2,
        h3
        {
            letter-spacing: 0;
            text-transform: none;
        }
        h1
        {
            @include h2-noe-display;
        }
        h2
        {
            @include h3-noe-display;

            color: $black-50;
        }
        h3
        {
            @include h4-sofia-pro;

            color: $black-50;
        }
        p
        {
            @include font-sofia-pro;

            font-size: 1.8rem;
            line-height: 2.8rem;
        }
        ol:not(.listicle),
        ul:not(.slick-dots)
        {
            font-size: 2.0rem;

            clear: both;

            list-style: none;
            > li
            {
                @include copy-sofia-pro;
                &:before
                {
                    font-weight: 600;

                    float: left;

                    margin-right: 8px;
                    margin-left: -20px;

                    color: $dark-purple;
                }
            }
        }
        ul
        {
            &:not(.slick-dots)
            {
                > li
                {
                    &:before
                    {
                        content: '•';
                    }
                }
            }
        }
        ol:not(.listicle)
        {
            li
            {
                @for $i from 1 through 20
                {
                    &:nth-child(#{$i})
                    {
                        &:before
                        {
                            content: '#{$i}.';
                        }
                    }
                }
            }
        }

        .pusher-left
        {
            float: left;
            clear: left;
        }
        .pusher-right
        {
            float: right;
            clear: right;
        }
        .figure-wrapper
        {
            figure
            {
                @include columnizer(16, -2, $collapse: true);
                img
                {
                    display: block;
                }
                figcaption
                {
                    font-family: $header-font-family-serif;
                    font-weight: 300;
                    line-height: 48px;

                    text-align: center;

                    color: $grey;
                }
                &.gallery.row
                {
                    img
                    {
                        display: inline-block;

                        vertical-align: middle;
                    }
                }
            }
        }
        blockquote
        {
            @include padding(20px 30px);

            box-sizing: border-box;

            p
            {
                @include h2-noe-display;

                margin-bottom: 0;

                color: $black-50;
            }
            .attribution
            {
                @include h4-sofia-pro;

                font-weight: 500;
            }
        }
        aside
        {
            // }
            margin-bottom: 16px;
            // @include direction-context(right-to-left)
            // {
            &.right
            {
                // @include grid-column(10);
                // @include grid-shift(-2);
                clear: right;
                blockquote,
                figure
                {
                    // @include grid-column(16);
                    // @include grid-shift(0);
                }
            }
            blockquote
            {
                text-align: left;
            }
            &.left
            {
                // @include grid-column(10);
                // @include grid-shift(-2);
                clear: left;
                blockquote,
                figure
                {
                    // @include grid-column(16);
                    // @include grid-shift(0);
                }
            }
            &.center
            {
                + h1
                {
                    margin-top: 50px;
                }
                .images
                {
                    figure
                    {
                        img
                        {
                            @include margin(null auto);
                        }
                    }
                }
                blockquote
                {
                    @include margin(40px null);
                }
            }
            .images
            {
                figure
                {
                    img
                    {
                        width: auto !important;
                        max-width: 100% !important;
                    }
                }
            }
        }

        .content
        {
            &.gallery
            {
                // @include grid-column(20);
                // @include grid-shift(-2);
                padding: 0;
                .item
                {
                    position: relative;

                    float: left;

                    width: 50%;
                    padding: 0;

                    border: 0;
                    .description
                    {
                        @include transition(opacity ease .5s);

                        position: absolute;
                        z-index: 10;

                        width: 100%;
                        height: 100%;

                        text-align: center;

                        opacity: 0;
                        border: 0;
                        background-color: rgba(0,0,0,.8);
                        .subhead
                        {
                            @include font-sofia-pro;

                            font-size: 2.6rem;
                            font-weight: 300;
                            font-style: italic;

                            margin-top: 0;
                            padding-top: 8px;

                            text-indent: 0;

                            color: $white;
                        }
                        .opera
                        {
                            @include margin(0);

                            font-size: 1.8rem;
                            font-weight: 400;

                            padding-top: 0;

                            letter-spacing: 0;
                            text-transform: none;

                            color: $light-grey;
                        }
                        .title
                        {
                            @include transition(color ease .5s);
                            @include padding(10px);
                            @include font-sofia-pro;

                            font-size: 2.4rem;
                            font-weight: 400;

                            box-sizing: border-box;
                            margin-bottom: 0;

                            text-indent: 0;
                            letter-spacing: 4px;
                            text-transform: uppercase;

                            color: $white;
                            &:hover
                            {
                                @include transition(color ease .5s);

                                color: $red;
                            }
                        }
                        hr
                        {
                            width: 15%;
                            margin: 10px auto;

                            border-top: 2px solid $white;
                        }
                        .content
                        {
                            display: table;

                            width: 100%;
                            height: 100%;
                            padding: 0;
                            span
                            {
                                display: table-cell;

                                vertical-align: middle;
                            }
                        }
                    }
                    &:hover a.gallerybox .description
                    {
                        @include transition(opacity ease .5s);

                        opacity: 1;
                    }
                    img
                    {
                        z-index: 5;

                        margin: 0;
                        padding: 0;

                        border: 0;
                    }
                    a.preview
                    {
                        line-height: 0;

                        display: block;
                    }
                }
            }
        }
    }
}
a.related-case-study
{
    color: transparent;

    span
    {
        padding: 0 5px;
    }
}
i
{
    display: inline-block;
    &.f
    {
        &.facebook
        {
            &:before
            {
                @include fontello($icon-facebook);

                font-size: 3.2rem;
            }
        }
        &.twitter
        {
            &:before
            {
                @include fontello($icon-twitter);

                font-size: 3.2rem;
            }
        }
        &.linkedin
        {
            &:before
            {
                @include fontello($icon-linkedin);

                font-size: 3.2rem;
            }
        }
    }
}
.social
{
    @include margin(16px null);
    span
    {
        @include label-sofia-pro;

        font-weight: 700;

        display: block;

        margin-bottom: 10px;

        color: $black-50;
    }
    a
    {
        display: inline-flex;

        width: 40px;
        height: 40px;

        text-decoration: none;

        color: $black-50;

        align-items: center;
        justify-content: center;
        &:hover
        {
            color: $red;
        }
        &.facebook
        {
            &:hover
            {
                color: $facebook-color;
            }
        }
        &.twitter
        {
            &:hover
            {
                color: $twitter-color;
            }
        }
        &.linkedin
        {
            &:hover
            {
                color: $linkedin-color;
            }
        }
    }
}

@media($small-screen)
{
    .journal-page
    {
        margin-top: 0;
        header
        {
            @include margin(32px null);
            h1
            {
                font-size: 4.8rem;
            }
            aside
            {
                // @include grid-column(6);
                text-align: center;
            }
            > figure
            {
                margin-top: 32px;
            }
            > div
            {
                // @include grid-column(5);
                // @include grid-shift(.5);
            }
            .social
            {
                @include margin(8px null);
            }
        }
        .article-content
        {
            // @include grid-column(5);
            // @include grid-shift(.5);
            aside
            {
                // @include direction-context(right-to-left)
                // {
                &.right
                {
                    // @include grid-column(6);
                    // @include grid-shift(0)
                    clear: left;
                    blockquote,
                    figure
                    {
                        // @include grid-column(6);
                        // @include grid-shift(0);
                    }
                }
                // }
                blockquote
                {
                    text-align: center;
                }
                &.left
                {
                    // @include grid-column(6);
                    // @include grid-shift(0);
                    clear: left;
                    blockquote,
                    figure
                    {
                        // @include grid-column(6);
                        // @include grid-shift(0);
                    }
                }
            }
            .pusher-left,
            .pusher-right
            {
                display: none;
            }
            :not(aside) > blockquote,
            :not(aside) > .images,
            > blockquote,
            > .images
            {
                // @include grid-column(7);
                // @include grid-shift(-.5);
                margin-top: 0;
                + .captions
                {
                    @include margin(0 null 32px);

                    clear: both;
                }
                &:after
                {
                    display: block;
                    clear: both;

                    content: '';
                }
            }
            > ol
            {
                &:not(.slick-dots)
                {
                    &.listicle
                    {
                        > li
                        {
                            &:not(:first-child)
                            {
                                margin-top: 20px;
                            }
                            &:before
                            {
                                left: -32px;
                            }
                            > h2
                            {
                                padding-left: 32px;
                            }
                        }
                    }
                }
            }
            .content
            {
                &.gallery
                {
                    // @include grid-column(7);
                    // @include grid-shift(-.5);
                }
            }
        }
        ~ .related-case-study
        {
            @include margin(32px null);
            @include padding(128px null);
            span
            {
                // @include grid-column(5);
                // @include grid-shift(.5);
            }
        }
        ~ .related-journals
        {
            @include padding(20px null 16px);

            > .journal
            {
                @include margin(16px null);
                &:nth-child(2n),
                &:nth-child(2n+1)
                {
                    text-align: center;
                    > a
                    {
                        &:before,
                        &:after
                        {
                            display: none;
                        }
                    }
                    > figure
                    {
                        // @include grid-column(6);
                        // @include grid-shift(0);
                    }
                }
            }
        }
    }
    .slider
    {
        position: relative;

        margin-top: 40px;

        .slide
        {
            img
            {
                width: 100%;
            }
        }

        .tns-nav
        {
            display: none;
        }

        [data-controls='prev'],
        [data-controls='next']
        {
            @include animate;

            font-size: 0;

            position: absolute;
            z-index: 1;

            display: table-cell;

            height: 100%;
            padding: 0 35px 0 25px;

            vertical-align: middle;

            color: $purple;
            border: 0;
            outline: 0;
            background-color: transparent;

            &:before
            {
                @include fontello($icon-chevron-right);

                font-size: 24px;
                line-height: 1;

                position: absolute;
                top: 50%;

                transform: translateY(-50%);
            }

            &:hover
            {
                background-color: hsla(0,0%,100%,.4);
            }
            &[disabled]
            {
                display: none;
            }
        }
        [data-controls='prev']
        {
            left: 0;
            &:before
            {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
        [data-controls='next']
        {
            right: 0;
        }
    }
}
