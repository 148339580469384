@import 'video-js';
section.video
{
    background-size: cover !important;
    .row
    {
        display: contents !important;

        width: 100%;
        @media($phone-and-tablet)
        {
            .column
            {
                padding: 0 20px;
            }
        }
    }
}
.video-js
{
    background: $black !important;
    &:hover
    {
        .vjs-big-play-button
        {
            background-color: $yellow;
            span
            {
                color: $purple;
            }
        }
    }
    .vjs-big-play-button
    {
        top: 50%;
        left: 50%;

        width: 100px;
        height: 100px;

        transform: translateX(-50%) translateY(-50%);

        border: none;
        border-radius: 50%;
        background-color: $yellow;
        .vjs-icon-placeholder
        {
            @include animate;
            &:before
            {
                @include font-sofia-pro;

                font-size: 1.0rem;
                font-weight: bold;
                line-height: 100px;

                content: 'PLAY';
                letter-spacing: 2px;

                color: $purple;
            }
        }
    }
    .vjs-poster
    {
        &:before
        {
            @include position(absolute, 0);

            content: '';

            background-color: rgba(0,0,0,.3);
        }
    }
    .vjs-control-bar
    {
        height: 115px;

        background-color: initial;

        align-items: center;

        &:before
        {
            @include position(absolute, 0);

            content: '';
            pointer-events: none;

            opacity: .35;
            background-image: $black-gradient;
        }
        .vjs-control
        {
            line-height: 2rem;

            height: 2rem;
            min-height: 2rem;
            padding: 0;

            outline: none;
            &.vjs-button
            {
                &:hover
                {
                    span
                    {
                        color: $purple;
                    }
                }
            }
        }
        .vjs-button
        {
            > .vjs-icon-placeholder
            {
                @include animate;
                &:before
                {
                    line-height: 2rem;
                }
            }
        }
        .vjs-play-control,
        .vjs-current-time,
        .vjs-time-divider,
        .vjs-duration,
        .vjs-volume-panel,
        .vjs-fullscreen-control
        {
            display: flex;

            margin-top: 20px;
        }
        .vjs-play-control
        {
            margin-left: 20px;

            order: 1;
            &.vjs-paused
            {
                .vjs-icon-placeholder
                {
                    &:before
                    {
                        @include fontello($icon-play);

                        font-size: 2.3rem;
                        line-height: 1.8rem;
                    }
                }
            }
            &.vjs-playing
            {
                .vjs-icon-placeholder
                {
                    &:before
                    {
                        @include fontello($icon-pause);

                        font-size: 2.3rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }
        .vjs-time-control
        {
            @include t-meta;
            @include padding(null 0);

            width: auto;
            min-width: 0;
            padding-top: 1px;

            color: $white;
            &.vjs-time-divider
            {
                @include padding(null 8px);
            }
        }
        .vjs-current-time
        {
            margin-top: 15px;
            margin-left: 16px;

            order: 2;
        }
        .vjs-time-divider
        {
            order: 3;
        }
        .vjs-duration
        {
            margin-top: 15px;

            order: 4;
        }
        .vjs-volume-panel
        {
            margin-left: auto;

            order: 5;
            &.vjs-hidden
            {
                ~ .vjs-fullscreen-control
                {
                    margin-left: auto;
                }
            }
            .vjs-icon-volume-mute
            {
                &:before
                {
                    @include fontello($icon-mute);
                }
            }
            .vjs-icon-volume-low
            {
                &:before
                {
                    @include fontello($icon-volume-1);
                }
            }
            .vjs-icon-volume-mid
            {
                &:before
                {
                    @include fontello($icon-volume-2);
                }
            }
            .vjs-icon-volume-high
            {
                &:before
                {
                    @include fontello($icon-volume-3);
                }
            }
            .vjs-icon-volume-mute,
            .vjs-icon-volume-low,
            .vjs-icon-volume-mid,
            .vjs-icon-volume-high
            {
                &:before
                {
                    font-size: 2.3rem;
                    line-height: 1.8rem;
                }
            }
            .vjs-volume-control
            {
                &.vjs-volume-horizontal
                {
                    height: 2rem;
                }
                .vjs-volume-bar
                {
                    @include margin(9px null);

                    height: 2px;

                    background-color: $grey;
                    .vjs-volume-level
                    {
                        height: 2px;
                        &:before
                        {
                            display: none;
                        }
                    }
                }
            }
        }
        .vjs-fullscreen-control
        {
            margin-right: 20px;

            order: 6;
            .vjs-icon-fullscreen-enter
            {
                &:before
                {
                    @include fontello($icon-fullscreen);
                }
            }
            .vjs-icon-fullscreen-exit
            {
                &:before
                {
                    @include fontello($icon-fullscreen-exit);
                }
            }
            .vjs-icon-fullscreen-enter,
            .vjs-icon-fullscreen-exit
            {
                &:before
                {
                    font-size: 2.3rem;
                    line-height: 1.8rem;
                }
            }
        }
        .vjs-remaining-time
        {
            display: none;
        }
        .vjs-progress-control
        {
            @include position(absolute, null 0 0);

            width: 100%;
            height: 4px;
            min-height: 4px;
            .vjs-progress-holder
            {
                @include margin(null 0);

                height: 4px;

                background-color: $grey;
            }
            .vjs-play-progress
            {
                background-color: $purple;
                &:before
                {
                    top: -5px;
                    right: -6px;

                    display: block;

                    width: 13px;
                    height: 13px;

                    content: '';

                    border-radius: 13px;
                    background-color: $purple;
                }
            }
            .vjs-load-progress
            {
                background-color: $light-grey;
                div
                {
                    background-color: $light-grey;
                }
            }
            .vjs-mouse-display
            {
                display: none;
            }
            .vjs-time-tooltip
            {
                @include t-meta;

                top: -2.6rem;

                padding: 0;

                color: $white;
                background-color: transparent;
            }
        }
    }
}
