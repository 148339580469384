.dk-select,
.dk-select *,
.dk-select *:before,
.dk-select *:after,
.dk-select-multi,
.dk-select-multi *,
.dk-select-multi *:before,
.dk-select-multi *:after
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.dk-select,
.dk-select-multi
{
    line-height: 1em;

    position: relative;

    display: inline-block;

    width: 100%;
    margin-bottom: 40px;

    cursor: pointer;
    vertical-align: middle;
}

.dk-selected
{
    font-family: 'sofia-pro', 'Trebuchet MS', sans-serif;
    font-size: rem(20px);
    font-weight: 600;
    line-height: 1;

    position: relative;

    overflow: hidden;

    width: 100%;
    padding: 20px;

    text-align: center;
    white-space: nowrap;

    border-bottom: 3px solid rgba(0, 0, 0, .25);
}

.dk-selected:before,
.dk-selected:after
{
    position: absolute;
    right: 0;

    display: block;

    content: '';
}

.dk-selected:before
{
    @include animate;
    //icon arrow down
    @include fontello($icon-chevron-right);

    font-size: 2rem;
    line-height: 2rem;

    position: absolute;
    right: 15px;

    transform: rotate(90deg);
}

.dk-selected:hover
{
    background-color: #ffffff;
}

.dk-selected-disabled
{
    color: #8c8c8c;
}

.dk-select .dk-select-options
{
    font-family: 'sofia-pro', 'Trebuchet MS', sans-serif;
    font-size: 1.3rem;
    line-height: 1;

    position: absolute;
    left: 0;

    display: none;

    text-align: center;
}

.dk-select-open-up .dk-select-options
{
    bottom: 100%;
}

.dk-select-open-down .dk-select-options
{
    top: 100%;
}

.dk-select-multi .dk-select-options
{
    max-height: 10em;
}

.dk-select-options
{
    z-index: 100;

    overflow-x: hidden;
    overflow-y: auto;

    width: 100%;
    margin: 0;
    padding: 16px 20px;

    list-style: none;

    color: #8c8c8c;
    background-color: #f9f9f9;
}

.dk-select-options-highlight .dk-option-selected
{
    background-color: transparent;
}

.dk-option
{
    padding-bottom: 20px;
}

.dk-option.dk-option-selected
{
    color: #9d00ff;
}

.dk-select-options .dk-option-highlight
{
    color: #313131;
}

.dk-select-options .dk-option-disabled
{
    color: #8c8c8c;
    background-color: transparent;
}

.dk-optgroup
{
    margin-top: .25em;
    padding: .25em 0;

    border: solid #f9f9f9;
    border-width: 1px 0;
}

.dk-optgroup + .dk-option
{
    margin-top: .25em;
}

.dk-optgroup + .dk-optgroup
{
    margin-top: 0;

    border-top-width: 0;
}

.dk-optgroup:nth-child(2)
{
    margin-top: 0;
    padding-top: 0;

    border-top: none;
}

.dk-optgroup:last-child
{
    margin-bottom: 0;
    padding-bottom: 0;

    border-bottom-width: 0;
}

.dk-optgroup-label
{
    font-weight: bold;

    width: 100%;
    padding: 0 .5em .25em;
}

.dk-optgroup-options
{
    padding-left: 0;

    list-style: none;
}

.dk-optgroup-options li
{
    padding-left: 1.2em;
}

.dk-select-open-up .dk-selected
{
    border-color: #ececec;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dk-select-open-down .dk-selected
{
    border-color: #f9f9f9;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ffffff;
}

.dk-select-open-down .dk-selected:before
{
    transform: rotate(-90deg);
    //icon arrow up
    //position: absolute;
    //right: 15px;

    //content: '\e682';
}

.dk-select-open-up .dk-selected:before,
.dk-select-open-down .dk-selected:before
{
    //border-width: 0 .25em .25em;
    border-bottom-color: #ececec;
}

.dk-select-open-up .dk-selected:after,
.dk-select-open-down .dk-selected:after
{
    border-left-color: #ececec;
}

.dk-select-open-up .dk-select-options,
.dk-select-open-down .dk-select-options,
.dk-select-multi:focus .dk-select-options
{
    display: block;

    border-color: #ececec;
}

.dk-select-multi:hover,
.dk-select-multi:focus
{
    outline: none;
}

.dk-selected:hover,
.dk-selected:focus
{
    outline: none;
}

.dk-select-disabled
{
    cursor: not-allowed;

    opacity: .6;
    color: #8c8c8c;
}

.dk-select-disabled .dk-selected:hover,
.dk-select-disabled .dk-selected:focus
{
    border-color: inherit;
}

.dk-select-disabled .dk-selected:hover:before,
.dk-select-disabled .dk-selected:focus:before
{
    border-top-color: inherit;
}

.dk-select-disabled .dk-selected:hover:after,
.dk-select-disabled .dk-selected:focus:after
{
    border-left-color: inherit;
}

select[data-dkcacheid]
{
    display: none;
}
