main
{
    &.about
    {
        position: relative;

        background-color: $white;

        header
        {
            h1
            {
                line-height: inherit;

                @media($phone-only)
                {
                    font-size: 3.1rem;
                    line-height: 3.125rem;
                    small
                    {
                        font-size: 70%;
                    }
                }
            }
        }

        .capabilities
        {
            .wrapper
            {
                display: table;
                float: none;

                box-sizing: border-box;
                width: 100%;
                max-width: 833px;
                margin: 0 auto;
                padding: 0 20px;
            }
            .buckets
            {
                margin: 30px auto;
                .centered
                {
                    max-width: 833px;
                    margin: 0 auto;
                }
                h3
                {
                    font-size: 1.75em;
                }
                .row
                {
                    @include columnizer(6 6, $vertical-gutter: 30px, $collapse: true);

                    float: none;

                    box-sizing: border-box;
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 30px;

                    @media($tablet-up)
                    {
                        display: table;
                    }

                    @media($phone-only)
                    {
                        @include columnizer(12, $vertical-gutter: 0, $collapse: false);

                        margin-bottom: 0;

                        .panel
                        {
                            margin-bottom: 30px;
                        }
                    }


                    img
                    {
                        display: inline-block;

                        width: 100%;
                        height: auto;

                        vertical-align: middle;
                    }
                    .title
                    {
                        a
                        {
                            font-size: 1.7rem;

                            display: block;

                            padding: 20px 0 15px;

                            text-align: center;
                            text-decoration: none;
                            &:before
                            {
                                font-size: 2.0rem;

                                top: 23px;
                            }
                        }
                        span
                        {
                            position: relative;

                            display: block;
                            overflow: hidden;

                            margin: 0 37px;

                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .button
                        {
                            &:before
                            {
                                @include fontello($icon-chevron-right);
                                @media($phone-only)
                                {
                                    display: none;
                                }
                            }
                        }
                    }
                    .panel:after,
                    .panel:before
                    {
                        display: table;

                        content: ' ';
                    }

                    &:last-child
                    {
                        margin-bottom: 0;
                    }
                }
                &:last-child
                {
                    margin-bottom: 0;
                }
            }
        }
        .life
        {
            padding-bottom: 0 !important;
            .wrapper
            {
                display: table;
                float: none;

                box-sizing: border-box;
                width: 100%;
                max-width: 833px;
                margin: 0 auto;
            }
            .slider
            {
                position: relative;

                margin-top: 40px;

                .slide
                {
                    img
                    {
                        width: 100%;
                    }
                }

                .tns-nav
                {
                    display: none;
                }

                [data-controls='prev'],
                [data-controls='next']
                {
                    @include animate;

                    font-size: 0;

                    position: absolute;
                    z-index: 1;

                    display: table-cell;

                    height: 100%;
                    padding: 0 35px 0 25px;

                    vertical-align: middle;

                    color: #ffff00;
                    border: 0;
                    outline: 0;
                    background-color: transparent;

                    &:before
                    {
                        @include fontello($icon-chevron-right);

                        font-size: 24px;
                        line-height: 1;

                        position: absolute;
                        top: 50%;

                        transform: translateY(-50%);
                    }

                    &:hover
                    {
                        background-color: hsla(0,0%,100%,.4);
                    }
                    &[disabled]
                    {
                        display: none;
                    }
                }
                [data-controls='prev']
                {
                    left: 0;
                    &:before
                    {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }
                [data-controls='next']
                {
                    right: 0;
                }
            }
        }
        .team
        {
            padding-bottom: 0 !important;
            h1
            {
                margin-right: 0;
                margin-left: 0;
            }
            h2
            {
                margin-bottom: 30px;
            }

            @media($phone-only)
            {
                padding-top: 0;
            }
        }
    }

    //
    // BIO GRID
    //
    .team-members
    {
        overflow: hidden;

        margin: 0;
        padding: 0;

        list-style-type: none;
        .person
        {
            float: left;

            width: 20%;

            @media($tablet-only)
            {
                width: 25%;
            }
            @media(min-width: 500px) and (max-width: 767px)
            {
                width: 50%;
            }
            @media(max-width: 499px)
            {
                width: 100%;
            }
            .preview
            {
                position: relative;

                display: block;

                padding: 0;

                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                img
                {
                    width: 100%;

                    //opacity: 0;
                }
                &:hover
                {
                    .info
                    {
                        opacity: 1;
                    }
                }
            }
            .info
            {
                position: absolute;
                z-index: 10;
                bottom: 0;

                display: flex;

                width: 100%;
                //height: 30%;

                transition: opacity .5s ease;

                opacity: 0;
                //background-color: rgba(0, 0, 0, .4);
                background-image: linear-gradient(to bottom, rgba(0,00,0,0), rgba(0,0,0,.3));

                .content
                {
                    height: 100%;
                    margin: 0 auto;
                    padding: 20px 20px 10px 20px;
                    //display: table;

                    text-align: center;
                    div
                    {
                        //display: table-cell;
                        //
                        //vertical-align: bottom;
                    }
                }
                h3
                {
                    font-family: $paragraph-font-family;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 29px;

                    margin: 0;

                    letter-spacing: 0;
                    text-transform: none;

                    color: $white;
                }
                .title
                {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;

                    margin: 0 0 0;

                    letter-spacing: 3px;
                    text-transform: uppercase;

                    color: $white;
                }
            }
        }
    }
}

//
// BIO LIGHTBOX
//


.profile
{
    position: fixed;
    top: calc(5vw + 43px);
    right: 5vw;
    bottom: 5vw;
    left: 5vw;

    overflow-x: scroll;
    //width: 100%;
    //height: 100vh;

    background-color: $white;

    .container
    {
        width: 66.6666666667%;
        margin: 0 auto;
        padding: 60px 0;

        @media($phone-only)
        {
            width: 80%;
            padding: 60px 0;
        }
    }
    header
    {
        width: 100%;
        margin-bottom: 40px;

        text-align: center;
    }
    h1
    {
        @include t-h1;

        @media($phone-only)
        {
            font-size: 2.8rem;
            line-height: 3.8rem;
        }
    }
    hr
    {
        clear: both;

        height: 0;
        margin: 1.25rem 0 1.1875rem;

        border: solid #dadada;
        border-width: 1px 0 0;
    }
    .description
    {
        font-family: $header-font-family;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.2rem;

        list-style: none;

        letter-spacing: 3px;
        text-transform: uppercase;
        @media($phone-only)
        {
            padding-left: 0;
        }
        @media ($tablet-up)
        {
            margin: 0 0 5px;
        }
        li
        {
            list-style: none;

            color: $grey;
            @media ($tablet-up)
            {
                display: inline-block;
            }
            + li
            {
                @media($phone-only)
                {
                    margin-top: 5px;
                }
            }
            &::after
            {
                @media ($tablet-up)
                {
                    padding: 0 15px;

                    content: '/';

                    color: $purple;
                }
            }
            &:last-child
            {
                &::after
                {
                    padding: 0;

                    content: '';
                }
            }
        }
    }
    .questions
    {
        dt
        {
            margin-bottom: 0;
        }
        dd
        {
            margin-bottom: 20px;
        }
    }
    p
    {
        font-family: $sofia-pro;
        font-size: 1.6rem;
        b
        {
            display: block;

            margin-bottom: 5px;
        }
    }
    .bottom
    {
        @include clearfix;

        margin-top: 20px;

        color: $grey;
        background-color: $white;
    }
    .right-content
    {
        position: relative;

        float: right;

        width: 60%;
        padding-right: .9375rem;
        padding-left: .9375rem;
        @media($tablet-only)
        {
            width: 55%;
        }
        @media($phone-only)
        {
            float: none;

            width: calc(100% - 40px);
            padding: 20px;
        }
    }
    .left-content
    {
        position: relative;

        float: left;

        width: 33%;
        padding-right: .9375rem;
        padding-left: .9375rem;

        .pen-slider
        {
            padding: 0;
        }

        img
        {
            max-width: 100%;
        }

        .slide
        {
            img
            {
                width: 100%;
            }
        }

        .tns-nav
        {
            display: none;
        }

        [data-controls='prev'],
        [data-controls='next']
        {
            @include animate;

            font-size: 0;

            position: absolute;
            z-index: 1;

            display: table-cell;

            height: 100%;
            padding: 0 35px 0 25px;

            vertical-align: middle;

            color: #ffffff;
            border: 0;
            outline: 0;
            background-color: transparent;

            &:before
            {
                @include fontello($icon-chevron-right);

                font-size: 24px;
                line-height: 1;

                position: absolute;
                top: 50%;

                transform: translateY(-50%);
            }

            &:hover
            {
                background-color: hsla(0,0%,100%,.4);
            }
            &[disabled]
            {
                display: none;
            }
        }
        [data-controls='prev']
        {
            left: 0;
            &:before
            {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
        [data-controls='next']
        {
            right: 0;
        }
        @media($phone-only)
        {
            float: none;

            width: 100%;
            padding: 0;
        }
        img
        {
            display: inline-block;

            width: auto;
            max-width: 100%;
            //padding-right: 30px;
            @media($phone-only)
            {
                padding-right: 0;
            }
        }
    }
}

.fancybox-wrap
{
    .fancybox-skin
    {
        position: fixed;
        top: 5vw;
        right: 5vw;
        left: 5vw;

        background: white;

        .fancybox-outer
        {
            position: relative;

            //&:before
            //{
            //    position: fixed;
            //    z-index: 1;
            //    top: 0;
            //
            //    width: 100vw;
            //    height: 43px;
            //
            //    content: '';
            //
            //    background: $white;
            //}

            .fancybox-inner
            {
                height: 42.63px !important;

                background-color: $white;
            }
        }

        .fancybox-close
        {
            top: 0;
            left: 50%;

            width: max-content;
            padding: 13px;

            transform: translateX(-50%);

            background-color: #ffffff;

            &:before
            {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .fancybox-nav
    {
        font-family: sofia-pro,sans-serif;
        font-size: 10px;
        font-weight: normal;
        line-height: 22px;

        z-index: 1;

        display: block;

        width: auto;
        height: auto;
        padding: 1rem 2rem 1.0625rem;

        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;

        color: $dark-grey;
        background-color: $light-grey;

        &:hover
        {
            color: $white;
            background-color: #aaaaaa;
        }
    }
}
