$sofia-pro: 'sofia-pro', verdana, sans-serif;
$noe-display-black: 'NoeDisplay-Black', serif;

$preload-base-font-family: sans-serif;
$base-font-family: $sofia-pro;

@font-face
{
    font-family: 'NoeDisplay-Black';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/noe-display-black.eot');
    src: url('../fonts/noe-display-black.woff') format('woff'),
    url('../fonts/noe-display-black.eot?#iefix') format('embedded-opentype');
}

/* -----------------------------------------
	Mixins
----------------------------------------- */
@mixin font-sofia-pro
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-weight: 400;
}

@mixin font-sofia-pro-bold
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-weight: 700;
}

@mixin font-noe-display-black
{
    font-family: 'NoeDisplay-Black', serif;
    font-weight: 700;
}

@mixin h1-noe-display
{
    @include tracking(25);

    font-family: 'NoeDisplay-Black', serif;
    font-size: 7.5rem;
    font-weight: 700;
    line-height: 8.0rem;
}

@mixin h2-noe-display
{
    font-family: 'NoeDisplay-Black', serif;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 5.0rem;
}

@mixin quote-text
{
    font-family: 'NoeDisplay-Black', serif;
    font-size: 32px;
    line-height: 46px;

    letter-spacing: .8px;

    color: $black-50;

    @media (max-width: 767px)
    {
        font-size: 28px;
        line-height: 38px;
    }
}

@mixin h3-noe-display
{
    font-family: 'NoeDisplay-Black', serif;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.2rem;
}

@mixin h4-sofia-pro
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-size: 2.0rem;
    font-weight: 700;
    line-height: 2.6rem;
}

@mixin copy-sofia-pro
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.8rem;
}

@mixin label-sofia-pro
{
    @include tracking(800);

    font-family: 'sofia-pro', verdana, sans-serif;
    font-size: 1.0rem;
    font-weight: 400;
    line-height: 2.6rem;

    text-transform: uppercase;
}

@mixin button-sofia-pro
{
    @include tracking(400);

    font-family: 'sofia-pro', verdana, sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.6rem;

    text-transform: uppercase;
}
