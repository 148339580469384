html
{
    //browser font size hack for accessibility to make rem multiple of 10's eg. 1rem = 10px
    font-size: 62.5%;

    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html,
body
{
    position: absolute;

    width: 100%;
    height: 100%;

    color: $black-50;
}
::selection
{
    color: $white;
    background: $purple;
    text-shadow: none;
}

html
{
    -webkit-font-smoothing: antialiased;
}
main
{
    position: relative;

    display: block;

    height: auto;
    // padding-top: 85px;
}
.content
{
    padding: 110px 0;

    background-color: transparent;
    &.inner-landing
    {
        background-color: $white;
    }
}
.center
{
    text-align: center;
}
.full
{
    max-width: 100%;
    &.two-columns
    {
        .left,
        .right
        {
            max-width: 570px;
        }
        .left
        {
            float: right;
        }
        .right
        {
            float: left;
        }
    }
}
.black-overlay
{
    @include transition(all .5s ease);

    position: absolute;
    top: 0;

    display: block;

    width: 100%;
    height: 100%;

    background-color: rgba(0,0,0,.5);
}
figure
{
    margin: 0;
}
hr
{
    &.short
    {
        clear: both;

        width: 60px;
        height: 0;
        margin: 30px 0;
        margin-right: auto;
        margin-left: auto;

        border-width: 2px 0 0;
    }
    &.light
    {
        border-color: $light-grey;
    }
    &.dark
    {
        border-color: $black-50;
    }
    &.white
    {
        border-color: $dark-purple-50;
    }
}

// Meta
dl.meta
{
    font-size: 1rem;

    position: relative;

    display: block;

    margin: 10px 20px 20px 0;
    padding-bottom: 20px;

    color: $dark-grey;
    &:after
    {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 45%;
        height: 2px;
        margin: 0 auto;

        content: '';

        background-color: #e0e0e0;
    }
    &:last-child
    {
        &:after
        {
            height: 0;
        }
    }
    dt
    {
        @include label-sofia-pro;

        font-weight: 600;

        margin: 0;

        text-transform: uppercase;

        color: $black-50;
    }
    dd
    {
        @include copy-sofia-pro;

        font-size: 1.5rem;
        line-height: 2.3rem;

        margin-bottom: 5px;

        color: $black-50;
    }
    a
    {
        color: $black-50;
        &:hover
        {
            color: $purple;
        }
    }
    &.services
    {
        dd
        {
            display: inline;
            &:after
            {
                content: ',';
            }
            &:last-child
            {
                &:after
                {
                    content: '';
                }
            }
        }
    }
}

// Buttons
.button
{
    @include tracking(400);

    margin: 1rem 0;

    text-transform: uppercase;
    &:hover,
    &:focus
    {
        color: $blue;
    }
    &:active
    {
        color: $white;
    }
    &.white
    {
        font-weight: 600;

        border: 3px solid $yellow;
        background-color: transparent;
    }
    &.grey
    {
        color: $dark-grey;
        border: 0;
        background-color: $light-grey;
    }
}

// Panel
.panel
{
    @include clearfix;

    position: relative;

    display: block;

    margin: 0;
    padding: 0;

    border: 0 solid;
    background-color: $purple-black;
    .title
    {
        position: relative;
        z-index: 90;

        display: block;
        &:after
        {
            @include transition(all .5s ease);

            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;

            width: 100%;
            height: 12px;

            content: '';

            background: $yellow;
        }
        &:hover
        {
            &:after
            {
                @include transition(all .5s ease);

                height: 100%;
            }
        }
    }
    .button
    {
        @include transition(color .5s ease);

        font-family: 'sofia-pro', 'Trebuchet MS', sans-serif;
        font-size: 1.225rem;
        font-weight: 700;

        position: relative;

        overflow: hidden;

        width: 100%;
        height: 100%;
        margin: 0;
        padding: 20px 40px 12px;

        letter-spacing: 1px;
        text-transform: initial;

        color: $white;
        border: 0;
        border-top: 6px solid $yellow;
        background-color: transparent;
        &:before
        {
            @include transition(all .5s ease);

            font-size: 50px;
            line-height: 1;

            position: absolute;
            top: 8px;
            right: 25px;

            height: 100%;

            color: $white;
        }
        &:hover
        {
            color: $purple-black;
            background-color: transparent;
            &:before
            {
                color: $purple-black;
            }
        }
    }
}

/* =======================================================================
	Typography
========================================================================== */
h1
{
    @include h1-noe-display;

    font-size: 7.5rem;
    font-style: normal;

    margin-top: .2rem;
    margin-bottom: .8rem;

    color: $black-50;

    text-rendering: optimizeLegibility;
}
h2
{
    @include h2-noe-display;

    color: $black-50;
}
h3
{
    font-weight: 600;
}
h4,
h5,
h6
{
    font-weight: 600;

    color: $dark-grey;
}
h5,
h6
{
    font-weight: 700;
}

section
{
    &:not(.top)
    {
        h1
        {
            @include h2-noe-display;

            @media (max-width: 767px)
            {
                font-size: 2.8rem;
                line-height: 3.8rem;
            }
        }
        h2
        {
            @include h2-noe-display;

            font-size: 3.2rem;
            line-height: 4.6rem;

            margin-top: 3rem;

            text-transform: initial;

            color: $black-50;

            @media (max-width: 767px)
            {
                font-size: 2.8rem;
                line-height: 3.8rem;
            }
        }
    }
}
header
{
    .dark
    {
        .title
        {
            h1
            {
                color: $dark-purple;
            }
        }
    }
}
.content
{
    h3
    {
        margin-top: 3rem;
    }
    p
    {
        @include font-sofia-pro;

        font-size: 1.8rem;
    }
    &.dark-bg
    {
        color: $white;
        h1
        {
            color: $white;
        }
    }
}
.dark
{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p
    {
        color: $black-50;
    }
}
.light
{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p
    {
        color: darken($grey,5);
    }
}
.title
{
    p:not(.primary)
    {
        @include font-sofia-pro;

        font-size: 1.8rem;
    }
}
.primary
{
    font-family: 'sofia-pro', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;

    display: block;

    margin-bottom: 25px;
}
.tag
{
    @include font-sofia-pro-bold;

    font-size: 10px;

    letter-spacing: 8px;
    text-transform: uppercase;
}
small
{
    font-size: 1em;
    font-style: italic;
}
.nowrap
{
    white-space: nowrap;
}
a
{
    @include transition(all .5s ease);

    word-wrap: break-word;

    color: $purple;
    &:hover
    {
        color: $dark-purple;
    }
}
ul,
ol
{
    @include copy-sofia-pro;

    // font-size: 1.3rem;
}

.content
{
    .dark
    {
        p
        {
            color: $white;
        }
    }
    blockquote
    {
        p
        {
            @include quote-text;
        }
        .attribution
        {
            @include h4-sofia-pro;

            font-weight: 500;
        }
    }
}
blockquote
{
    @include padding(20px 30px);

    box-sizing: border-box;
    margin: 60px 0;

    border-left: 0 solid;

    &.dark
    {
        color: $white;
    }
    p
    {
        max-width: 95%;
        margin: 0 auto;
        padding: 10px 0;

        text-align: center;

        color: $black-50;
    }
    .attribution
    {
        @include h4-sofia-pro;

        font-weight: 500;
    }
}

// Icons
.icon
{
    &.social
    {
        font-family: 'entypo-social';
        font-size: 2em;
        line-height: 100%;

        padding: 0 5px;

        text-decoration: none;
    }
    &.ion
    {
        font-family: 'Pe-icon-7-stroke';
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        line-height: 1;

        display: inline-block;

        text-transform: none;

        speak: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    img
    {
        @media($small-screen)
        {
            max-width: 130px;
        }
    }
}
ul.icons
{
    margin: 0;

    list-style-type: none;

    text-align: center;
    li
    {
        display: inline-block;
        a
        {
            font-size: 38px;
        }
    }
}

textarea
{
    min-height: 120px;
    padding: 20px 15px;

    border: 3px solid #f1f1f1;
    background-color: #f1f1f1;
}
textarea:focus
{
    border: 3px solid;
    border-color: #51b0d3;
    outline: none;
    background: white;
}

// Wagtail responsive embeds
.responsive-object
{
    position: relative;
}
.responsive-object iframe,
.responsive-object object
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

/* temp fix 10-23-15 */
@media only screen and (min-width: 320px) and (max-width: 460px)
{
    .contact-cta .hbspt-form .hs_email
    {
        float: none !important;

        width: 100% !important;
    }

    .contact-cta .hbspt-form .hs_email input[type=email]
    {
        text-align: center !important;
    }

    .contact-cta .hbspt-form .hs_submit
    {
        float: none !important;

        width: 100% !important;
        margin-top: -10px !important;
    }

    .contact-cta .hbspt-form .hs_submit input[type=submit]
    {
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;

            -ms-border-radius: 4px;
             -o-border-radius: 4px;
    }
}

.journal .content img.richtext-image
{
    max-width: 85%;
}

img
{
    &.icon
    {
        width: calc(100% - 20%);
        padding-right: 10%;
        padding-left: 10%;
        @media($tablet-up)
        {
            padding-top: 10%;
        }
    }
}

/* VideoJS Fix */
.vjs-default-skin
{
    .vjs-big-play-button
    {
        &:before
        {
            top: 0;
        }
    }
}

button,
.button
{
    font-weight: 600;
}

.noselect
{
    -webkit-user-select: none; /* Safari */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    -webkit-touch-callout: none; /* iOS Safari */
     -khtml-user-select: none; /* Konqueror HTML */
}

.phone-grid
{
    $self: &;

    position: fixed;
    z-index: 9999;
    top: 0;

    visibility: visible;
    overflow: hidden;

    width: 100%;

    pointer-events: none;
    @media ($tablet-up)
    {
        visibility: hidden;
    }
    &__wrapper
    {
        @include columnizer(1 1 1 1, $set-neat-grid: $neat-grid-phone);
        @media($phone-and-tablet)
        {
            padding-right: 20px;
            padding-left: 20px;
        }
        div
        {
            height: 100vh;

            background-color: rgba($blue, .25);
        }
    }
}
//blue bars desktop grid
.tablet-grid
{
    $self: &;

    position: fixed;
    z-index: 9999;
    top: 0;

    visibility: hidden;
    overflow: hidden;

    width: 100%;

    pointer-events: none;
    @media ($tablet-only)
    {
        visibility: visible;
    }
    &__wrapper
    {
        @include columnizer(1 1 1 1 1 1 1 1, $set-neat-grid: $neat-grid-tablet);
        @media($tablet-only)
        {
            padding-right: 20px;
            padding-left: 20px;
        }
        div
        {
            height: 100vh;

            background-color: rgba($blue, .25);
        }
    }
}
//blue bars desktop grid
.desktop-grid
{
    $self: &;

    position: fixed;
    z-index: 9999;
    top: 0;

    visibility: hidden;
    overflow: hidden;

    width: 100%;

    pointer-events: none;
    @media ($desktop-only)
    {
        visibility: visible;
    }
    &__wrapper
    {
        @include columnizer(1 1 1 1 1 1 1 1 1 1 1 1, $set-neat-grid: $neat-grid);

        padding-right: 9%;
        padding-left: 9%;
        div
        {
            height: 100vh;

            background-color: rgba($blue, .25);
        }
    }
}
//red lines bleed
.desktop-grid-bleed
{
    $self: &;

    position: fixed;
    z-index: 9999;
    top: 0;

    visibility: hidden;
    overflow: hidden;

    width: 100%;

    pointer-events: none;
    @media ($desktop-only)
    {
        visibility: visible;
    }
    &__wrapper
    {
        @include columnizer(1 1 1 1 1 1 1 1 1 1 1 1, $set-neat-grid: $neat-grid-bleed);

        padding-right: 9%;
        padding-left: 9%;
        div
        {
            height: 100vh;

            border: 1px solid rgba($red, .25);
        }
    }
}
