.cards
{
    &.two-up
    {
        ul
        {
            @media($phone-only)
            {
                @include columnizer(4, $vertical-gutter: 40px, $set-neat-grid: $neat-grid-phone, $collapse: true, $set-max-width: none);
            }
            @media($tablet-up)
            {
                @include columnizer(4 4, $vertical-gutter: 50px, $set-neat-grid: $neat-grid-tablet, $collapse: true, $set-max-width: none);
            }
            @media($desktop-only)
            {
                @include columnizer(6 6, $vertical-gutter: 60px, $collapse: true, $set-max-width: none);
            }
        }
    }
    &.three-up
    {
        ul
        {
            @media($phone-only)
            {
                @include columnizer(4, $vertical-gutter: 40px, $set-neat-grid: $neat-grid-phone, $collapse: true, $set-max-width: none);
            }
            @media($tablet-up)
            {
                @include columnizer(4 4, $vertical-gutter: 50px, $set-neat-grid: $neat-grid-tablet, $collapse: true, $set-max-width: none);
            }
            @media($desktop-only)
            {
                @include columnizer(4 4 4, $vertical-gutter: 60px, $collapse: true, $set-max-width: none);
            }
        }
    }

    &.four-up
    {
        ul
        {
            @media($phone-only)
            {
                @include columnizer(4 , $vertical-gutter: 10px, $set-neat-grid: $neat-grid-phone, $collapse: true, $set-max-width: none);
            }
            @media($tablet-up)
            {
                @include columnizer(4 4, $vertical-gutter: 30px, $set-neat-grid: $neat-grid-tablet, $collapse: true, $set-max-width: none);
            }
            @media($desktop-only)
            {
                @include columnizer(3 3 3 3, $vertical-gutter: 70px, $collapse: true, $set-max-width: none);
            }
        }
    }

    &.two-up,
    &.three-up
    {
        ul
        {
            display: flex;

            flex-flow: row wrap;
            justify-content: flex-start;

            li
            {
                display: flex;

                flex-flow: column nowrap;

                .card__outer
                {
                    display: flex;

                    padding-bottom: 10px;

                    border-bottom: 1px solid $purple-grey;

                    flex-flow: column nowrap;
                    flex: 1 0 auto;
                }

                .card__content
                {
                    display: flex;
                    flex-direction: column;

                    justify-content: space-between;
                    flex: 1 0 auto;
                }

                .card__footer
                {
                    display: flex;

                    @media($phone-only)
                    {
                        margin-top: 10px;
                    }
                    @media($tablet-only)
                    {
                        margin-top: 20px;
                    }
                    @media($desktop-only)
                    {
                        margin-top: 40px;
                    }

                    span
                    {
                        display: inline-block;

                        width: 50%;

                        text-align: right;
                        &:first-child
                        {
                            float: left;

                            text-align: left;
                        }
                        a
                        {
                            @include font-sofia-pro-bold;
                            @include tracking(300);

                            font-size: 1.2rem;
                            line-height: 1.9rem;

                            position: relative;

                            text-decoration: none;
                            letter-spacing: .4rem;
                            text-transform: uppercase;

                            color: $purple-grey;

                            &:before
                            {
                                @include animate;

                                position: relative;
                                top: 1px;

                                display: inline-block;

                                width: 10px;
                                height: 10px;
                                margin-right: 8px;

                                content: '';

                                border-radius: 50%;
                                background-color: rgba($purple-grey, .3);
                            }

                            &:hover
                            {
                                &:before
                                {
                                    background-color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    > .title
    {
        @include font-sofia-pro-bold;

        font-size: 11px;

        margin-bottom: 85px;

        vertical-align: text-bottom;
        letter-spacing: 11px;
        text-transform: uppercase;

        color: $dark-purple;

        @media($phone-and-tablet)
        {
            margin-bottom: 60px;
        }
    }

    .intro
    {
        + ul
        {
            margin-top: 30px;
        }
        .intro__wrapper
        {
            @media($desktop-only)
            {
                @include columnizer(8, $collapse: true);

                p
                {
                    @include t-copy;
                }
            }
        }
    }

    ul
    {
        margin: 0;
        padding: 0;

        list-style: none;
        + *
        {
            margin-top: 100px;
        }
        li
        {
            a
            {
                text-decoration: none;

                &:hover
                {
                    .image-container
                    {
                        &:before,
                        &:after
                        {
                            opacity: 1;
                        }
                    }
                }
                .section-label
                {
                    @include t-card-label;

                    margin-bottom: 20px;

                    color: $purple-grey;
                }
                .title
                {
                    font-size: 22px;

                    margin-bottom: 10px;
                }
                .description
                {
                    font-size: 14px;
                    line-height: 1.6;

                    margin-top: 15px;
                    margin-bottom: 20px;

                    color: $purple-grey;
                }
                .image-container
                {
                    line-height: 0;

                    position: relative;

                    width: 100%;
                    height: auto;
                    margin-top: 20px;

                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    &:before
                    {
                        position: absolute;
                        top: 0;
                        left: 0;

                        width: 100%;
                        height: 100%;

                        content: '';
                        transition: all 250ms ease;

                        opacity: 0;
                        background-color: rgba($white, .3);
                    }

                    &:after
                    {
                        @include fontello($icon-chevron-right);

                        font-size: 10px;
                        line-height: 32px;

                        position: absolute;
                        top: 50%;
                        left: 50%;

                        width: 31px;
                        height: 32px;
                        padding-left: 1px;

                        transition: all 250ms ease;
                        transform: translateY(-50%) translateX(-50%);
                        text-align: center;

                        opacity: 0;
                        color: $white;
                        border-radius: 16px;
                        background-color: $purple;
                    }

                    img
                    {
                        width: 100%;
                        height: 100%;

                        @media($desktop-only)
                        {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.triangle
{
    .block__wrapper
    {
        > .title
        {
            margin-bottom: 140px;
            //blocks builder override

            color: $yellow !important;
        }
        ul
        {
            @media($phone-only)
            {
                @include columnizer(4, $vertical-gutter: 36px, $set-neat-grid: $neat-grid-phone, $collapse: true, $set-max-width: none);
            }
            li
            {
                &:nth-child(odd)
                {
                    transform: translateY(0);
                    a
                    {
                        div.background
                        {
                            transform: rotate(0deg);

                            img
                            {
                                transform: rotate(0deg);
                            }
                        }
                        .arrow
                        {
                            top: 5%;
                            left: 5%;
                        }
                    }
                }
                &:nth-child(even)
                {
                    @media($tablet-up)
                    {
                        transform: translateY(-20%);
                    }
                    a
                    {
                        div.background
                        {
                            //triangle top left 1 of 2
                            transform: rotate(90deg);

                            img
                            {
                                //triangle top left 2 of 2
                                transform: rotate(-90deg) translateY(2px);
                            }
                        }
                        .arrow
                        {
                            top: 5%;
                            left: 5%;

                            transform: rotate(-90deg);
                        }
                    }
                }
                a
                {
                    position: relative;

                    display: block;
                    &:hover
                    {
                        div.background
                        {
                            &:before
                            {
                                background-color: rgba($black, 0);
                            }
                        }
                        div.arrow
                        {
                            &:before
                            {
                                @include arrow-before-hover;
                            }
                        }
                    }
                    div.background
                    {
                        line-height: 0;

                        position: relative;

                        overflow: hidden;
                        //transparent cover
                        &:before
                        {
                            @include animate;

                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;

                            width: 100%;
                            height: 100%;

                            content: '';

                            background-color: rgba($black, .7);
                        }
                        //triangle bottom left
                        &:after
                        {
                            position: absolute;
                            top: 0;
                            left: 0;

                            display: block;

                            width: 0;
                            height: 0;

                            content: '';

                            border-right: 1000px solid transparent;
                            border-bottom: 1000px solid $black;
                        }
                    }
                    .arrow
                    {
                        position: absolute;
                        z-index: 1;

                        width: 13%;
                        height: 13%;

                        &:before
                        {
                            @include arrow-before;
                        }
                    }
                    div.text
                    {
                        position: absolute;
                        top: 20%;

                        color: $white;

                        @media($phone-only)
                        {
                            transform: scale(.75);
                            transform-origin: left top;
                        }
                        @media($desktop-only)
                        {
                            font-size: calc(1px + 1.2vw);
                            line-height: calc(1px + 1.5vw);

                            max-width: 85%;
                        }
                    }
                    div.project-title
                    {
                        @media($phone-only)
                        {
                            font-size: 2.2rem;
                            line-height: 3.2rem;
                        }
                    }
                }
            }
        }
    }
    a.new-button
    {
        @include new-button($purple-grey);
    }
}
