$max-width: 3840px;
$gutter-size-phone: 10px;
$gutter-size-tablet: 16px;
$gutter-size: 30px;

$neat-grid-phone: (
    columns: 4,
    gutter: $gutter-size-phone,
);

$neat-grid-tablet: (
    columns: 8,
    gutter: $gutter-size-tablet,
);

$neat-grid: (
  columns: 12,
  gutter: $gutter-size,
);

$neat-grid-bleed: (
  columns: 12,
  gutter: 1px - 1,
);// Odd hack to make 0px since csscomb removes px from 0 values. However the px value is required for calc to work

// Desktop First Media Queries
$phone-only: 'max-width: 767px';
$tablet-only: 'min-width: 768px) and (max-width: 1024px';
$phone-and-tablet: 'max-width: 1024px';
$tablet-up: 'min-width: 768px';
$desktop-only: 'min-width: 1025px';

// Legacy PB Craft 2.0
$neat-grid-16: (
    columns: 16,
    gutter: $gutter-size,
);

// Column Counts for Legacy PB Craft 2.0
$grid-columns-small: 6;
$grid-columns-medium: 8;
$grid-columns: 16;

// Neat Breakpoints for Legacy PB Craft 2.0
$small-screen-width: 600px;
$medium-screen-width: 860px;

$small-screen: new-breakpoint(max-width $small-screen-width $grid-columns-small);
$medium-screen: new-breakpoint(min-width $small-screen-width max-width $medium-screen-width $grid-columns-medium);
$large-screen: new-breakpoint(min-width $medium-screen-width $grid-columns);
