.intro
{
    h1
    {
        @include t-h1;

        font-size: 5.7rem;
        line-height: 2.0rem;

        color: $dark-purple;
        small
        {
            font-size: 3.5rem;
        }
    }

    h2
    {
        @include t-h2;

        color: $dark-purple;
    }

    h3
    {
        @include t-h3;

        color: $purple;
    }

    h4
    {
        @include t-h4;
    }
    &.bgimage
    {
        @include background-cover;

        position: relative;
        z-index: 0;

        background-color: $blue;
        background-attachment: scroll;
        background-position: top center;
        &.not-yellow
        {
            .title
            {
                h1
                {
                    color: $white;
                }
            }
            &:after
            {
                display: none;
            }
        }
        &:after
        {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            content: '';

            background-color: $yellow-92;
        }

        .wrapper
        {
            @include background-cover;

            position: relative;
            z-index: 2;

            display: table;
            float: none;

            box-sizing: border-box;
            width: 100%;
            max-width: 1140px;
            min-height: 200px;
            margin: 0 auto;
            padding: 125px 30px;

            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: top center;
            background-size: 100% auto;

            .title
            {
                z-index: 100;

                display: block;
                display: table-cell;
                zoom: 1;

                width: 100%;

                text-align: center;
                vertical-align: middle;
                &.dark
                {
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6
                    {
                        color: $dark-purple;
                    }
                }
                &.light
                {
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6
                    {
                        color: $dark-grey;
                    }
                }
                small
                {
                    font-style: normal;
                    line-height: 1.4;

                    display: block;

                    color: $dark-purple;
                }
            }
        }
    }
}
