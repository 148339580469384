.case-study
{
    #blocks-builder
    {
        .block
        {
            @media($phone-only)
            {
                @include columnizer(4, $set-neat-grid: $neat-grid-phone);

                padding-right: 20px;
                padding-left: 20px;

                &:first-child
                {
                    padding-top: 60px;
                }
            }
            @media($tablet-only)
            {
                @include columnizer(8, $set-neat-grid: $neat-grid-tablet);

                padding-right: 20px;
                padding-left: 20px;

                &:first-child
                {
                    padding-top: 90px;
                }
            }
            @media($desktop-only)
            {
                @include columnizer(12);

                padding-right: 9%;
                padding-left: 9%;
            }

            &.copy,
            &.image-caption,
            &.video
            {
                @media($desktop-only)
                {
                    @include columnizer(8, 2);
                }
            }

            &.featured-links
            {
                &.three-up
                {
                    ul
                    {
                        @media($tablet-only)
                        {
                            @include columnizer(4 4, $set-neat-grid: $neat-grid-tablet, $collapse: true);

                            display: flex;

                            flex-flow: row wrap;

                            > li:first-child
                            {
                                margin-top: 30px;
                            }
                        }
                        @media($desktop-only)
                        {
                            @include columnizer(4 4 4, $collapse: true);

                            display: flex;

                            flex-flow: row wrap;

                            > li:first-child
                            {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }

            .quick-bullet-accordion
            {
                @media($desktop-only)
                {
                    @include columnizer(8, 2);

                    padding-right: 0;
                    padding-left: 0;
                }
                .title
                {
                    margin-left: -20px;
                    + .accordion
                    {
                        margin-top: 45px;
                    }
                }
                .accordion
                {
                    .item-title
                    {
                        font-size: 2.2rem;
                    }
                    .item-description
                    {
                        font-size: 1.6rem;
                        line-height: 2.8rem;
                    }

                    @media ($tablet-only)
                    {
                        margin-bottom: 60px;
                    }

                    @media ($tablet-only)
                    {
                        margin-bottom: 90px;
                    }

                    @media ($desktop-only)
                    {
                        margin-bottom: 120px;
                    }
                }
            }
        }
    }
}

.block
{
    &--case-study-link-cards
    {
        .container
        {
            @media($phone-and-tablet)
            {
                //vertical spacing between half & half div containers for phone and tablet only
                > div + div
                {
                    margin-top: 90px;
                }
            }
            @media($desktop-only)
            {
                @include columnizer(5 6, 0 1, $collapse: true);
            }
            .featured-links
            {
                @media($desktop-only)
                {
                    .title
                    {
                        + ul
                        {
                            margin-top: 65px;
                        }
                    }
                }
            }
        }
    }
}
