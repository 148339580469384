.contact
{
    .intro
    {
        h1
        {
            + p
            {
                margin-top: 2.1rem;
            }
        }
        p
        {
            @include font-sofia-pro-bold;

            font-size: 2rem;
            font-style: normal;
            line-height: 2.6rem;

            color: $black-50;
        }
        .wrapper
        {
            max-width: 800px;
            min-height: 200px;
        }
    }
    form
    {
        .new-button
        {
            input
            {
                padding-bottom: 10px;
            }
        }
        input[type=date]:focus,
        input[type=datetime-local]:focus,
        input[type=datetime]:focus,
        input[type=email]:focus,
        input[type=month]:focus,
        input[type=number]:focus,
        input[type=password]:focus,
        input[type=search]:focus,
        input[type=tel]:focus,
        input[type=text]:focus,
        input[type=time]:focus,
        input[type=url]:focus,
        input[type=week]:focus,
        textarea:focus
        {
            border-color: #9d00ff;
            outline: 0;
            background: #ffffff;
        }

        input[type=date],
        input[type=datetime-local],
        input[type=datetime],
        input[type=email],
        input[type=month],
        input[type=number],
        input[type=password],
        input[type=search],
        input[type=tel],
        input[type=text],
        input[type=time],
        input[type=url],
        input[type=week],
        textarea,
        {
            font-family: sofia-pro;
            font-size: 1.8rem;
            font-weight: 400;

            display: block;

            box-sizing: border-box;
            width: 100%;
            height: 5.6rem;
            margin: 0 0 1rem;
            padding: 1.28rem;

            transition: all .15s linear;

            color: rgba(37, 31, 41, .5);
            border: 0;
            border-bottom: 3px solid #e4e4e4;
            background-color: #f1f1f1;

            -webkit-appearance: none;
        }

        .dk-select
        {
            max-width: 400px;
            margin: 0 auto;
            margin-bottom: 20px;
            + select
            {
                display: none !important;
            }
        }

        .dk-selected,
        .dk-option
        {
            font-size: 2rem;
        }

        .dk-selected
        {
            overflow: hidden;

            border-bottom: 3px solid rgba(0, 0, 0, 0.25);

            padding-right: 36px;

            user-select: none;
            text-overflow: ellipsis;
        }

        .freeform-row
        {
            @media($phone-only)
            {
                display: block;

                width: 100%;

                flex: unset;
            }
            .freeform-column
            {
                .g-recaptcha
                {
                    width: 308px;
                    margin: 0 auto;
                }
                .grecaptcha-badge
                {
                    opacity: 0;
                }
                @media($phone-only)
                {
                    display: block;

                    width: 100%;

                    flex: unset;
                }
                .freeform-label
                {
                    display: none !important;
                }

                ul.errors.freeform-errors
                {
                    margin-top: -10px !important;
                    li
                    {
                        font-size: 1.4rem;
                        font-weight: 400;
                        font-style: italic;

                        display: block;

                        margin-top: -1px;
                        margin-bottom: 1rem;
                        padding: .375rem .5625rem .5625rem;

                        color: #ffffff !important;
                        background: #f04124;
                    }
                }
            }
        }
    }
    &.location
    {
        padding: 0;

        .location__wrapper
        {
            @media($desktop-only)
            {
                @include columnizer(4 8, $set-neat-grid: $neat-grid-bleed, $collapse: true, $set-max-width: none);
            }

            .contact-info
            {
                min-height: 500px;
                padding: 50px;

                background-color: $yellow;

                @media($phone-only)
                {
                    min-height: 400px;
                }
                .detail
                {
                    margin-bottom: 40px;
                    p
                    {
                        font-size: 1.8rem;
                        line-height: 2.8rem;

                        margin-bottom: 0;

                        color: $black-50;
                    }
                    a
                    {
                        @include animate;

                        color: $black-50;
                        &:hover
                        {
                            color: $dark-purple;
                        }
                    }
                    h3
                    {
                        @include label-sofia-pro;

                        margin: 0 0 5px;

                        color: $black-50;
                    }
                }
            }

            #map
            {
                z-index: 10;

                height: 500px;
                padding: 0;
                .leaflet-popup-pane
                {
                    display: none;
                }
                .leaflet-bottom.leaflet-right
                {
                    display: none;
                }
            }
        }
    }

    .newsletter
    {
        margin-top: 5px;
        padding: 30px 0;

        background-color: $blue;
        h2
        {
            float: left;

            margin-right: 30px;

            color: $white;
        }
        .newsletter-signup
        {
            float: left;

            width: 100%;
            max-width: 410px;
        }
        #mc-field-group
        {
            height: 50px;
        }
        #mc_embed_signup_scroll
        {
            @include clearfix;
        }
        form
        {
            text-align: center;
        }
        input.email
        {
            float: left;

            width: 350px;
            margin: 0 auto;

            border-bottom: 0 solid;
        }
        .button
        {
            width: 60px;
            height: 50px;
            margin: 0;
            padding: 0;

            border-color: rgba($blue,.8);
            background-color: rbga($blue,.8);
        }
    }
}
