$header-font-family: 'sofia-pro', verdana, sans-serif;

@mixin font-sofia-pro
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-weight: 400;
}

@mixin font-sofia-pro-bold
{
    font-family: 'sofia-pro', verdana, sans-serif;
    font-weight: 700;
}

@mixin font-noe-display-black
{
    font-family: 'NoeDisplay-Black', Georgia, serif;
    font-weight: 700;
}

// Typography mixins used in component classes
@mixin t-h1
{
    @include font-noe-display-black;

    font-size: 7.5rem;
    line-height: 8.6rem;

    @media ($phone-only)
    {
        font-size: 4.5rem;
        line-height: 5.5rem;
    }
}

@mixin t-h2
{
    @include font-noe-display-black;

    font-size: 3.8rem;
    line-height: 4.8rem;

    @media ($phone-only)
    {
        font-size: 2.8rem;
        line-height: 3.8rem;
    }
}

@mixin t-h3
{
    @include font-sofia-pro;

    font-size: 2.2rem;
    line-height: 3.2rem;
}

@mixin t-h4
{
    @include font-sofia-pro-bold;

    font-size: 1.8rem;
    line-height: 2.6rem;
}

@mixin t-intro
{
    font-family: 'sofia-pro', verdana, sans-serif !important;
    font-size: 2.4rem !important;
    font-weight: 400 !important;
    line-height: 3.3rem !important;
}

@mixin t-long-form-copy
{
    @include font-sofia-pro;

    font-size: 18rem;
    line-height: 3rem;
}


@mixin t-copy
{
    @include font-sofia-pro;

    font-size: 1.6rem;
    line-height: 2.8rem;

    color: $purple-grey;

    .fonts-loaded &
    {
        font-family: $sofia-pro;
    }
}

@mixin t-figcaption
{
    @include font-sofia-pro;

    font-size: 1.4rem;
    line-height: 2.2rem;

    color: $purple-grey;

    .fonts-loaded &
    {
        font-family: $sofia-pro;
    }
}

@mixin t-meta
{
    @include font-sofia-pro;

    font-size: 1.4rem;
    line-height: 2.4rem;

    color: $purple-grey;
    .fonts-loaded &
    {
        font-family: $sofia-pro;
    }
}

// big button with underline
@mixin button-sofia-pro
{
    @include font-sofia-pro-bold;
    @include tracking(400);

    font-size: 1.4rem;
    line-height: 2.6rem;

    text-transform: uppercase;
}

@mixin t-nav-button
{
    @include font-sofia-pro;

    font-size: 1.5rem;
    line-height: 3.1rem;

    text-decoration: none;
}

@mixin t-label
{
    @include font-sofia-pro-bold;
    @include tracking(800);

    font-size: 1.1rem;
    line-height: 1.9rem;

    text-transform: uppercase;
}

@mixin t-card-label
{
    @include font-sofia-pro-bold;
    @include tracking(400);

    font-size: 1.1rem;
    line-height: 1.9rem;

    text-transform: uppercase;
}

@mixin t-nav-products
{
    @include font-sofia-pro;

    font-size: 1.6rem;
    line-height: 3.5rem;
}

@mixin t-slider-label
{
    @include font-sofia-pro-bold;
    @include tracking(400);

    font-size: 1.2rem;
    line-height: 1.9rem;

    text-transform: uppercase;
}

@mixin t-anchor-nav
{
    @include font-sofia-pro;

    font-size: 1.8rem;
    line-height: 3.0rem;
}
