
main.projects
{
    position: relative;
    z-index: 100;

    overflow: hidden;

    background-color: $white;

    section
    {
        .content
        {
            display: flow-root;

            ol,
            ul
            {
                color: $purple-grey;
            }

            blockquote
            {
                padding: 0 !important;
            }

            @media($phone-only)
            {
                padding: 60px 0;
            }
        }
        &.flip
        {
            .row
            {
                @media($tablet-up)
                {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
        .row
        {
            display: table;

            max-width: 1000px;
            margin: 0 auto;
            padding-right: 20px;
            padding-left: 20px;

            &.full
            {
                max-width: 100%;
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .two-columns
    {
        @media($desktop-only)
        {
            @include columnizer(6 6)
        }
    }

    .web
    {
        .large-4
        {
            @media($tablet-up)
            {
                float: left;

                width: 33.3333333333%;
            }
        }
        .large-8
        {
            position: relative;

            @media($tablet-up)
            {
                float: right;

                width: 66.6666666667%;
            }
        }
        .large-5
        {
            @media($tablet-up)
            {
                float: left;

                width: 41.6666666667%;
            }
        }
        .large-7
        {
            @media($tablet-up)
            {
                float: left;

                width: 58.3333333333%;
            }
        }
        [class*=column] + [class*=column]:last-child
        {
            float: right;
        }
        .columns
        {
            p,
            h1
            {
                padding: 30px;
                + p
                {
                    padding-top: 0;
                }
            }
        }
    }

    .sidebar-projects-left
    {
        @media($tablet-up)
        {
            float: left;

            width: 25%;
        }
    }
    .sidebar-projects-right
    {
        @media($tablet-up)
        {
            float: right;

            width: 75%;
        }
    }
    dl.meta dd
    {
        display: inline-block;

        margin-right: 3px;
        margin-left: 0;

        a
        {
            text-decoration: none;
        }
    }
    .large-centered
    {
        max-width: 833px;
        margin: 0 auto;

        blockquote
        {
            p
            {
                text-align: center;
            }

            &:before
            {
                display: none;
            }
        }
    }
    .dark-bg p,
    .dark-bg h1
    {
        color: #ffffff;
    }

    .slider
    {
        position: relative;

        margin-top: 40px;

        .pen-slider
        {
            padding: 0;
        }

        img
        {
            max-width: 100%;
        }

        .slide
        {
            img
            {
                width: 100%;
            }
        }

        .tns-nav
        {
            display: none;
        }

        [data-controls='prev'],
        [data-controls='next']
        {
            @include animate;

            font-size: 0;

            position: absolute;
            z-index: 1;

            display: table-cell;

            height: 100%;
            padding: 0 35px 0 25px;

            vertical-align: middle;

            color: #ffff00;
            border: 0;
            outline: 0;
            background-color: transparent;

            &:before
            {
                @include fontello($icon-chevron-right);

                font-size: 24px;
                line-height: 1;

                position: absolute;
                top: 50%;

                transform: translateY(-50%);
            }

            &:hover
            {
                background-color: hsla(0,0%,100%,.4);
            }
            &[disabled]
            {
                display: none;
            }
        }
        [data-controls='prev']
        {
            left: 0;
            &:before
            {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
        [data-controls='next']
        {
            right: 0;
        }
    }
}
.single-project
{
    .intro
    {
        h1
        {
            line-height: 6.0rem;
        }
    }

    #slb-invitation
    {
        .medium-6
        {
            float: left;

            width: 50%;
        }
    }
    #slb-planning-coordination-and-art-direction
    {
        padding-bottom: 104px;
    }

    #unitedway
    {
        color: $purple-black;
        h2
        {
            color: $purple-black;
        }
    }
    section
    {
        + aside
        {
            padding-top: 40px;
        }
    }
    .proj-logos
    {
        @media($phone-only)
        {
            @include columnizer(2 2, $set-neat-grid: $neat-grid-phone);
        }
        @media($tablet-only)
        {
            @include columnizer(2.667 2.667 2.667, $set-neat-grid: $neat-grid-tablet);
        }
        @media($desktop-only)
        {
            @include columnizer(4 4 4);
        }

        .logo
        {
            .project-title
            {
                font-size: 2.4rem;
                line-height: 3.0rem;

                margin-bottom: 25px;

                text-align: center;

                color: lighten($grey, 12);
            }
        }
    }

    &.identities
    {
        .logo
        {
            @include transition(all .5s ease);

            margin: 30px 0;
            padding: 0 15px;
            img
            {
                margin-top: 0 !important;
            }
        }
        .project-link
        {
            color: transparent;
            .logo
            {
                border: 1px solid transparent;
            }
            &:hover
            {
                .logo
                {
                    border: 1px solid $light-grey;
                    background-color: lighten($light-grey, 4);
                }
                .project-title
                {
                    color: $grey;
                }
            }
        }
        .project-title
        {
            font-size: 1.8rem;

            margin-bottom: 25px;

            text-align: center;

            color: lighten($grey, 12);
        }
    }
    .top
    {
        padding: 0;
    }
    .content
    {
        .slider
        {
            margin-bottom: -110px;
            padding: 40px 0 0;
        }
        &.bg
        {
            @include background-cover;
        }
        &.image
        {
            padding: 0;
            img
            {
                width: 100%;
            }
        }
        &.grid
        {
            img
            {
                margin-top: 30px;
                &.half
                {
                    float: left;

                    width: 50%;
                }
                &.third
                {
                    float: left;

                    width: 33.33%;
                }
            }
        }
        &.web
        {
            .stacked
            {
                margin-bottom: 40px;
                .monitor
                {
                    width: 1000px;
                    max-width: none;
                }
                .phone
                {
                    position: absolute;
                    bottom: -50px;
                    left: 40px;

                    width: 210px;
                }
            }
        }
        &.video
        {
            @include background-cover;

            padding: 100px 0;

            background-repeat: no-repeat;
            background-position: center center;
            .vjs-poster
            {
                background-color: transparent;
                &:before
                {
                    display: none;
                }
            }
            .video-js
            {
                width: 100%;
                margin: 0 auto;

                background-color: transparent;
                &#fkp-video-1
                {
                    padding-bottom: 47.28%;
                }
                &#fkp-video-2
                {
                    padding-bottom: 47.28%;
                }
                &#slb-video
                {
                    padding-bottom: 40.4%;
                }
                &#uw-video
                {
                    padding-bottom: 46.7%;
                }
            }
            .video-player
            {
                width: 100%;
                max-width: 960px;
                height: auto;
                margin: 30px auto;
            }
        }
    }
    aside.related-projects
    {
        padding: 110px 0 0;

        background-color: $white;
        h2
        {
            @include h2-noe-display;

            font-size: 3.8rem;

            color: $black-50;
        }
        .thumbs
        {
            padding-top: 30px;

            a
            {
                .item
                {
                    position: relative;

                    float: left;

                    height: 350px;
                    padding: 0;
                    @media($phone-only)
                    {
                        width: 100%;
                    }
                    @media($tablet-up)
                    {
                        width: 33.3333333333%;
                    }
                    .container
                    {
                        display: flex;

                        height: 100%;

                        text-align: center;

                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;

                        align-items: center;
                        justify-content: center;
                    }
                    .title
                    {
                        @include transform(translateY(-50%));

                        position: absolute;
                        z-index: 100;
                        top: 50%;

                        display: block;
                        zoom: 1;

                        width: 100%;

                        text-align: center;
                        @media all and (-ms-high-contrast:none)
                        {
                            left: 0; /* IE10 */
                            *::-ms-backdrop
                            {
                                left: 0;
                            } /* IE11 */
                        }
                        span
                        {
                            display: inline-block;

                            height: 100%;

                            vertical-align: middle;
                        }
                        .big-title
                        {
                            @include font-sofia-pro;

                            font-size: 3.2rem;
                        }
                    }
                    .logo
                    {
                        @include transition(all .5s ease);

                        max-width: 200px;
                        margin: 0 auto;

                        vertical-align: middle;

                        opacity: 1;
                        &.vertist
                        {
                            max-height: 50px;
                        }
                        &.uw
                        {
                            max-height: 70px;
                        }
                    }
                    .black-overlay
                    {
                        @include transition(all .5s ease);

                        position: absolute;
                        top: 0;

                        display: block;

                        width: 100%;
                        height: 100%;

                        opacity: .3;
                        background-color: rgba(0,0,0,.5);
                        @media all and (-ms-high-contrast:none)
                        {
                            left: 0; /* IE10 */
                            *::-ms-backdrop
                            {
                                left: 0;
                            } /* IE11 */
                        }
                    }
                    .button
                    {
                        //@include transform(scale(.5) translate(-50%));
                        @include transition(opacity .3s ease);
                        @include button-sofia-pro;
                        //position: absolute;

                        z-index: 100;
                        //top: 50%;

                        display: inline-block;

                        margin: 0;
                        padding: 10px 20px;

                        text-align: center;

                        opacity: 0;
                        border: 0;
                    }
                    &.text
                    {
                        h4
                        {
                            font-size: 27px;
                            font-weight: 600;

                            margin: 0 auto;
                            padding: 25px 0;

                            vertical-align: middle;

                            opacity: 1;
                            color: $white;
                        }
                    }
                    &:hover
                    {
                        .logo,
                        &.view-all h4
                        {
                            @include transition(opacity .3s ease);

                            opacity: 0;
                        }
                        .black-overlay
                        {
                            opacity: 1;
                        }
                        .button
                        {
                            //@include transform(scale(1) translate(-50%));
                            opacity: 1;
                            color: $dark-purple;
                            background-color: $yellow;
                        }
                    }
                }
            }
        }
    }
}
