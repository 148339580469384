.cta
{
    @include transition(background-color .5s ease);
    @include padding(230px null);

    clear: both;
    overflow: hidden;

    background-color: $purple-black;
    h1
    {
        @include transition(color .5s ease);

        font-size: 1.75rem;

        padding: 40px 0 0;
    }
    h2
    {
        + h1
        {
            padding-top: 0;
        }
    }
    .row
    {
        width: 100%;
        max-width: 62.5rem;
        margin: 0 auto;
    }
    .cta-title
    {
        @include h2-noe-display;

        text-transform: initial;

        color: $yellow;
        + .cta-button
        {
            margin-top: 30px;
        }
    }
    .cta-button
    {
        @include button-sofia-pro;

        color: $white;
    }
}


.cta-next
{
    position: relative;

    a.cta-wrapper
    {
        display: block;
        .next
        {
            //next SVG container styles
            //check IE11
        }
        .text
        {
            @include t-h4;

            position: absolute;
            top: 50%;
            left: 50%;

            width: 90%;

            transform: translateX(-50%) translateY(-50%);
            text-align: center;

            small
            {
                @include font-sofia-pro;

                font-size: 16px;
                font-style: normal;
                line-height: 22px;

                display: block;

                max-width: 70%;
                margin: 0 auto;

                color: $purple-grey;
            }
        }
    }
}

.journal-page
{
    ~ .contact-cta
    {
        //@include outer-container;
        background-color: $white;
        @media ($phone-only)
        {
            padding: 20px;
        }
        input
        {
            font-size: 1.6rem;

            width: 100%;
            height: 3.55rem;
            margin-bottom: 20px;
            padding: .8rem;

            transition: all .15s linear;

            color: rgba(37,31,41,.5);
            border: 0;
            border-bottom: 3px solid #e4e4e4;
            outline: 0;
            background-color: #f1f1f1;

            -webkit-appearance: none;
        }
        .contact-cta-wrapper
        {
            @include padding(150px 0px);

            box-sizing: border-box;
            max-width: 1176px;
            margin: 0 auto;
            margin-top: 80px;

            color: $black-50;
            border: 2px solid $dark-purple;

            @media ($phone-only)
            {
                padding: 20px;
            }

            p
            {
                margin-bottom: 3rem;
            }
            h2
            {
                @include h2-noe-display;

                color: $black-50;
            }
            small
            {
                font-family: 'sofia-pro';
                font-size: 1.5rem;

                display: block;
                clear: both;
            }
            .large-8
            {
                max-width: 667px;
                margin: 0 auto;

                text-align: center;
            }
        }
        .hbspt-form
        {
            padding-bottom: 20px;

            label
            {
                display: none;
            }
            .hs_email
            {
                float: left;

                width: 66%;

                .error
                {
                    display: none;
                }

                @media($tablet-only)
                {
                    width: 60%;
                }
                input
                {
                    @include font-sofia-pro;
                    @media ($phone-only)
                    {
                        padding: .8rem 0;
                    }
                    [type='email']
                    {
                        @include font-sofia-pro;

                        border-bottom: 4px solid $meta-txt;
                    }
                    [type=submit]
                    {
                        color: $purple;
                        border-color: $yellow;
                        background-color: $yellow;
                        &:hover
                        {
                            color: $purple;
                            border-color: $purple;
                            background-color: $white;
                        }
                    }
                    @media($phone-only)
                    {
                        text-align: center;
                    }
                }
                @media($phone-only)
                {
                    width: 100%;
                }
            }
            .hs_submit
            {
                float: left;

                width: 33%;
                height: 57px !important;

                @media($tablet-only)
                {
                    width: 40%;
                }
                input
                {
                    @include padding(14px 2.0rem null);

                    height: 54px !important;
                    margin: 0;
                    padding-top: 10px;

                    @media($phone-only)
                    {
                        margin-top: 0;
                    }
                }
                [type=submit]
                {
                    @include button-sofia-pro;

                    height: 54px;
                    padding-top: 10px;

                    color: $purple;
                    border: 2px $yellow solid;
                    background-color: $yellow;
                    &:hover
                    {
                        color: $purple;
                        border-color: $purple;
                        background-color: $white;
                    }
                }
                @media($phone-only)
                {
                    width: 100%;
                }
            }
        }
    }
}

.cta // CTA New
{
    //overrides
    padding-top: 0;
    padding-bottom: 0;

    color: $white;
    background-color: $black;

    .cta-wrapper
    {
        max-width: 1000px;
        margin: 0 auto;
        padding: 130px 80px;

        text-align: center;

        @media ($phone-only)
        {
            padding: 90px 20px;
        }

        .section-title
        {
            @include t-label;

            color: $yellow;

            + h1
            {
                @media (phone-and-tablet)
                {
                    margin-top: 20px;
                }
                @media($desktop-only)
                {
                    margin-top: 60px;
                }
            }
        }

        > h1
        {
            @include t-h1;

            margin-bottom: 20px;

            letter-spacing: 0;

            color: $white !important;
        }
        p
        {
            color: $white !important;
        }

        > span
        {
            @include font-sofia-pro;

            font-size: 18px;

            display: inline-block;

            margin-bottom: 75px;
            padding-right: 15%;
            padding-left: 15%;

            @media($phone-only)
            {
                margin-bottom: 55px;
                padding-right: 0;
                padding-left: 0;
            }
        }

        > a
        {
            @include font-sofia-pro;

            font-size: 24px;

            display: table;

            margin: 0 auto;

            text-decoration: none;

            &.new-button
            {
                /* Firefox */
                @-moz-document url-prefix()
                {
                    position: relative;
                }
                span
                {
                    color: $yellow;
                }
                .underline
                {
                    margin-top: -5px;
                    /* Firefox */
                    @-moz-document url-prefix()
                    {
                        position: absolute;

                        width: 105%;
                        margin-top: 0;
                    }
                    .underline_wrapper
                    {
                        .path-underline
                        {
                            stroke: $yellow;
                            stroke-width: 2px;
                        }
                    }
                }
            }

            &:not(.new-button)
            {
                position: relative;
                z-index: 0;

                color: $purple;
                border-bottom: 1px solid yellow;

                &:after
                {
                    @include animate;

                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                    left: 0;

                    width: 100%;
                    height: 1px;

                    content: '';

                    background: $yellow;
                }

                &:hover
                {
                    &:after
                    {
                        height: 100%;
                    }
                }
            }

            + a
            {
                margin-top: 45px;
            }
        }
    }
}
