.work
{
    .banner
    {
        min-height: 100vh;
    }
    > .container
    {
        position: relative;

        .scroll
        {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 76px;
            height: 120px;

            @media (max-width: 767px)
            {
                display: none;
            }

            .scroll-wrapper
            {
                .scroll__text-wrapper
                {
                    position: relative;

                    height: 70px;

                    > div
                    {
                        @include position(absolute, null null 0 50%);
                        @include font-sofia-pro-bold;

                        font-size: .735vw;

                        margin-bottom: .85vw;

                        transform: rotate(-90deg);
                        transform-origin: left center;
                        letter-spacing: .4vw;

                        color: $grey;

                        @media (max-width: 1024px)
                        {
                            font-size: 10px;

                            letter-spacing: 4px;
                        }
                    }
                }
                .scroll__line-wrapper
                {
                    div
                    {
                        @include margin(0 auto);

                        width: 1px;
                        height: 50px;

                        animation: scroll-down-line 3s infinite $custom-animation-easing;

                        background-color: $grey;
                    }
                }
            }
        }
    }
    #blocks-builder
    {
        .blocks-builder__container
        {
            padding-top: 0;

            .block
            {
                @media($phone-only)
                {
                    @include columnizer(4, $set-neat-grid: $neat-grid-phone);

                    padding-right: 20px;
                    padding-left: 20px;
                }
                @media($tablet-only)
                {
                    @include columnizer(8, $set-neat-grid: $neat-grid-tablet);

                    padding-right: 20px;
                    padding-left: 20px;
                }
                @media($desktop-only)
                {
                    @include columnizer(12);

                    padding-right: 9%;
                    padding-left: 9%;
                }
            }

            .logos
            {
                ul
                {
                    li
                    {
                        position: relative;

                        display: flex;

                        box-sizing: border-box;

                        align-items: center;
                        justify-content: center;

                        &.clients--link
                        {
                            cursor: pointer;
                            &:before
                            {
                                @include animate;

                                position: absolute;
                                right: 0;
                                bottom: 0;
                                left: 0;

                                height: 1px;

                                content: '';

                                background-color: $purple;
                            }

                            &:hover
                            {
                                &:before
                                {
                                    height: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
