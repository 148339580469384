.new-footer
{
    width: 100vw;
    //fixes horizontal scrollbar appear bug
    max-width: 100%;

    background-color: $white;

    @media($phone-and-tablet)
    {
        padding-bottom: 20px;
    }

    &.homepage
    {
        @media ($tablet-up)
        {
            margin-top: 100vh;
        }
    }

    &.scroll-overlay
    {
        &:before
        {
            position: fixed;
            top: 0;

            width: 100vw;
            height: 100vh;

            content: '';
        }
    }
    .footer-container
    {
        position: relative;
        z-index: 1;

        margin: 0 auto;
        @media ($tablet-up)
        {
            max-width: 80vw;
        }
        .footer-wrapper
        {
            @include padding(null 40px);
            @media ($phone-and-tablet)
            {
                @include padding(null 10px);
            }
            .footer-links
            {
                @include padding(120px null 80px null);
                @media (max-width: 768px)
                {
                    @include padding(80px nul 40px null);
                }
                ul
                {
                    margin-left: 0;
                    padding-left: 0;

                    list-style: none;
                    @media ($tablet-up)
                    {
                        display: flex;

                        width: 100%;
                    }
                    @media ($phone-and-tablet)
                    {
                        @include margin(0);
                    }

                    li
                    {
                        text-align: center;
                        @media ($tablet-up)
                        {
                            display: table-cell; // even-width hack 1 of 2

                            width: 1%; // even-width hack 2 of 2

                            text-align: center;

                            flex-grow: 1;
                        }
                        a
                        {
                            @include padding(1vw);

                            font-family: 'NoeDisplay-Black';
                            font-size: 1.745vw;

                            transition: color $transition-speed $transition-easing;
                            text-decoration: none;

                            color: $black-50;

                            @media (max-width: 1440px)
                            {
                                font-size: 25px;
                            }

                            @media ($phone-and-tablet)
                            {
                                font-size: 23px;
                                line-height: 2;
                            }

                            &:hover
                            {
                                color: $purple;
                            }
                        }
                    }
                }
            }
            .footer-info
            {
                @include margin(null null 80px null);
                @media (max-width: 768px)
                {
                    @include margin(null null 60px null);
                }
                .footer-info-wrapper
                {
                    @include padding(null 40px);
                    &:not(.contact-info)
                    {
                        @media ($tablet-up)
                        {
                            display: flex;
                        }
                    }
                    &.contact-info
                    {
                        @media ($tablet-only)
                        {
                            @include columnizer(2.667 2.667 2.667, $set-neat-grid: $neat-grid-tablet);
                        }
                        @media ($desktop-only)
                        {
                            @include columnizer(4 4 4);
                        }
                    }
                    @media ($phone-and-tablet)
                    {
                        @include padding(null 0);
                    }

                    + .footer-info-wrapper
                    {
                        margin-top: 80px;

                        @media (max-width: 768px)
                        {
                            margin-top: 40px;
                        }
                    }
                    @media (max-width: 767px)
                    {
                        > div:not(:first-child)
                        {
                            margin-top: 20px;
                        }
                    }
                    div
                    {
                        @include font-sofia-pro;

                        text-align: center;

                        color: $black-50;
                        @media ($tablet-up)
                        {
                            flex-grow: 1;
                        }

                        .footer-title
                        {
                            @include font-sofia-pro-bold;

                            font-size: .7vw;

                            margin-bottom: .7vw;

                            text-indent: .7vw;
                            letter-spacing: .7vw;
                            text-transform: uppercase;

                            @media (max-width: 1440px)
                            {
                                font-size: 10px;

                                margin-bottom: 10px;

                                text-indent: 4px;
                                letter-spacing: 4px;
                            }
                        }

                        .footer-content
                        {
                            font-size: 1vw;

                            @media ($phone-and-tablet)
                            {
                                font-size: 14px;
                            }
                        }

                        .copyright-title
                        {
                            font-size: .7vw;

                            text-transform: uppercase;

                            color: $black-25;

                            @media ($phone-and-tablet)
                            {
                                font-size: 10px;

                                letter-spacing: 400;
                            }

                            &:not(:first-child)
                            {
                                margin-top: 15px;
                            }
                        }

                        a
                        {
                            transition: color $transition-speed $transition-easing;
                            text-decoration: none;

                            color: $black-50;

                            &:hover
                            {
                                color: $purple;
                            }
                        }
                    }
                    .copyright-title
                    {
                        font-weight: 700;

                        letter-spacing: 3px;
                        a
                        {
                            color: $black-25;
                        }
                    }
                }
            }
        }
    }
}
