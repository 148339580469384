$custom-animation-easing: cubic-bezier(.785, .135, .15, .86);

html,
body
{
    margin: 0;
    padding: 0;
}

//tiny slider no-js fallback
.no-js .new-homepage-features-container,
.no-js .vertical-image-slider
{
    overflow-x: auto;
}
.no-js .new-homepage-features-container,
.no-js .vertical-image-slider > div
{
    float: none;
}

//Re-usable Global Classes
.overflow-h
{
    overflow: hidden;
}

.overflow-v
{
    overflow: visible;
}

.pos-a-t-l
{
    position: absolute;
    top: 0;
    left: 0;
}

.pos-f-t-l
{
    position: fixed;
    top: 0;
    left: 0;
}

.new-homepage
{
    section#new-homepage-features
    {
        z-index: 2;

        overflow: hidden;

        width: 100vw;
        //fixes horizontal scrollbar appear bug
        max-width: 100%;
        height: 100vh;
        padding-top: 0;

        transition: background-color $transition-speed $transition-easing;

        @media ($phone-only)
        {
            overflow: visible;

            width: auto;
            height: auto;
        }

        &.pos-a-t-l
        {
            @extend .pos-a-t-l;
        }

        &.pos-f-t-l
        {
            @extend .pos-f-t-l;
        }

        .scroll
        {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 76px;
            height: 120px;

            @media ($phone-only)
            {
                display: none;
            }

            .scroll-wrapper
            {
                .scroll__text-wrapper
                {
                    position: relative;

                    height: 70px;

                    > div
                    {
                        @include position(absolute, null null 0 50%);
                        @include font-sofia-pro-bold;

                        font-size: .735vw;

                        margin-bottom: .85vw;

                        transform: rotate(-90deg);
                        transform-origin: left center;
                        letter-spacing: .4vw;

                        // color: $black-50;

                        @media (max-width: 1024px)
                        {
                            font-size: 10px;

                            letter-spacing: 4px;
                        }
                    }
                }
                .scroll__line-wrapper
                {
                    div
                    {
                        @include margin(0 auto);

                        width: 1px;
                        height: 50px;

                        animation: scroll-down-line 3s infinite $custom-animation-easing;

                        // background-color: $black-50;
                    }
                }
            }
        }

        > .tns-outer
        {
            @include position(absolute, 50% null null null);
            @include transform(translateY(-50%));

            width: 100%;

            .tns-controls
            {
                display: none;
            }
            .tns-nav
            {
                @include position(absolute, 50% 10px null null);
                @include transform(translateY(-50%));

                z-index: 1000;

                + button
                {
                    &[data-action='start'],
                    &[data-action='stop']
                    {
                        display: none;
                    }
                }

                button
                {
                    @include disable-select;

                    display: block;

                    &:focus
                    {
                        outline: 0;
                    }

                    + button
                    {
                        margin-top: 1vh;
                    }

                    &[aria-selected='true'],
                    &.tns-nav-active
                    {
                        position: relative;

                        width: 3vw;
                        height: 7vh;

                        border: none;
                        background-color: transparent;
                        &:after
                        {
                            @include position(absolute, 0 0 0 null);

                            width: 2px;
                            height: inherit;

                            content: '';

                            border-radius: 2px;
                            // background: $purple;
                        }
                    }

                    &[aria-selected='false'],
                    &:not(.tns-nav-active)
                    {
                        position: relative;

                        width: 3vw;
                        height: 2vh;

                        border: none;
                        background-color: transparent;
                        &:after
                        {
                            @include position(absolute, 0 0 0 null);

                            width: 2px;
                            height: inherit;

                            content: '';

                            border-radius: 2px;
                            // background: $black-50;
                        }
                    }
                }
            }
        }

        .new-homepage-features-container
        {
            @include position(absolute, 0);
            @include margin(0 auto);

            overflow-y: hidden;

            width: 85vw;

            @media ($phone-only)
            {
                @include position(static, auto);

                overflow-y: visible;

                width: 100%;
                max-width: none;
            }

            .new-feature-slide
            {
                .new-feature-slide-wrapper
                {
                    display: flex;

                    @media ($phone-only)
                    {
                        @include padding(60px 10px);

                        position: relative;

                        display: block;
                    }

                    .new-image-container
                    {
                        visibility: hidden;

                        flex-grow: 1;
                        flex-basis: 50%;
                        align-self: center;

                        .new-image-wrapper
                        {
                            .new-image
                            {
                                img
                                {
                                    width: 100%;
                                    @media ($phone-only)
                                    {
                                        width: 99%;
                                    }
                                }
                            }
                        }

                        @media ($phone-only)
                        {
                            visibility: visible;

                            margin: 0 auto;

                            flex-grow: 0;
                            flex-basis: auto;
                            align-self: auto;

                            .new-image-wrapper
                            {
                                position: relative;
                                .new-image
                                {
                                    //new image settings
                                }
                                .new-svg-wrapper
                                {
                                    @include position(absolute, 0 null null 0);

                                    width: 100%;

                                    &:after
                                    {
                                        @include transition(background-color $transition-speed $transition-easing);

                                        position: absolute;
                                        top: 0;
                                        left: 0;

                                        width: 100%;
                                        height: 100%;

                                        content: '';

                                        opacity: .8;

                                        // background-color: $yellow;
                                    }
                                }
                            }
                        }
                        @media ($phone-only)
                        {
                            width: 100vw;
                            //fixes horizontal scrollbar appear bug
                            max-width: 100%;
                        }
                    }
                    .new-content-container
                    {
                        @include animate;

                        opacity: 0;

                        flex-grow: 1;
                        flex-basis: 50%;
                        align-self: center;
                        &.visible
                        {
                            opacity: 1;
                        }

                        @media ($phone-only)
                        {
                            position: relative;
                            top: 0;

                            max-width: 68vw;
                            margin: 0 auto;
                            margin-top: -40%;

                            opacity: 1;

                            flex-grow: 0;
                            flex-basis: auto;
                            align-self: auto;
                        }
                        @media ($phone-only)
                        {
                            max-width: none;
                            padding-right: 10px;
                            padding-left: 10px;
                        }

                        .new-content-wrapper
                        {
                            padding-left: 4vw;

                            @media ($phone-only)
                            {
                                @include padding(0);
                            }

                            .new-content
                            {
                                @media (min-width: 768px)
                                {
                                    > div
                                    {
                                        > div.column
                                        {
                                            &:first-child
                                            {
                                                float: left;

                                                width: calc(50% - 10px);
                                                padding-right: 10px;
                                            }
                                            &:last-child
                                            {
                                                float: left;

                                                width: 50%;
                                            }
                                            ul
                                            {
                                                margin: 0;
                                            }
                                        }
                                        &:after
                                        {
                                            display: block;
                                            visibility: hidden;
                                            clear: both;

                                            height: 0;

                                            content: '.';
                                        }
                                    }
                                }
                            }
                        }

                        h1
                        {
                            @include font-sofia-pro-bold;

                            font-size: .735vw;
                            line-height: 1.35vw;

                            position: relative;

                            letter-spacing: 8px;
                            text-transform: uppercase;

                            // color: $black-50;

                            @media ($phone-only)
                            {
                                font-size: 10px;
                                line-height: 18px;
                            }

                            &:before
                            {
                                @include position(absolute, .675vw null null -30px);

                                width: 15px;
                                height: 1px;

                                content: '';
                                transform-origin: center right;

                                // background: $black-50;

                                @media ($phone-only)
                                {
                                    display: none;
                                }
                            }

                            span
                            {
                                font-family: 'NoeDisplay-Black';
                                font-size: 5.49vw;
                                line-height: 5.9vw;

                                display: block;

                                padding-top: 1.5vw;

                                letter-spacing: 0;
                                text-transform: none;

                                // color: $dark-purple;

                                @media ($phone-only)
                                {
                                    font-size: 8vw;
                                    line-height: 8vw;

                                    margin-top: 10px;
                                }

                                @media ($phone-only)
                                {
                                    font-size: 12.6vw;
                                    line-height: 12.6vw;
                                }
                            }
                        }
                        p
                        {
                            @include font-sofia-pro;

                            font-size: 1.025vw;
                            line-height: 1.62vw;

                            max-width: 375px;

                            letter-spacing: .015vw;

                            // color: $black-50;

                            + .new-button-container
                            {
                                margin-top: 1.2vw;

                                @media ($phone-only)
                                {
                                    margin-top: 16px;
                                }
                            }

                            @media ($phone-only)
                            {
                                font-size: 22px;
                            }

                            @media ($phone-only)
                            {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                        ul
                        {
                            margin: 0;
                            padding-left: 0;

                            list-style: none;
                            li
                            {
                                &:not(first-child)
                                {
                                    margin-top: .45vw;
                                }
                                a
                                {
                                    @include font-sofia-pro-bold;

                                    font-size: 1.025vw;

                                    transition: 250ms;
                                    text-decoration: none;

                                    color: $black-50;

                                    @media ($phone-only)
                                    {
                                        font-size: 1.5vw;
                                    }

                                    @media ($phone-only)
                                    {
                                        font-size: 20px;
                                        line-height: 20px;
                                    }

                                    &:hover
                                    {
                                        color: $purple;
                                    }
                                }
                            }
                        }
                        .new-button-container
                        {
                            margin-top: 1.2vw;

                            @media ($phone-only)
                            {
                                margin-top: 16px;
                            }

                            a.new-button
                            {
                                /* Firefox width fix */
                                @-moz-document url-prefix()
                                {
                                    position: relative;
                                    //width: -moz-max-content;
                                }

                                /* Edge */
                                @supports (-ms-ime-align: auto)
                                {
                                    position: relative;
                                }

                                span
                                {
                                    font-size: 1.025vw;

                                    display: inline-block;

                                    letter-spacing: .3vw;

                                    @media ($phone-only)
                                    {
                                        font-size: 14px;
                                        line-height: 14px;

                                        letter-spacing: 4px;
                                    }
                                }
                                .underline
                                {
                                    right: -6%;

                                    margin-left: -6%;

                                    /* Firefox */
                                    @-moz-document url-prefix()
                                    {
                                        position: absolute;
                                        right: 0;
                                        left: 0;

                                        width: 105%;
                                        margin-left: -2%;
                                    }

                                    /* Edge */
                                    @supports (-ms-ime-align: auto)
                                    {
                                        position: absolute;
                                        right: 0;
                                        left: 0;

                                        width: 101%;
                                    }

                                    /* IE11 */
                                    @media all and (-ms-high-contrast:none)
                                    {
                                        //position: absolute;
                                        top: -100% !important;
                                        //right: auto;
                                        //left: auto;
                                        //width: 110%;
                                        //height: 100%;
                                        //margin-right: 0;
                                        //margin-left: -2%;

                                        transform: translateY(0) !important;
                                        *::-ms-backdrop
                                        {
                                            position: absolute;
                                            top: -20%;
                                            right: auto;
                                            left: auto;

                                            width: 110%;
                                            height: 100%;
                                            margin-right: 0;
                                            margin-left: -2%;

                                            transform: translateY(100%);
                                        }
                                    }
                                }
                            }

                            @media ($phone-only)
                            {
                                display: flex;

                                justify-content: center;
                                align-items: center;

                                a.new-button
                                {
                                    display: inline-block;

                                    span
                                    {
                                        font-size: 14px;
                                        line-height: 14px;

                                        padding-right: 15px;
                                        padding-bottom: 5px;
                                        padding-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.mobile:first-child
                {
                    .new-feature-slide-wrapper
                    {
                        padding-top: 100px;
                    }
                }
            }
        }

        //homepage image slider
        .new-homepage-features-images
        {
            @include position(absolute, 50% null null null);
            @include transform(translateY(-50%));
            @include animate;

            width: 100%;

            pointer-events: none;

            opacity: 0;
            &.visible
            {
                opacity: 1;
            }

            @media ($phone-only)
            {
                @include position(static, auto null null null);
                @include transform(translateY(none));

                display: none;
            }
        }

        .new-homepage-features-images-container
        {
            @include margin(0 auto);

            width: 85vw;

            .new-feature-slide
            {
                .new-feature-slide-wrapper
                {
                    @include padding(1px null);

                    display: flex;
                    overflow: hidden;

                    @media ($phone-only)
                    {
                        display: block;
                        overflow: visible;
                    }

                    .new-image-container
                    {
                        flex-grow: 1;
                        flex-basis: 50%;
                        align-self: center;

                        .new-image-wrapper
                        {
                            position: relative;

                            .tns-outer
                            {
                                button
                                {
                                    &[data-action='start'],
                                    &[data-action='stop']
                                    {
                                        display: none;
                                    }
                                }

                                .tns-inner
                                {
                                    .vertical-image-slider
                                    {
                                        line-height: 0;

                                        overflow: hidden;

                                        padding-left: 1px;
                                    }
                                }
                            }

                            .slide__item
                            {
                                font-size: 0; //fixes glitch on touch devices, removes right border

                                max-width: 40vw;
                            }
                        }

                        .new-svg-wrapper
                        {
                            @include position(absolute, 50% null null 0);
                            @include transform(translateY(-50%));

                            width: 100%;

                            //ie11 hack
                            @media all and (-ms-high-contrast:none)
                            {
                                @include transform(translateY(-50%) translate(-22px));
                                /* IE11 start */

                                height: 105%;
                                *::-ms-backdrop
                                {
                                    @include transform(translateY(-50%) translate(-22px));

                                    height: 105%;
                                }
                            }/* IE11 end */

                            .color
                            {
                                // fill: $yellow;
                                transition: fill $transition-speed $transition-easing;
                            }
                        }
                    }
                    .new-content-container
                    {
                        flex-grow: 1;
                        flex-basis: 49%;
                        align-self: center;

                        .new-content-wrapper
                        {
                            padding-left: 3vw;

                            .new-content
                            {
                                overflow-y: hidden;
                            }
                        }
                    }
                }
            }
        }

        &[data-theme-color='yellow'],
        .mobile[data-color='yellow']
        {
            background-color: $yellow;

            .scroll__text-wrapper
            {
                > div
                {
                    color: $black-50;
                }
            }
            .scroll__line-wrapper
            {
                div
                {
                    background-color: $black-50;
                }
            }

            button
            {
                &[aria-selected='true'],
                &.tns-nav-active
                {
                    &:after
                    {
                        background: $dark-purple;
                    }
                }
                &[aria-selected='false'],
                &:not(.tns-nav-active)
                {
                    &:after
                    {
                        background: $black-30;
                    }
                }
            }

            .new-svg-wrapper
            {
                &:before,
                &:after
                {
                    background-color: $yellow;
                }

                .color
                {
                    fill: $yellow;
                }
            }

            h1
            {
                color: $black-50;

                &:before
                {
                    background: $black-50;
                }

                span
                {
                    color: $dark-purple;
                }
            }
            p
            {
                color: $black-50;
            }
            a.new-button
            {
                span
                {
                    color: $purple;
                }
            }
        }

        &[data-theme-color='old-gold'],
        .mobile[data-color='old-gold']
        {
            background-color: $old-gold;

            .scroll__text-wrapper
            {
                > div
                {
                    color: $white-70;
                }
            }
            .scroll__line-wrapper
            {
                div
                {
                    background-color: $white-70;
                }
            }

            button
            {
                &[aria-selected='true'],
                &.tns-nav-active
                {
                    &:after
                    {
                        background: $white;
                    }
                }
                &[aria-selected='false'],
                &:not(.tns-nav-active)
                {
                    &:after
                    {
                        background: $white-30;
                    }
                }
            }

            .new-svg-wrapper
            {
                &:before,
                &:after
                {
                    background-color: $old-gold;
                }

                .color
                {
                    fill: $old-gold;
                }
            }

            h1
            {
                color: $white;

                &:before
                {
                    background: $white;
                }

                span
                {
                    color: $white;
                }
            }
            p
            {
                color: $white;
            }
            a.new-button
            {
                span
                {
                    color: $white;
                }
                .underline
                {
                    .underline_wrapper
                    {
                        .path-underline
                        {
                            stroke: $white;
                        }
                    }
                }
            }
        }

        &[data-theme-color='teal'],
        .mobile[data-color='teal']
        {
            background-color: $teal;

            .scroll__text-wrapper
            {
                > div
                {
                    color: $white-70;
                }
            }
            .scroll__line-wrapper
            {
                div
                {
                    background-color: $white-70;
                }
            }

            button
            {
                &[aria-selected='true'],
                &.tns-nav-active
                {
                    &:after
                    {
                        background: $white;
                    }
                }
                &[aria-selected='false'],
                &:not(.tns-nav-active)
                {
                    &:after
                    {
                        background: $white-30;
                    }
                }
            }

            .new-svg-wrapper
            {
                &:before,
                &:after
                {
                    background-color: $teal;
                }

                .color
                {
                    fill: $teal;
                }
            }

            h1
            {
                color: $white;

                &:before
                {
                    background: $white;
                }

                span
                {
                    color: $white;
                }
            }
            p
            {
                color: $white;
            }
            a.new-button
            {
                span
                {
                    color: $white;
                }
                .underline
                {
                    .underline_wrapper
                    {
                        .path-underline
                        {
                            stroke: $white;
                        }
                    }
                }
            }
        }

        &[data-theme-color='orange'],
        .mobile[data-color='orange']
        {
            background-color: $orange;

            .scroll__text-wrapper
            {
                > div
                {
                    color: $white-70;
                }
            }
            .scroll__line-wrapper
            {
                div
                {
                    background-color: $white-70;
                }
            }

            button
            {
                &[aria-selected='true'],
                &.tns-nav-active
                {
                    &:after
                    {
                        background: $white;
                    }
                }
                &[aria-selected='false'],
                &:not(.tns-nav-active)
                {
                    &:after
                    {
                        background: $white-30;
                    }
                }
            }

            .new-svg-wrapper
            {
                &:before,
                &:after
                {
                    background-color: $orange;
                }

                .color
                {
                    fill: $orange;
                }
            }

            h1
            {
                color: $white;

                &:before
                {
                    background: $white;
                }

                span
                {
                    color: $white;
                }
            }
            p
            {
                color: $white;
            }
            a.new-button
            {
                span
                {
                    color: $white;
                }
                .underline
                {
                    .underline_wrapper
                    {
                        .path-underline
                        {
                            stroke: $white;
                        }
                    }
                }
            }
        }

        &[data-theme-color='black'],
        .mobile[data-color='black']
        {
            background-color: $black;

            .scroll__text-wrapper
            {
                > div
                {
                    color: $white-70;
                }
            }
            .scroll__line-wrapper
            {
                div
                {
                    background-color: $white-70;
                }
            }

            button
            {
                &[aria-selected='true'],
                &.tns-nav-active
                {
                    &:after
                    {
                        background: $white;
                    }
                }
                &[aria-selected='false'],
                &:not(.tns-nav-active)
                {
                    &:after
                    {
                        background: $white-30;
                    }
                }
            }

            .new-svg-wrapper
            {
                &:before,
                &:after
                {
                    background-color: $black;
                }

                .color
                {
                    fill: $black;
                }
            }

            h1
            {
                color: $white;

                &:before
                {
                    background: $white;
                }

                span
                {
                    color: $white;
                }
            }
            p
            {
                color: $white;
            }
            a.new-button
            {
                span
                {
                    color: $white;
                }
                .underline
                {
                    .underline_wrapper
                    {
                        .path-underline
                        {
                            stroke: $white;
                        }
                    }
                }
            }
        }
    }
}

//iOS 10.x.x Safari 10 fixes
.touch.safari-10
{
    .new-homepage
    {
        section#new-homepage-features
        {
            /**
             * iPad with portrait orientation.
             */
            @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
            {
                width: 768px;
                height: 1024px;
            }

            /**
             * iPad with landscape orientation.
             */
            @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)
            {
                width: 1024px;
                height: 768px;
            }

            h1
            {
                @media ($desktop-only)
                {
                    hyphens: unset !important;
                }
            }

            .new-homepage-features-container
            {
                /**
                 * iPad with portrait orientation.
                 */
                @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
                {
                    width: 652px;
                }

                /**
                 * iPad with landscape orientation.
                 */
                @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)
                {
                    width: 870px;
                }

                .new-feature-slide
                {
                    > div
                    {
                        /**
                         * iPad with portrait orientation.
                         */
                        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
                        {
                            float: left;

                            width: 50%;
                        }

                        /**
                         * iPad with landscape orientation.
                         */
                        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)
                        {
                            float: left;

                            width: 50%;
                        }
                    }
                    .new-feature-slide-wrapper
                    {
                        .new-image-container
                        {
                            @media ($phone-only)
                            {
                                width: 100vw;
                                //fixes horizontal scrollbar appear bug
                                max-width: 100%;
                            }
                        }
                        .new-content-container
                        {
                            .new-content-wrapper
                            {
                                /**
                                 * iPad with portrait orientation.
                                 */
                                @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
                                {
                                    padding-left: 30px;
                                }

                                /**
                                 * iPad with landscape orientation.
                                 */
                                @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)
                                {
                                    padding-left: 40px;
                                }
                            }
                            .new-button-container
                            {
                                // margin-top: 1.2vw;

                                @media ($phone-only)
                                {
                                    // margin-top: 16px;
                                }

                                a.new-button
                                {
                                    //firefox width fix
                                    // width: -moz-max-content;

                                    span
                                    {
                                        // display: inline-block;

                                        // font-size: 1.025vw;

                                        // letter-spacing: .3vw;

                                        // color: $purple;

                                        @media ($phone-only)
                                        {
                                            // font-size: 14px;
                                            // line-height: 14px;

                                            // letter-spacing: 4px;
                                        }
                                    }
                                    .underline
                                    {
                                        // right: -6%;

                                        // margin-left: -6%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes scroll-down-line
{
    0%,
    33.3%
    {
        @include transform(scaleY(1));

        transform-origin: center bottom;
    }
    66.6%
    {
        @include transform(scaleY(0));

        transform-origin: center bottom;
    }
    66.7%
    {
        @include transform(scaleY(0));

        transform-origin: center top;
    }
    100%
    {
        @include transform(scaleY(1));

        transform-origin: center top ;
    }
}
