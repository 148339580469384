/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
    margin: 0;
    padding: 0;

    vertical-align: top;

    border: 0;
    outline: none;
}

.fancybox-wrap
{
    position: absolute;
    z-index: 8020;
    top: 0;
    left: 0;
}

.fancybox-skin
{
    position: relative;
    // background: #fff;

    color: $dark-grey;
    text-shadow: none;
    // text-align: center;
    // -webkit-border-radius: 4px;
    //    -moz-border-radius: 4px;
    //         border-radius: 4px;
}

.fancybox-opened
{
    z-index: 8030;
}

.fancybox-opened .fancybox-skin
{
    margin: 0 !important;
    padding: 0 !important;
    // -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    //    -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    //         box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer,
.fancybox-inner
{
    position: relative;
}

.fancybox-inner
{
    overflow: hidden;
    .content
    {
        .left-content
        {
            position: relative;

            padding: 50px;
            .container
            {
                // background-color:yellow;
                // position: absolute;
                // bottom: 0;
                // top: 0;
            }
            img
            {
                width: 100%;
                margin-bottom: 30px;
            }
        }
        .right-content
        {
            padding: 50px 60px 50px 0;
        }
    }
}

.fancybox-type-iframe .fancybox-inner
{
    -webkit-overflow-scrolling: touch;
}

.fancybox-error
{
    font: 14px/20px 'Helvetica Neue',Helvetica,Arial,sans-serif;

    margin: 0;
    padding: 15px;

    white-space: nowrap;

    color: #444444;
}

.fancybox-image,
.fancybox-iframe
{
    display: block;

    width: 100%;
    height: 100%;
}

.fancybox-image
{
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span
{
    // background-image: url(fancybox_sprite.png);
}

#fancybox-loading
{
    position: fixed;
    z-index: 8060;
    top: 50%;
    left: 50%;

    margin-top: -22px;
    margin-left: -22px;

    cursor: pointer;

    opacity: .8;
    background-position: 0 -108px;
}

#fancybox-loading div
{
    width: 44px;
    height: 44px;
    // background: url(fancybox_loading.gif) center center no-repeat;
}

.fancybox-close
{
    position: absolute;
    z-index: 8040;
    top: -18px;
    right: -18px;

    width: 36px;
    height: 36px;

    cursor: pointer;
}

.fancybox-nav
{
    position: absolute;
    z-index: 8040;
    top: 0;

    width: 60px;
    height: 100%;

    cursor: pointer;
    text-decoration: none;

    background: transparent url(../../media/images/blank.gif); /* helps IE */

    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.fancybox-prev
{
    left: 0;
}

.fancybox-next
{
    right: 0;
}

.fancybox-nav span
{
    position: absolute;
    z-index: 8040;
    top: 50%;
    // visibility: hidden;

    visibility: visible;

    width: 36px;
    height: 34px;
    margin-top: -18px;

    cursor: pointer;
}

.fancybox-prev span
{
    left: 15px;

    background-position: 0 -36px;
}

.fancybox-next span
{
    right: 15px;

    background-position: 0 -72px;
}

.fancybox-nav:hover span
{
    visibility: visible;
}

.fancybox-tmp
{
    position: absolute;
    top: -99999px;
    left: -99999px;

    overflow: visible !important;

    max-width: 99999px;
    max-height: 99999px;
}

/* Overlay helper */

.fancybox-lock
{
    overflow: visible !important;

    width: auto;
}

.fancybox-lock body
{
    overflow: hidden !important;
}

.fancybox-lock-test
{
    overflow-y: hidden !important;
}

.fancybox-overlay
{
    position: absolute;
    z-index: 8010;
    top: 0;
    left: 0;

    display: none;
    overflow: hidden;

    background: rgba(255, 255, 255, .9);
    // background: url(fancybox_overlay.png);
}

.fancybox-overlay-fixed
{
    position: fixed;
    right: 0;
    bottom: 0;
}

.fancybox-lock .fancybox-overlay
{
    overflow: auto;
    overflow-y: scroll;
}

/* Title helper */

.fancybox-title
{
    font: normal 13px/20px 'sofia-pro', Helvetica, Arial, sans-serif;

    position: relative;
    z-index: 8050;

    visibility: hidden;

    text-shadow: none;
}

.fancybox-opened .fancybox-title
{
    visibility: visible;
}

.fancybox-title-float-wrap
{
    position: absolute;
    z-index: 8050;
    right: 50%;
    bottom: 0;

    margin-bottom: -35px;

    text-align: center;
}

.fancybox-title-float-wrap .child
{
    font-family: 'sofia-pro', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    // display: inline-block;
    // margin-right: -100%;
    // padding: 2px 20px;
    // background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    // background: rgba(0, 0, 0, 0.8);
    // -webkit-border-radius: 15px;
    //    -moz-border-radius: 15px;
    //         border-radius: 15px;
    // text-shadow: 0 1px 2px #222;
    // color: #FFF;
    // font-weight: bold;
    // line-height: 24px;
    // white-space: nowrap;

    display: inline-block;

    margin-right: -100%;
    padding: 5px 20px;

    white-space: nowrap;

    color: $dark-grey;
    background: transparent;
}

.fancybox-title-outside-wrap
{
    position: relative;

    margin-top: 10px;

    color: #ffffff;
}

.fancybox-title-inside-wrap
{
    padding-top: 10px;
}

.fancybox-title-over-wrap
{
    position: absolute;
    bottom: 0;
    left: 0;

    padding: 10px;

    color: #ffffff;
    background: #000000;
    background: rgba(0, 0, 0, .8);
}

.fancybox-close
{
    font-family: 'entypo';

    top: 5px;
    right: 20px;

    width: auto;
    height: auto;

    text-decoration: none;

    color: #9ea2a3;
    background-image: none;
}
.fancybox-close:before
{
    @include fontello($icon-close);

    font-size: 60px;
}

.fancybox-prev
{
    left: 0;
}
.fancybox-prev span
{
    width: auto;

    background-image: none;
}
.fancybox-prev span:before
{
    @include fontello($icon-chevron-right);

    font-size: 90px;

    transform: rotate(-180deg);
}

.fancybox-next
{
    right: 0;
}
.fancybox-next span
{
    width: auto;

    background-image: none;
}
.fancybox-next span:before
{
    @include fontello($icon-chevron-right);

    font-size: 90px;
}

.fancybox-title .share.icon
{
    font-size: 50px;

    padding: 10px;

    text-align: center;

    color: #9ea2a3;
    &:hover
    {
        color: $red;
    }
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5)
{
    #fancybox-loading,
    .fancybox-close,
    .fancybox-prev span,
    .fancybox-next span
    {
        background-image: url(fancybox_sprite@2x.png);
        background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
    }

    #fancybox-loading div
    {
        background-image: url(fancybox_loading@2x.gif);
        background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
    }
}
