.logos
{
    &.block--grey-background
    {
        a.new-button
        {
            @include new-button($purple-grey);
        }
    }
    .title
    {
        margin-bottom: 90px;
    }
    ul
    {
        display: flex;

        margin-left: 0;
        padding-left: 0;

        list-style: none;

        flex-wrap: wrap;

        @media($phone-only)
        {
            @include columnizer(2 2, $set-neat-grid: $neat-grid-phone, $collapse: true);
        }

        @media($tablet-only)
        {
            @include columnizer(2.667 2.667 2.667, $set-neat-grid: $neat-grid-tablet, $collapse: true);
        }

        @media($desktop-only)
        {
            @include columnizer(3 3 3 3, $collapse: true);
        }

        + *
        {
            margin-top: 100px;
        }

        li
        {
            box-sizing: border-box;

            background: $white;

            @media($phone-only)
            {
                @include padding(20px 20px);

                min-height: 100px;
                margin-bottom: 10px;

                &:nth-child(2n+1):nth-last-child(-n+2),
                &:nth-child(2n+1):nth-last-child(-n+2) ~ li
                {
                    margin-bottom: 0;

                    border: none;
                }
            }
            @media($tablet-only)
            {
                @include padding(30px 30px);

                min-height: 220px;
                margin-bottom: 16px;

                &:nth-child(3n+1):nth-last-child(-n+3),
                &:nth-child(3n+1):nth-last-child(-n+3) ~ li
                {
                    margin-bottom: 0;

                    border: none;
                }
            }
            @media($desktop-only)
            {
                @include padding(40px 30px);

                min-height: 250px;
                margin-bottom: 30px;

                &:nth-child(4n+1):nth-last-child(-n+4),
                &:nth-child(4n+1):nth-last-child(-n+4) ~ li
                {
                    margin-bottom: 0;

                    border: none;
                }
            }
            @media(min-width: 1920px)
            {
                min-height: 350px;
            }
            @media(min-width: 3840px)
            {
                min-height: 750px;
            }
        }
    }
}
