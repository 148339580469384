$pb-red: $purple;
.heading-container
{
    display: table;

    width: 80%;
    margin: 0 auto;
    .heading
    {
        display: table-cell;

        text-align: center;
        vertical-align: middle;
    }
}
.step-wrapper
{
    position: relative;

    overflow: hidden;

    height: 460px;
    @media ($phone-only)
    {
        height: 400px;
    }
    &:before
    {
        position: absolute;
        top: 0;
        left: -10%;

        width: 120%;
        height: 248px;

        content: '';

        border-bottom: 3px dotted $pb-red;
        @media ($phone-only)
        {
            height: 148px;
        }
    }
    .prev-btn,
    .next-btn
    {
        font-size: 36px;
        font-weight: bold;
        line-height: 49px;

        position: absolute;
        z-index: 1;
        top: 249px;

        display: flex;

        width: 56px;
        height: 56px;
        margin-top: -31px;

        cursor: pointer;
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
           -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* IE/Edge */
                user-select: none;
        text-align: center;

        color: $pb-red;
        border: 3px dotted $pb-red;
        border-radius: 60px;
        background: #ffffff;

        -webkit-touch-callout: none; /* iOS Safari */
         -khtml-user-select: none;    /* Konqueror */
        align-items: center;
        justify-content: center;
        &:after
        {
            @include fontello($icon-right);

            font-size: 2rem;

            margin: 0 0 0 0;
        }
    }
    .prev-btn
    {
        left: 10%;

        letter-spacing: 3px;

        &:after
        {
            display: inline-block;

            margin: 0 0 0 -5px;

            transform: rotate(180deg);
        }
    }
    .next-btn
    {
        right: 10%;

        letter-spacing: -3px;
    }



    .steps
    {
        position: absolute;
        top: 100px;
        right: 20%;
        left: 20%;

        height: 300px;

        -webkit-user-select: none;   /* Chrome/Safari/Opera */
           -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* IE/Edge */
                user-select: none;
        transition-timing-function: ease-in-out;
        -webkit-transition-duration: 750ms; /* Safari */
                transition-duration: 750ms;
        text-align: center;

        -webkit-touch-callout: none; /* iOS Safari */
         -khtml-user-select: none;    /* Konqueror */
        @media ($phone-only)
        {
            top: 0;
        }
        &.right-two
        {
            left: 40%;
            .step
            {
                width: 50%;
                margin-left: -5%;
            }
        }
        &.right-two:before
        {
            left: 25%;

            width: 50%;
        }
        &.left-two
        {
            right: 40%;
            .step
            {
                width: 50%;
                margin-left: -5%;
            }
        }
        &.left-two:before
        {
            left: 25%;

            width: 50%;
        }
        &:before
        {
            position: absolute;
            top: 0;
            left: 19%;

            width: 66%;
            height: 148px;

            content: '';
            transition-timing-function: ease-in-out;
            -webkit-transition-duration: 750ms; /* Safari */
                    transition-duration: 750ms;

            border-bottom: 3px solid $pb-red;
        }
        .step
        {
            position: absolute;
            top: 0;
            bottom: 0;

            width: 33.33%;
            padding: 0 2%;

            transition-timing-function: ease-in-out;
            -webkit-transition-duration: 750ms; /* Safari */
                    transition-duration: 750ms;
            text-align: center;

            opacity: 1;
            &:before
            {
                position: absolute;
                top: 50%;
                left: 50%;

                width: 16px;
                height: 16px;
                margin-top: -12px;
                margin-left: -12px;

                content: '';
                transition-timing-function: ease-in-out;
                -webkit-transition-duration: 750ms; /* Safari */
                        transition-duration: 750ms;

                border: 3px solid $pb-red;
                border-radius: 16px;
                background: #ffffff;
            }
            &:after
            {
                position: absolute;
                top: 50%;
                left: 50%;

                width: 0;
                height: 0;
                margin-top: -1px;
                margin-left: -1px;

                content: '';
                -webkit-transition: all 750ms cubic-bezier(.190, 1.000, .220, 1.000);
                   -moz-transition: all 750ms cubic-bezier(.190, 1.000, .220, 1.000);
                     -o-transition: all 750ms cubic-bezier(.190, 1.000, .220, 1.000);
                        transition: all 750ms cubic-bezier(.190, 1.000, .220, 1.000);

                border-radius: 16px;
                background: #ffffff;
                background: $pb-red;
            }
            &.step-active:after
            {
                width: 18px;
                height: 18px;
                margin-top: -10px;
                margin-left: -10px;

                -webkit-transition: all 750ms cubic-bezier(.950, .050, .795, .035);
                   -moz-transition: all 750ms cubic-bezier(.950, .050, .795, .035);
                     -o-transition: all 750ms cubic-bezier(.950, .050, .795, .035);
                        transition: all 750ms cubic-bezier(.950, .050, .795, .035);

                border-radius: 16px;
            }
            .title
            {
                position: relative;

                height: 135px;
                .icon
                {
                    position: absolute;
                    bottom: 48px;

                    width: 100%;
                    img
                    {
                        width: auto;
                        max-height: 130px;
                        @media ($phone-only)
                        {
                            max-height: 90px;
                        }
                    }
                }
                p
                {
                    @include font-sofia-pro;

                    font-size: 1.4rem;

                    position: absolute;
                    bottom: 0;

                    width: 100%;

                    text-transform: uppercase;
                }
            }
            .description
            {
                width: 197px;
                max-width: 100%;
                margin: 0 auto;
                margin-top: 40px;

                -webkit-transition-duration: 750ms; /* Safari */
                        transition-duration: 750ms;

                opacity: 0;

                // padding: 0 5%;
                p
                {
                    @include font-sofia-pro;

                    font-size: 1.5rem;
                }
            }
            &.step-active
            {
                .description
                {
                    opacity: 1;
                }
            }
            &:hover
            {
                .description
                {
                    opacity: 1;
                }
            }
        }
    }
}

@media only screen and (min-width: 40em) and (max-width: 64em)
{
    .step-wrapper
    {
        .prev-btn
        {
            left: 5%;
        }
        .next-btn
        {
            right: 5%;
        }
        h1
        {
            font-size: 2.5rem;
        }
        .steps
        {
            right: 10%;
            left: 10%;
            .step
            {
                .title
                {
                    width: 130%;
                    margin-left: -15%;
                }
            }
        }
    }
}

@media only screen and (max-width: 40em)
{
    .step-wrapper
    {
        &:before
        {
            left: 5%;

            width: 90%;
        }
        .prev-btn,
        .next-btn
        {
            font-size: 30px;
            line-height: 41px;

            width: 46px;
            height: 46px;
            margin-top: -25px;
            @media ($phone-only)
            {
                margin-top: -125px;
            }
        }
        .prev-btn
        {
            left: 2%;
        }
        .next-btn
        {
            right: 2%;
        }
        > h1
        {
            font-size: 2rem;
        }
        .steps
        {
            right: 0;
            left: 0;
            &:before
            {
                border-bottom: none;
            }
            &.left-two
            {
                right: 0;
                .step
                {
                    width: 80%;
                }
            }
            &.right-two
            {
                left: 0;
                .step
                {
                    width: 80%;
                }
            }
            .step
            {
                width: 80%;
                .description
                {
                    margin-top: 50px;
                }
            }
        }
    }
}

.process-button
{
    width: 100%;

    text-align: center;
    @media ($phone-only)
    {
        top: 360px;
        a
        {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    a
    {
        font-family: 'sofia-pro', Helvetica, Arial, sans-serif;
        font-weight: 800;

        display: inline-block;

        max-width: 400px;
        padding: 10px 15px;

        -webkit-transition: all 250ms linear;
           -moz-transition: all 250ms linear;
             -o-transition: all 250ms linear;
                transition: all 250ms linear;
        text-decoration: none;
        text-transform: uppercase;

        color: $pb-red;
        border: 3px solid $pb-red;
        &:hover
        {
            color: #ffffff;
            background: $pb-red;
        }
    }
}
