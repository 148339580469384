.banner
{
    position: relative;

    width: 100%;
    height: auto;

    background-color: $black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:after
    {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: '';

        opacity: .9;
        background-color: #251f29;
    }

    .banner__wrapper
    {
        .content
        {
            position: relative;
            z-index: 1;

            color: $white;

            @media($phone-only)
            {
                @include columnizer(4, $set-neat-grid: $neat-grid-phone);

                padding-right: 20px;
                padding-left: 20px;
            }
            @media($tablet-only)
            {
                @include columnizer(6, 1, $set-neat-grid: $neat-grid-tablet);

                padding-right: 20px;
                padding-left: 20px;
            }
            @media($desktop-only)
            {
                @include columnizer(9, 3);

                padding-top: 17vh;
                padding-right: 9%;
                padding-bottom: 9vh;
                padding-left: 9%;
            }

            .details
            {
                .section-title
                {
                    @include t-label;

                    color: $yellow;

                    + .title
                    {
                        margin-top: 30px;
                    }
                }
                .title
                {
                    @media($desktop-only)
                    {
                        max-width: 50vw;
                    }
                    h1
                    {
                        @include t-h1;

                        color: $white;
                    }

                    + .description
                    {
                        margin-top: 20px;
                    }
                }
                .description
                {
                    @media($desktop-only)
                    {
                        max-width: 40vw;
                    }
                    p
                    {
                        @include t-copy;

                        color: $white;
                    }
                }
                .anchor-nav
                {
                    @media($phone-only)
                    {
                        margin-top: 45px;
                    }
                    @media($tablet-only)
                    {
                        margin-top: 16vh;
                    }
                    @media($desktop-only)
                    {
                        margin-top: 20vh;
                    }
                    ul
                    {
                        display: flex;

                        margin-left: 0;
                        padding-left: 0;

                        list-style: none;

                        flex-wrap: wrap;

                        @media($desktop-only)
                        {
                            max-width: 80%;
                        }

                        > li
                        {
                            padding-bottom: 20px;

                            flex: 1 1 auto;

                            a
                            {
                                @include t-anchor-nav;

                                text-decoration: none;

                                color: $purple-grey;

                                &:hover
                                {
                                    color: $purple;
                                }
                            }
                        }
                    }
                }
            }
            .meta-list
            {
                @media($phone-only)
                {
                    margin-top: 20px;
                    margin-left: 0 !important;
                }
                > div
                {
                    margin-bottom: 20px;
                }
                @media($phone-only)
                {
                    @include columnizer(2 2, $set-neat-grid: $neat-grid-phone)
                }
                @media($tablet-up)
                {
                    margin-top: 40px;
                }
                .label
                {
                    @include t-meta;
                }
                .value
                {
                    @include t-copy;

                    color: $white;
                    a
                    {
                        @include animate;

                        text-decoration: none;

                        color: $white;
                        &:hover
                        {
                            color: $purple;
                        }
                    }
                }
            }
        }
    }

    &.banner--detail
    {
        .banner__wrapper
        {
            .content
            {
                color: $white;
                @media($phone-only)
                {
                    @include columnizer(4, $set-neat-grid: $neat-grid-phone);

                    padding-right: 20px;
                    padding-left: 20px;
                }
                @media($tablet-only)
                {
                    @include columnizer(6 2, 2 -8, $set-neat-grid: $neat-grid-tablet);

                    padding-right: 20px;
                    padding-left: 20px;
                }
                @media($desktop-only)
                {
                    @include columnizer(9 3, 3 -12);

                    padding-top: 160px;
                    padding-right: 9%;
                    padding-bottom: 160px;
                    padding-left: 9%;
                }

                .details
                {
                    .section-title
                    {
                        @include t-label;

                        color: $yellow;

                        + .title
                        {
                            margin-top: 30px;
                        }
                    }
                    .title
                    {
                        @media($desktop-only)
                        {
                            max-width: 50vw;
                        }
                        h1
                        {
                            @include t-h1;

                            color: $white;
                        }

                        + .description
                        {
                            margin-top: 20px;
                        }
                    }
                    .description
                    {
                        @media($desktop-only)
                        {
                            max-width: 40vw;
                        }
                        p
                        {
                            @include t-copy;

                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
