$white: #ffffff;
$red: #ee3124;

$blue-configurator: #15adff;

$header-font-family-serif: 'sofia-pro', Georgia, serif;

$configurator-url: '/img/site/configurator/';

$configurator-width: 3000;
$configurator-height: 1688;

$z-low: 2;
$z-mid: 5;
$z-high: 7;
$z-ui: 10;
$z-top: 12;

$balls: a b c d e f g h i j k l;

@mixin configurator-piece($piece)
{
    $url: $configurator-url + $piece;

    background-image: url(#{$url});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@mixin configurator-size($width, $height)
{
    width: $width / $configurator-width * 100%;
    height: $height / $configurator-height * 100%;
}

@mixin configurator-position($top, $left, $index: $z-mid)
{
    position: absolute;

    z-index: $index;
    top: $top / $configurator-height * 100%;
    left: $left / $configurator-width * 100%;
}

@function configurator-scale-width($width)
{
    @return $width / $configurator-width * 100%;
}

@function configurator-scale-height($height)
{
    @return $height / $configurator-height * 100%;
}

.configurator-wrapper
{
    font-weight: 600;

    position: relative;

    overflow: hidden;

    padding-bottom: 56.25%; /* 16:9 */
    .hopper
    {
        &:before
        {
            @include configurator-piece('Hopper/Hopper.png');
            @include configurator-size(601, 409);
            @include configurator-position(0, 101, $z-high);

            display: block;

            content: '';
        }
        &.shuffle
        {
            @each $ball in $balls
            {
                .ball-#{$ball}
                {
                    animation: shuffle-#{$ball} .5s linear;
                }
            }
        }
        .ball-drop
        {
            @include configurator-size(170, 170);
            @include configurator-position(70, 290, $z-low);
            &.fall
            {
                animation: ball-fall 1s linear;
            }
            &.a
            {
                @include configurator-piece('Hopper/Ball_A.png');
            }
            &.b
            {
                @include configurator-piece('Hopper/Ball_B.png');
            }
            &.c
            {
                @include configurator-piece('Hopper/Ball_C.png');
            }
            &.d
            {
                @include configurator-piece('Hopper/Ball_D.png');
            }
            &.e
            {
                @include configurator-piece('Hopper/Ball_E.png');
            }
            &.f
            {
                @include configurator-piece('Hopper/Ball_F.png');
            }
            &.g
            {
                @include configurator-piece('Hopper/Ball_G.png');
            }
            &.h
            {
                @include configurator-piece('Hopper/Ball_H.png');
            }
            &.i
            {
                @include configurator-piece('Hopper/Ball_I.png');
            }
            &.j
            {
                @include configurator-piece('Hopper/Ball_J.png');
            }
            &.k
            {
                @include configurator-piece('Hopper/Ball_K.png');
            }
            &.l
            {
                @include configurator-piece('Hopper/Ball_L.png');
            }
        }
        .ball-a
        {
            @include configurator-piece('Hopper/Ball_A.png');
            @include configurator-size(170, 170);
            @include configurator-position(60, 340);
        }
        .ball-b
        {
            @include configurator-piece('Hopper/Ball_B.png');
            @include configurator-size(170, 170);
            @include configurator-position(80, 270);
        }
        .ball-c
        {
            @include configurator-piece('Hopper/Ball_C.png');
            @include configurator-size(170, 170);
            @include configurator-position(60, 200);
        }
        .ball-d
        {
            @include configurator-piece('Hopper/Ball_D.png');
            @include configurator-size(170, 170);
            @include configurator-position(-20, 140);
        }
        .ball-e
        {
            @include configurator-piece('Hopper/Ball_E.png');
            @include configurator-size(170, 170);
            @include configurator-position(10, 230);
        }
        .ball-f
        {
            @include configurator-piece('Hopper/Ball_F.png');
            @include configurator-size(170, 170);
            @include configurator-position(-70, 340);
        }
        .ball-g
        {
            @include configurator-piece('Hopper/Ball_G.png');
            @include configurator-size(170, 170);
            @include configurator-position(-80, 230);
        }
        .ball-h
        {
            @include configurator-piece('Hopper/Ball_H.png');
            @include configurator-size(170, 170);
            @include configurator-position(20, 430);
        }
        .ball-i
        {
            @include configurator-piece('Hopper/Ball_I.png');
            @include configurator-size(170, 170);
            @include configurator-position(-50, 490);
        }
        .ball-j
        {
            @include configurator-piece('Hopper/Ball_J.png');
            @include configurator-size(170, 170);
            @include configurator-position(-90, 390);
        }
        .ball-k
        {
            @include configurator-piece('Hopper/Ball_K.png');
            @include configurator-size(170, 170);
            @include configurator-position(-100, 110);
        }
        .ball-l
        {
            @include configurator-piece('Hopper/Ball_L.png');
            @include configurator-size(170, 170);
            @include configurator-position(-100, 520);
        }
    }
    .machine
    {
        &:before
        {
            @include configurator-piece('Outline_Top.png');
            @include configurator-size(2053, 1341);
            @include configurator-position(348, 85, $z-high);

            display: block;

            content: '';
        }
        &:after
        {
            @include configurator-piece('Outline_Bottom.png');
            @include configurator-size(770, 1157);
            @include configurator-position(532, 2083, $z-low);

            display: block;

            content: '';
        }
        .guage-a
        {
            &:before
            {
                @include configurator-piece('Gauges/Dial_A.png');
                @include configurator-size(164, 164);
                @include configurator-position(810, 590, $z-ui);

                display: block;

                content: '';
            }
            &:after
            {
                @include configurator-piece('Gauges/Needle_A.png');
                @include configurator-size(164, 164);
                @include configurator-position(810, 590, $z-high);
                animation: needle-spin-a 10s linear infinite;

                display: block;

                content: '';
            }
        }
        .guage-b
        {
            &:before
            {
                @include configurator-piece('Gauges/Dial_B.png');
                @include configurator-size(164, 164);
                @include configurator-position(965, 595, $z-ui);

                display: block;

                content: '';
            }
            &:after
            {
                @include configurator-piece('Gauges/Needle_B.png');
                @include configurator-size(164, 164);
                @include configurator-position(965, 595, $z-high);
                animation: needle-spin-b 10s linear infinite;

                display: block;

                content: '';
            }
        }
        .guage-c
        {
            &:before
            {
                @include configurator-piece('Gauges/Dial_C.png');
                @include configurator-size(164, 164);
                @include configurator-position(1120, 605, $z-ui);

                display: block;

                content: '';
            }
            &:after
            {
                @include configurator-piece('Gauges/Needle_C.png');
                @include configurator-size(164, 164);
                @include configurator-position(1120, 605, $z-high);
                animation: needle-spin-c 10s linear infinite;

                display: block;

                content: '';
            }
        }
        .roller-a
        {
            @include configurator-piece('Roller_A.png');
            @include configurator-size(148, 148);
            @include configurator-position(1392, 2340, $z-low);
            animation: roller-spin 5s linear infinite;
        }
        .roller-b
        {
            @include configurator-piece('Roller_B.png');
            @include configurator-size(148, 148);
            @include configurator-position(1390, 2180, $z-low);
            animation: roller-spin 5s linear infinite;
        }
        .roller-c
        {
            @include configurator-piece('Roller_C.png');
            @include configurator-size(148, 148);
            @include configurator-position(1391, 2025, $z-low);
            animation: roller-spin 5s linear infinite;
        }
        .conveyor
        {
            $speed: 2s;
            .line-a
            {
                @include configurator-piece('Conveyor_Line_A.png');
                @include configurator-size(303, 20);
                animation: conveyor-a $speed linear infinite;
            }
            .line-b
            {
                @include configurator-piece('Conveyor_Line_B.png');
                @include configurator-size(316, 28);
                animation: conveyor-b $speed linear infinite;
                animation-delay: $speed/4;
            }
            .line-c
            {
                @include configurator-piece('Conveyor_Line_C.png');
                @include configurator-size(316, 28);
                animation: conveyor-c $speed linear infinite;
                animation-delay: $speed/4*2;
            }
            .line-d
            {
                @include configurator-piece('Conveyor_Line_D.png');
                @include configurator-size(316, 28);
                animation: conveyor-d $speed linear infinite;
                animation-delay: $speed/4*3;
            }
        }
        .screen
        {
            @include configurator-size(910, 495);
            @include configurator-position(852, 815, $z-ui);
            @include padding(1.6rem 2.0rem 2.0rem);

            font-size: 100%;
            line-height: 1.2;

            text-align: center;

            color: $white;
            .response
            {
                @include position(absolute, 50% 0 null);
                @include padding(null 1.6rem);

                font-size: 2.0rem;

                transform: translateY(-50%);
                form
                {
                    display: none;
                    &.show
                    {
                        display: block;
                    }
                    input
                    {
                        //@include placeholder
                        //{
                        //    color: rgba($white, .75);
                        //}

                        text-align: center;

                        color: $white;
                        border: none;
                        border-bottom: 4px solid $white;
                        background: transparent;
                        box-shadow: none;
                        &[type='submit']
                        {
                            @include transition(all 250ms ease);
                            @include padding(5px 15px);

                            color: $blue-configurator;
                            border: none;
                            background: $white;
                            box-shadow: none;
                            &:hover
                            {
                                color: $white;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            .question
            {
                font-family: $header-font-family-serif;
                font-size: 3.0rem;

                margin-bottom: 1.0rem;
            }
            .answers
            {
                @include margin(1.0rem 4.4rem null);

                position: relative;

                height: 40%;
                .answer
                {
                    @include position(absolute, 50% 0 null);
                    @include transform(translateY(-50%));
                    //@include transition(all 250ms ease);

                    font-size: 2.0rem;

                    opacity: 0;
                    &.active
                    {
                        opacity: 1;
                    }
                }
                .prev,
                .next
                {
                    @include padding(null 5%);
                    @include transform(translateY(-50%));

                    font-size: 4.0rem;

                    cursor: pointer;
                }
                .prev
                {
                    @include position(absolute, 50% null null -50px);
                    &:before
                    {
                        @include fontello($icon-chevron-left);

                        display: block;
                    }
                }
                .next
                {
                    @include position(absolute, 50% -50px null null);
                    &:before
                    {
                        @include fontello($icon-chevron-right);

                        display: block;
                    }
                }

                @media(min-width: 1400px)
                {
                    .prev
                    {
                        left: -75px;
                    }
                    .next
                    {
                        right: -75px;
                    }
                }

                @media(max-width: 1024px)
                {
                    .prev
                    {
                        left: -40px;
                    }
                    .next
                    {
                        right: -40px;
                    }
                }
            }
        }
        .lights
        {
            [class^='light-']
            {
                $rnd_n: 0;
                $prev_rnd_n: 0;
                &:before,
                &:after
                {
                    @include configurator-position(645, 850, $z-ui);
                    @include configurator-size(64, 64);

                    display: block;

                    content: '';
                }
                &:before
                {
                    opacity: 0;
                }
                &.on
                {
                    &:before
                    {
                        opacity: 1;
                    }
                }
                @for $i from 1 through 10
                {
                    &:nth-child(#{$i})
                    {
                        $prev_rnd_n: $rnd_n;
                        &:before,
                        &:after
                        {
                            margin-left: configurator-scale-width(100) * ( $i - 1 );
                        }
                        @while $rnd_n == $prev_rnd_n
                        {
                            $rnd_n: random(4);
                        }
                        &:before
                        {
                            @include configurator-piece('Lights/Light_#{$rnd_n}_Fill.png');
                        }
                        &:after
                        {
                            @include configurator-piece('Lights/Light_#{$rnd_n}.png');
                        }
                    }
                }
            }
        }
        .prev-button
        {
            display: none;

            cursor: pointer;
            &:before
            {
                @include transform(scaleX(-.75) scaleY(.75));
                @include configurator-piece('Button.png');
                @include configurator-size(219, 134);
                @include configurator-position(1450, 615, $z-ui);

                display: block;

                content: '';
            }
            &:after
            {
                @include transform(scaleX(-.75) scaleY(.75));
                @include configurator-piece('Button_Fill.png');
                @include configurator-size(212, 138);
                @include configurator-position(1448, 620, $z-high);

                display: block;

                content: '';
            }
        }
        .next-button
        {
            cursor: pointer;
            &:before
            {
                @include configurator-piece('Button.png');
                @include configurator-size(219, 134);
                @include configurator-position(1410, 1695, $z-ui);

                display: block;

                content: '';
            }
            &:after
            {
                @include configurator-piece('Button_Fill.png');
                @include configurator-size(212, 138);
                @include configurator-position(1408, 1700, $z-high);

                display: block;

                content: '';
            }
        }
    }
    .roof-pipes
    {
        @include configurator-piece('Roof_Pipes.png');
        @include configurator-size(1147, 445);
        @include configurator-position(0, 1580);
    }
    .gear-a
    {
        @include configurator-piece('Gear_A.png');
        @include configurator-size(350, 350);
        @include configurator-position(500, 2650);
        animation: roller-spin 10s linear infinite;
    }
    .gear-b
    {
        @include configurator-piece('Gear_B.png');
        @include configurator-size(350, 350);
        @include configurator-position(280, 2825);
        animation: roller-spin 10s linear infinite;
        animation-direction: reverse;
    }
    .output
    {
        &.triangle
        {
            animation: output-triangle 10s linear;
        }
        &.square
        {
            animation: output-square 10s linear;
        }
        &.rubble
        {
            animation: output-rubble 10s linear;
        }
    }
    .plaque
    {
        @include configurator-piece('Plaque.png');
        @include configurator-size(824, 221);
        @include configurator-position(1470, 825, $z-high);
    }
    .bubble-a
    {
        animation: bubble-a 1.2s linear infinite;
    }
    .bubble-b
    {
        animation: bubble-b 1s linear infinite;
    }
    .bubble-c
    {
        animation: bubble-c 1.4s linear infinite;
    }
    .help-prev-next
    {
        @include configurator-position(1350, 1350, $z-ui);
        @include configurator-size(500, 0);
        //@include transform(rotateZ(2deg));
        @include padding(.75% .5%);

        font-size: 1.6rem;

        height: auto;

        text-align: center;

        color: $white;
        &.hide
        {
            display: none;
        }
        @media(max-width: 768px)
        {
            @include configurator-position(1350, 1400, $z-ui);
        }
        &:before,
        &:after
        {
            z-index: -1;

            display: block;

            content: '';

            background-color: $red;
        }
        &:before
        {
            @include position(absolute, 0 0 0 0);
        }
        &:after
        {
            //@include transform(rotateZ(40deg) skewY(25deg));
            //@include position(absolute, -4% null null 33%);
            @include transform(rotateZ(45deg));
            @include position(absolute, -10% null null 66%);

            width: 14%;
            height: 70%;
        }
    }
    .help-answer
    {
        @include configurator-position(1425, 1100, $z-ui);
        @include configurator-size(600, 0);
        //@include transform(rotateZ(-2deg));
        @include padding(.75% .5%);

        font-size: 1.6rem;

        height: auto;

        text-align: center;

        color: $white;
        &.hide
        {
            display: none;
        }
        &:before,
        &:after
        {
            z-index: -1;

            display: block;

            content: '';

            background-color: $red;
        }
        &:before
        {
            @include position(absolute, 0 0 0 0);
        }
        &:after
        {
            //@include transform(rotateZ(25deg) skewY(25deg));
            //@include position(absolute, null null -4% 66%);
            @include transform(rotateZ(45deg));
            @include position(absolute, null 0% 20% null);

            width: 14%;
            height: 70%;
        }
    }
}

@each $ball in $balls
{
    @keyframes shuffle-#{$ball}
    {
        0%
        {
            @include transform(translateY(0%));
        }
        50%
        {
            $moveY: 5 - random(10);
            $moveX: 5 - random(10);

            @include transform(translateY($moveY + 0%) translateX($moveX + 0%));
        }
        100%
        {
            @include transform(translateY(0%));
        }
    }
}

@keyframes ball-fall
{
    0%
    {
        @include configurator-position(70, 290, $z-low);
    }
    17%
    {
        @include configurator-position(110, 240, $z-low);
    }
    35%
    {
        @include configurator-position(260, 310, $z-low);
    }
    100%
    {
        @include configurator-position(800, 200, $z-low);
    }
}

@keyframes bubble-a
{
    0%
    {
        @include configurator-piece('Bubble_a1.png');
        @include configurator-size(78, 50);
        @include configurator-position(425, 825);
    }
    33%
    {
        @include configurator-piece('Bubble_a1.png');
        @include configurator-size(78, 50);
        @include configurator-position(395, 825);
    }
    33.33%
    {
        @include configurator-piece('Bubble_a2.png');
        @include configurator-size(86, 40);
        @include configurator-position(425, 815);
    }
    66.33%
    {
        @include configurator-piece('Bubble_a2.png');
        @include configurator-size(86, 40);
        @include configurator-position(395, 815);
    }
    66.66%
    {
        @include configurator-piece('Bubble_a3.png');
        @include configurator-size(102, 51);
        @include configurator-position(440, 805);
    }
    100%
    {
        @include configurator-position(415, 805);
    }
}

@keyframes bubble-b
{
    0%
    {
        @include configurator-piece('Bubble_b1.png');
        @include configurator-size(84, 30);
        @include configurator-position(435, 1075);
    }
    33%
    {
        @include configurator-piece('Bubble_b1.png');
        @include configurator-size(84, 30);
        @include configurator-position(410, 1075);
    }
    33.33%
    {
        @include configurator-piece('Bubble_b2.png');
        @include configurator-size(82, 39);
        @include configurator-position(425, 1075);
    }
    66.33%
    {
        @include configurator-piece('Bubble_b2.png');
        @include configurator-size(82, 39);
        @include configurator-position(395, 1075);
    }
    66.66%
    {
        @include configurator-piece('Bubble_b3.png');
        @include configurator-size(82, 37);
        @include configurator-position(415, 1075);
    }
    100%
    {
        @include configurator-position(385, 1075);
    }
}

@keyframes bubble-c
{
    0%
    {
        @include configurator-piece('Bubble_c1.png');
        @include configurator-size(96, 61);
        @include configurator-position(460, 1340);
    }
    33%
    {
        @include configurator-piece('Bubble_c1.png');
        @include configurator-size(96, 61);
        @include configurator-position(420, 1340);
        @include transform(scaleY(1));
    }
    33.33%
    {
        @include configurator-piece('Bubble_c2.png');
        @include configurator-size(102, 63);
        @include configurator-position(470, 1340);
        @include transform(scaleY(-1));
    }
    66.33%
    {
        @include configurator-piece('Bubble_c2.png');
        @include configurator-size(102, 63);
        @include configurator-position(430, 1340);
        @include transform(scaleY(-1));
    }
    66.66%
    {
        @include configurator-piece('Bubble_c3.png');
        @include configurator-size(102, 58);
        @include configurator-position(440, 1340);
        @include transform(scaleY(1));
    }
    100%
    {
        @include configurator-piece('Bubble_c3.png');
        @include configurator-size(102, 58);
        @include configurator-position(400, 1340);
    }
}

@keyframes output-rubble
{
    0%
    {
        @include configurator-piece('Rubble_1.png');
        @include configurator-size(277, 148);
        @include configurator-position(1232, 1850);
    }
    49%
    {
        @include configurator-piece('Rubble_1.png');
        @include configurator-size(277, 148);
        @include configurator-position(1232, 2450);
    }
    50%
    {
        @include configurator-piece('Rubble_2.png');
        @include configurator-size(219, 209);
        @include configurator-position(1250, 2500);
        @include transform(rotateZ(0));
    }
    64.9%
    {
        @include configurator-piece('Rubble_2.png');
        @include configurator-size(219, 209);
        @include configurator-position(1440, 2680);
        @include transform(rotateZ(60deg));
    }
    65%
    {
        @include configurator-piece('Rubble_3.png');
        @include configurator-size(170, 216);
        @include configurator-position(1450, 2750);
        @include transform(rotateZ(0));
    }
    100%
    {
        @include configurator-position(2200, 3100);
        @include transform(rotateZ(90deg));
    }
}

@keyframes output-square
{
    0%
    {
        @include configurator-piece('Square_1.png');
        @include configurator-size(192, 158);
        @include configurator-position(1232, 1850);
    }
    49%
    {
        @include configurator-piece('Square_1.png');
        @include configurator-size(192, 158);
        @include configurator-position(1232, 2475);
    }
    50%
    {
        @include configurator-piece('Square_2.png');
        @include configurator-size(230, 185);
        @include configurator-position(1245, 2500);
        @include transform(rotateZ(0));
    }
    64.9%
    {
        @include configurator-piece('Square_2.png');
        @include configurator-size(230, 185);
        @include configurator-position(1440, 2680);
        @include transform(rotateZ(90deg));
    }
    65%
    {
        @include configurator-piece('Square_3.png');
        @include configurator-size(201, 162);
        @include configurator-position(1450, 2700);
        @include transform(rotateZ(0));
    }
    100%
    {
        @include configurator-position(2200, 3000);
        @include transform(rotateZ(90deg));
    }
}

@keyframes output-triangle
{
    0%
    {
        @include configurator-piece('Triangle_1.png');
        @include configurator-size(278, 212);
        @include configurator-position(1180, 1850);
    }
    49.9%
    {
        @include configurator-piece('Triangle_1.png');
        @include configurator-size(278, 212);
        @include configurator-position(1180, 2475);
    }
    50%
    {
        @include configurator-piece('Triangle_2.png');
        @include configurator-size(224, 264);
        @include configurator-position(1240, 2535);
        @include transform(rotateZ(0));
    }
    69.9%
    {
        @include configurator-piece('Triangle_2.png');
        @include configurator-size(224, 264);
        @include configurator-position(1425, 2665);
        @include transform(rotateZ(130deg));
    }
    100%
    {
        @include configurator-position(2000, 2900);
        @include transform(rotateZ(270deg));
    }
}

@keyframes conveyor-a
{
    0%
    {
        @include configurator-position(1360, 2475, $z-low);
    }
    50%
    {
        @include configurator-position(1450, 2530, $z-low);
    }
    100%
    {
        @include configurator-position(1540, 2460, $z-low);
    }
}

@keyframes conveyor-b
{
    0%
    {
        @include configurator-position(1360, 2470, $z-low);
    }
    25%
    {
        @include configurator-position(1405, 2510, $z-low);
    }
    50%
    {
        @include configurator-position(1450, 2520, $z-low);
    }
    75%
    {
        @include configurator-position(1490, 2500, $z-low);
    }
    100%
    {
        @include configurator-position(1530, 2450, $z-low);
    }
}

@keyframes conveyor-c
{
    0%
    {
        @include configurator-position(1360, 2465, $z-low);
    }
    25%
    {
        @include configurator-position(1405, 2510, $z-low);
    }
    50%
    {
        @include configurator-position(1450, 2520, $z-low);
    }
    75%
    {
        @include configurator-position(1490, 2500, $z-low);
    }
    100%
    {
        @include configurator-position(1530, 2460, $z-low);
    }
}

@keyframes conveyor-d
{
    0%
    {
        @include configurator-position(1360, 2465, $z-low);
    }
    25%
    {
        @include configurator-position(1405, 2510, $z-low);
    }
    50%
    {
        @include configurator-position(1450, 2520, $z-low);
    }
    75%
    {
        @include configurator-position(1490, 2500, $z-low);
    }
    100%
    {
        @include configurator-position(1530, 2460, $z-low);
    }
}

@keyframes roller-spin
{
    0%
    {
        @include transform(rotateZ(0deg));
    }
    100%
    {
        @include transform(rotateZ(360deg));
    }
}

$n: 32;
@keyframes needle-spin-a
{
    @for $i from 0 through $n - 1
    {
        $b: $i * 100% / $n;
        $deg: random(160) - 80deg;
        #{$b}
        {
            @include transform(rotateZ($deg));
        }
        @if $b == '0%'
        {
            100%
            {
                @include transform(rotateZ($deg));
            }
        }
    }
}

$n: 40;
@keyframes needle-spin-b
{
    @for $i from 0 through $n - 1
    {
        $b: $i * 100% / $n;
        $deg: random(160) - 80deg;
        #{$b}
        {
            @include transform(rotateZ($deg));
        }
        @if $b == '0%'
        {
            100%
            {
                @include transform(rotateZ($deg));
            }
        }
    }
}

$n: 24;
@keyframes needle-spin-c
{
    @for $i from 0 through $n - 1
    {
        $b: $i * 100% / $n;
        $deg: random(160) - 80deg;
        #{$b}
        {
            @include transform(rotateZ($deg));
        }
        @if $b == '0%'
        {
            100%
            {
                @include transform(rotateZ($deg));
            }
        }
    }
}
