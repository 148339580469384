// Intro animation
$intro-p-fill-color: $white;
$intro-text-font-color: $white;
$intro-text-font-size: .8vw;

.intro-animation-outside
{
    @include position(fixed, 0 null null 0);

    z-index: 9999;

    width: 100%;
    height: 100%;

    pointer-events: none;

    .intro-animation
    {
        @include position(absolute, -300vh 0 null 0);

        font-size: 96px;

        z-index: 6000;

        overflow: hidden;

        height: 600vh;
        margin: 0 auto;

        transform: skewY(45deg);
        animation: unmask 2s reverse 1s forwards;
        text-align: center;

        background: $purple;

        .intro-animation-inside
        {
            @include position(absolute, 300vh 0 null 0);

            margin: 0 auto;

            transform: skewY(-45deg);

            color: $white;

            .intro-animation_container
            {
                display: flex;

                width: 100%;
                height: 100vh;

                background: $purple;

                align-items: center;

                .intro-animation_wrapper
                {
                    position: relative;

                    width: 100%;
                    height: auto;
                    margin: 0 auto;

                    .svg_wrapper
                    {
                        position: relative;

                        max-width: 40vw;
                        height: auto;
                        margin: 0 auto;

                        /**
                         * iPad with portrait orientation.
                         */
                        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
                        {
                            max-width: 100%;
                        }

                        /**
                         * iPad with landscape orientation.
                         */
                        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)
                        {
                            max-width: 100%;
                        }

                        @media (max-width: 767px)
                        {
                            max-width: 80vw;
                        }

                        @media all and (-ms-high-contrast:none)
                        {
                            height: 500px; /* IE10 */
                            *::-ms-backdrop
                            {
                                height: 500px;
                            } /* IE11 */
                        }

                        .color
                        {
                            transform-origin: center center;
                            animation: p-fade-in 2s ease, p-fade-up 2s ease forwards;

                            opacity: 0;

                            animation-fill-mode: forwards;
                            fill: $intro-p-fill-color;
                        }

                        .intro-text_wrapper
                        {
                            @include position(absolute, 50% 0 0 0);
                            @include transform(translateY(-50%));

                            display: block;

                            max-width: 40vw;
                            max-height: 20px;
                            margin: 0 auto;

                            transform-origin: center center;

                            @media (max-width: 767px)
                            {
                                max-width: 90vw;
                            }

                            .intro-text
                            {
                                @include font-sofia-pro;

                                font-size: $intro-text-font-size;

                                animation: p-text-fade-in 1s ease, p-text-fade-out 2s ease;
                                text-align: center;
                                letter-spacing: 4px;

                                opacity: 0;
                                color: $intro-text-font-color;

                                @media (max-width: 767px)
                                {
                                    font-size: 2vw;
                                    line-height: 2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.touch.safari-10
{
    .svg_wrapper
    {
        svg
        {
            max-width: 40%;

            @media (max-width: 480px)
            {
                max-width: 100%;
            }
        }
    }
}


//animations
@keyframes p-fade-in
{
    0%
    {
        opacity: 0;
    }
    50%
    {
        opacity: 0;
    }
    100%
    {
        opacity: .09;
    }
}

@keyframes p-text-fade-in
{
    0%
    {
        opacity: 0;
    }
    50%
    {
        opacity: 1;
    }
    100%
    {
        opacity: 1;
    }
}

@keyframes p-text-fade-out
{
    0%
    {
        opacity: 1;
    }
    50%
    {
        opacity: 1;
    }
    100%
    {
        opacity: 0;
    }
}

@keyframes p-fade-up
{
    0%
    {
        opacity: .09;
    }
    66%
    {
        opacity: .09;
    }
    100%
    {
        opacity: 1;
    }
}

@keyframes mask
{
    0%
    {
        height: 600vh;
    }
    100%
    {
        height: 600vh;
    }
}

@keyframes unmask
{
    0%
    {
        height: 12vh;
    }
    100%
    {
        height: 600vh;
    }
}
