.expertise
{
    background-color: $light-grey;
    .solutions
    {
        background-color: $white;
        h2
        {
            line-height: 1.2;

            margin-bottom: 32px;
        }
    }
}
.services-list
{
    @include columnizer(4 4 4 4);

    text-align: center;
    &.two
    {
        @include columnizer(4 4, 4 0);
        @media($phone-only)
        {
            @include columnizer(16);
        }
    }

    @media($tablet-only)
    {
        @include columnizer(8 8);
    }

    @media($phone-only)
    {
        @include columnizer(16);
    }
    .service-item
    {
        @include padding(32px 16px);
        @include font-sofia-pro-bold;

        font-size: 20px;
        line-height: 26px;

        display: block;

        color: $grey;
        &:hover
        {
            box-shadow: 0 2px 6px rgba($black, .25);
            .more-details
            {
                opacity: 1;
            }
        }
        img
        {
            margin-bottom: 24px;
        }
        .more-details
        {
            @include transition(all .5s ease);

            font-size: 14px;
            line-height: 26px;

            margin-top: 16px;

            letter-spacing: 3px;
            text-transform: uppercase;

            opacity: 0;
            color: $purple;
            &:after
            {
                @include fontello($icon-right);

                margin-left: 8px;
            }
        }
    }
}



.content
{
    &.bg-blue
    {
        color: $white;
        background-color: $light-grey;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p
        {
            color: $white;
        }
    }
    &.bg-red
    {
        color: $white;
        background-color: #e73127;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p
        {
            color: $white;
        }
    }
    // &.bg-gray
    // {
    //     background-color: $light-grey;
    // }
    &.service
    {
        padding: 110px 0 200px 0;
    }
}

.related-content
{
    clear: both;

    a
    {
        text-decoration: none;
    }
    .large-12
    {
        max-width: 1000px;
        margin: 0 auto;
    }

    @media ($phone-only)
    {
        .container
        {
            padding: 0 20px;
        }
    }
    @media ($phone-only)
    {
        .row
        {
            display: block;
        }
        .medium-4
        {
            float: none;

            width: 100%;
            .container
            {
                text-align: center;
            }
        }
    }
}

.related-service
{
    font-family: $header-font-family;
    font-size: 1.25rem;
    line-height: 3rem;

    margin-top: 20px !important;

    .columns
    {
        margin-bottom: 20px;

        a
        {
            text-decoration: none;
        }
    }
}

.related-blog
{
    font-family: $header-font-family;

    display: flex;
    clear: both;

    max-width: 1000px;
    margin: 0 auto;
    margin-top: 20px !important;

    .medium-4
    {
        float: left;

        width: 33.3333333%;
    }
}

.content
{
    ul.small-block-grid-1.medium-block-grid-3.large-block-grid-3
    {
        margin-left: 0;
        padding-left: 0;
        @media($tablet-up)
        {
            li
            {
                + li
                {
                    margin-left: 30px;
                }
            }
        }
    }
    &.video-block
    {
        .video-description
        {
            @media($phone-only)
            {
                h2
                {
                    padding-top: 20px;
                }
            }
        }
        .video-left
        {
            > .video-left-container
            {
                @media ($tablet-only)
                {
                    padding-right: 30px;
                }
            }
            > .video-description
            {
                @media ($tablet-only)
                {
                    padding-left: 30px;
                }
            }
        }
        .video-right
        {
            > .video-right-container
            {
                @media ($tablet-only)
                {
                    padding-left: 30px;
                }
            }
            > .video-description
            {
                @media ($tablet-only)
                {
                    padding-right: 30px;
                }
            }
        }
    }

    &.feature-block
    {
        clear: both;

        h1
        {
            line-height: 4.0rem;
        }
        h2
        {
            @media($phone-only)
            {
                margin-top: 20px;
            }
        }
        .row
        {
            width: 100%;
            max-width: 1000px;
            margin: 0 auto;
        }

        .hide-for-small-only
        {
            display: none;
            @media($phone-only)
            {
                display: block;
            }
        }
        .left-image-container
        {
            float: left;

            width: 50%;

            text-align: center;
            @media ($tablet-only)
            {
                //padding-right: 30px;
            }
            > img
            {
                @media (max-width: 480px)
                {
                    margin-bottom: 20px;
                }
            }
        }
        .image-left
        {
            float: right;

            width: 50%;
        }
        .right-image-container
        {
            float: right;

            width: 50%;

            text-align: center;
            @media ($tablet-only)
            {
                //padding-left: 30px;
            }
        }
        .image-right
        {
            float: left;

            width: 50%;
        }
        .left-image-container,
        .right-image-container
        {
            img
            {
                max-width: 100%;
                height: auto;
                padding: 0 50px;
                @media ($phone-only)
                {
                    padding: 0;
                }
            }
            @media ($phone-only)
            {
                float: none;

                margin: 0 auto;
                padding-bottom: 30px;
            }
        }
        .description
        {
            &.image-right
            {
                @media ($phone-only)
                {
                    h1,
                    p
                    {
                        padding: 0 30px;
                    }
                }
                @media ($phone-only)
                {
                    float: none;
                    clear: both;

                    width: 100%;
                }
            }
            &.image-left
            {
                @media ($phone-only)
                {
                    h1,
                    p
                    {
                        padding: 0 30px;
                    }
                }
                @media ($phone-only)
                {
                    float: none;
                    clear: both;

                    width: 100%;
                }
            }
        }
    }
    &.speed-bump
    {
        position: relative;

        @media ($phone-only)
        {
            .underline
            {
                width: 100%;
                margin-left: 0;
            }
        }

        &.left-image-container,
        &.right-image-container
        {
            @media($phone-only)
            {
                padding-top: 0 !important;
                padding-bottom: 0;
                h2
                {
                    margin-top: 0;
                }
            }
        }
        .medium-offset-6
        {
            margin-left: 50%;
            @media ($phone-only)
            {
                margin-left: auto;
            }
        }
        .button.white:hover
        {
            color: $black;
            background-color: $white;
        }
        .speed-bump-overlay
        {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-color: $white;
        }
        .speed-bump-left-image
        {
            position: absolute;
            top: 0;
            right: 55%;
            bottom: 0;
            left: 0;

            background-position: top center;
            -webkit-background-size: cover;
               -moz-background-size: cover;
                 -o-background-size: cover;
                    background-size: cover;
            @media($phone-only)
            {
                position: relative;

                min-height: 300px;
                margin-bottom: 30px;
            }
        }
        .speed-bump-right-image
        {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 55%;

            background-position: top center;
            -webkit-background-size: cover;
               -moz-background-size: cover;
                 -o-background-size: cover;
                    background-size: cover;
            @media($phone-only)
            {
                position: relative;
                left: 0;

                min-height: 300px;
                margin-top: 30px;
                margin-bottom: 0;
            }
        }
    }
    &.image-grid
    {
        padding-top: 0 ;
        .row
        {
            ul
            {
                display: -webkit-flex;
                display:         flex;
                        flex-direction: row;

                list-style-type: none;

                text-align: center;

                -webkit-flex-direction: row;
                @media($phone-only)
                {
                            flex-direction: column;

                    margin: 0;

                    -webkit-flex-direction: column;
                }
                li
                {
                    float: left;

                    margin-bottom: 1em;

                    text-align: center;

                    flex-grow: 1;

                    @media($phone-only)
                    {
                        margin-bottom: 2em;
                    }


                    //vertically center trick
                    &::before
                    {
                        display: inline-block;

                        height: 100%;

                        content: '';
                        vertical-align: middle;
                    }


                    img
                    {
                        width: auto;
                        max-width: 10em;
                        height: auto;
                        max-height: 10em;
                    }
                }
            }
        }
    }
}

.service
{
    .filtering
    {
        background-color: $yellow;

        .row
        {
            width: 100%;
            max-width: 803px;
            margin: 0 auto;
        }
    }

    .content
    {
        display: flow-root;
        clear: both;
        a
        {
            @include font-sofia-pro;

            font-size: 2.0rem;
            font-weight: 700;
        }
        h3
        {
            @include font-sofia-pro;
            @include tracking (0);

            font-size: 2.0rem;
            line-height: 26px;

            text-transform: initial;

            color: $black-50;
        }
        &.flip
        {
            .row
            {
                @media($tablet-up)
                {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
        .row
        {
            display: table;

            max-width: 1000px;
            margin: 0 auto;
            .columns
            {
                p,
                h1,
                h2,
                h3,
                .new-button
                {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
        }
    }
    .columns
    {
        @media($phone-only)
        {
            text-align: center;

            ul
            {
                text-align: left;
            }
        }
        img.icon
        {
            @media($phone-only)
            {
                max-width: 180px;
            }
        }
    }
    &.related-work
    {
        padding: 60px 0 0 0;
        .row.full.thumbs.service
        {
            padding-top: 60px;
        }
    }
    .related-work
    {
        .row
        {
            &.thumbs
            {
                margin-top: 30px;

                a
                {
                    .item
                    {
                        position: relative;

                        float: left;

                        height: 350px;
                        padding: 0;
                        @media($phone-only)
                        {
                            width: 100%;
                        }
                        @media($tablet-up)
                        {
                            width: 33.3333333333%;
                        }
                        .container
                        {
                            display: flex;

                            height: 100%;

                            text-align: center;

                            background-repeat: no-repeat;
                            background-position: center;
                            -webkit-background-size: cover;
                               -moz-background-size: cover;
                                 -o-background-size: cover;
                                    background-size: cover;

                            align-items: center;
                            justify-content: center;
                        }
                        .title
                        {
                            @include transform(translateY(-50%));

                            position: absolute;
                            z-index: 100;
                            top: 50%;

                            display: block;
                            display: block;
                            zoom: 1;

                            width: 100%;

                            text-align: center;
                            span
                            {
                                display: inline-block;

                                height: 100%;

                                vertical-align: middle;
                            }
                            .big-title
                            {
                                @include font-sofia-pro;

                                font-size: 3.2rem;
                            }
                        }
                        .logo
                        {
                            @include transition(all .5s ease);

                            max-width: 200px;
                            margin: 0 auto;

                            vertical-align: middle;

                            opacity: 1;
                            &.vertist
                            {
                                max-height: 50px;
                            }
                            &.uw
                            {
                                max-height: 70px;
                            }
                        }
                        .black-overlay
                        {
                            @include transition(all .5s ease);

                            position: absolute;
                            top: 0;

                            display: block;

                            width: 100%;
                            height: 100%;

                            opacity: .3;
                            background-color: rgba(0,0,0,.5);
                        }
                        .button
                        {
                            @include transition(opacity .3s ease);
                            @include button-sofia-pro;

                            z-index: 100;

                            display: inline-block;

                            margin: 0;
                            padding: 10px 20px;

                            text-align: center;

                            opacity: 0;
                            border: 0;
                        }
                        &.text
                        {
                            h4
                            {
                                font-size: 27px;
                                font-weight: 600;

                                margin: 0 auto;
                                padding: 25px 0;

                                vertical-align: middle;

                                opacity: 1;
                                color: $white;
                            }
                        }
                        &:hover
                        {
                            .logo,
                            &.view-all h4
                            {
                                @include transition(opacity .3s ease);

                                opacity: 0;
                            }
                            .black-overlay
                            {
                                opacity: 1;
                            }
                            .button
                            {
                                //@include transform(scale(1) translate(-50%));
                                opacity: 1;
                                color: $dark-purple;
                                background-color: $yellow;
                            }
                        }
                    }
                }
            }
        }
    }
    h2
    {
        font-size: 2.625rem;
        @media($phone-only)
        {
            text-align: center;
        }
    }
    p
    {
        @include font-sofia-pro;

        font-size: 1.8rem;

        color: $black-50;
    }
    .dark
    {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p
        {
            color: $black-50;
        }
    }
    .intro
    {
        float: none;

        margin: 0 auto;
        padding-top: 120px;
        padding-bottom: 160px;
        @media ($phone-only)
        {
            margin-top: 0;
            padding-right: 20px;
            padding-left: 20px;
        }
        h6
        {
            letter-spacing: 1px;
        }
        .headline
        {
            font-size: 4.5rem;
            line-height: 1.2;

            color: $dark-purple;
            small
            {
                font-size: 10px;
                font-weight: 700;
                font-style: normal;
                line-height: 1.3;

                display: block;

                margin-top: .2rem;
                margin-bottom: .8rem;

                letter-spacing: 1px;
                text-transform: uppercase;

                text-rendering: optimizeLegibility;
                strong
                {
                    @include label-sofia-pro;

                    font-weight: 700;

                    color: $black-50;
                }
            }
            @media($phone-only)
            {
                font-size: 2.625rem;
            }
        }
        .headline-divider
        {
            width: 60px;
            margin: 50px auto;

            border-bottom: 2px solid $black-50;
        }
    }
    .pull-quote-section
    {
        clear: both;

        .row
        {
            max-width: 1000px;
            margin: 0 auto;
        }
        .pull-quote
        {
            p
            {
                @include h2-noe-display;

                letter-spacing: .05rem;
                @media($phone-only)
                {
                    font-size: 1.25rem;
                }
            }
            .quote
            {
                @include quote-text;
            }
            .attribution
            {
                @include font-sofia-pro;

                font-size: 2.0rem;
                font-weight: 200;
            }
        }
    }


    .related-blog-post
    {
        margin-top: 20px;
        margin-right: 20px;

        text-align: left;
        @media($tablet-up)
        {
            margin-left: 20px;
        }

        &:last-child
        {
            margin-right: 0;
            padding-right: 0;
        }
        .title
        {
            margin-bottom: 1rem;
            padding-right: 20px;
            a
            {
                @include font-sofia-pro;

                font-size: 2.0rem;
                font-weight: 700;
                line-height: 30px;
            }
        }
        p
        {
            @include t-copy;

            font-size: 1.5rem;

            padding-left: 0 !important;
        }
    }
}

.row
{
    &.expanded
    {
        max-width: 100% !important;

        h1,
        p
        {
            margin: 0 40px;
            padding-bottom: 10px;
        }

        img
        {
            padding: 0 !important;
        }

        .no-pad
        {
            padding: 0;
        }

        .row
        {
            margin-right: auto;
            margin-left: auto;
        }
    }
}
