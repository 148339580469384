
$icon-close-1: '\e801'; /* '' */
$icon-external: '\e802'; /* '' */
$icon-pause: '\e803'; /* '' */
$icon-stop: '\e804'; /* '' */
$icon-upload: '\e805'; /* '' */
$icon-mute: '\e806'; /* '' */
$icon-volume-2: '\e807'; /* '' */
$icon-plus: '\e808'; /* '' */
$icon-minus: '\e809'; /* '' */
$icon-group: '\e80a'; /* '' */
$icon-video: '\e80b'; /* '' */
$icon-phone: '\e80c'; /* '' */
$icon-check: '\e80d'; /* '' */
$icon-volume-3: '\e80e'; /* '' */
$icon-volume-1: '\e80f'; /* '' */
$icon-fullscreen-exit: '\e810'; /* '' */
$icon-fullscreen: '\e811'; /* '' */
$icon-download: '\e812'; /* '' */
$icon-close: '\e813'; /* '' */
$icon-browse-dots: '\e814'; /* '' */
$icon-plus-dots: '\e815'; /* '' */
$icon-arrow-dots-left: '\e816'; /* '' */
$icon-dots-down-arrow: '\e817'; /* '' */
$icon-dots-two-top: '\e818'; /* '' */
$icon-bullet-dots: '\e819'; /* '' */
$icon-chevron-left: '\e81a'; /* '' */
$icon-chevron-right: '\e81b'; /* '' */
$icon-dots-up-arrow: '\e81c'; /* '' */
$icon-play: '\e81d'; /* '' */
$icon-spin: '\e831'; /* '' */
$icon-down: '\f004'; /* '' */
$icon-up: '\f005'; /* '' */
$icon-right: '\f006'; /* '' */
$icon-left: '\f007'; /* '' */
$icon-facebook: '\f052'; /* '' */
$icon-twitter: '\f058'; /* '' */
$icon-googleplus: '\f05a'; /* '' */
$icon-blank: '\f0c8'; /* '' */
$icon-mail: '\f0e0'; /* '' */
$icon-linkedin: '\f0e1'; /* '' */
$icon-angle-left: '\f104'; /* '' */
$icon-audio: '\f130'; /* '' */
$icon-pinterest: '\f231'; /* '' */
$icon-calendar-check: '\f274'; /* '' */
