.products-bg
{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    height: 100%;

    background-color: $yellow;

    @media ($phone-and-tablet)
    {
        display: none;
    }
    &.half
    {
        width: 50%;
    }
    &.product
    {
        width: 375px;
    }
}

section.products
{
    .container
    {
        #scroll
        {
            position: absolute;
            z-index: -1;
            bottom: -175px;
            left: 50%;

            width: 225px;
            height: 225px;
            margin: 0 auto;
            padding-top: 13px;

            transition: all 250ms ease;
            transform: translateX(-50%);

            border: 1px solid rgba($black, .3);
            border-radius: 225px;
            background-color: rgba($black, 0);

            @media($phone-and-tablet)
            {
                position: fixed;
                right: 20px;
                bottom: 20px;
                left: auto;

                transform: translateY(200px);

                opacity: 0;
            }

            .scroll-container
            {
                position: relative;

                padding-top: 30px;

                &:before
                {
                    @include fontello($icon-dots-down-arrow);

                    font-size: 19px;

                    position: absolute;
                    top: 0;
                    left: 50%;

                    width: 19px;
                    height: 19px;

                    transform: translateX(-50%);

                    color: rgba($black, .3);
                }

                .text
                {
                    @include font-sofia-pro-bold;

                    font-size: 10px;
                    line-height: 0;

                    text-align: center;
                    letter-spacing: 4px;
                    text-transform: uppercase;

                    opacity: 1;
                    color: rgba($black, .3);
                }
            }

            &.up
            {
                z-index: 1;
                bottom: 1%;
                left: calc(50% - 35px);

                width: 30px;
                height: 30px;
                padding-top: 0;

                cursor: pointer;

                border: 1px solid rgba($black, 0);
                border-radius: 15px;
                background-color: rgba($black, .3);

                @media($phone-and-tablet)
                {
                    bottom: 20px;
                    left: auto !important;

                    width: 40px;
                    height: 40px;

                    transform: translateY(200px);

                    opacity: 1;
                    border: 1px solid rgba($yellow, 0);
                    border-radius: 20px;
                    background-color: $yellow;
                    box-shadow: rgba($black, .16) 2px -2px 6px;
                }

                &:hover
                {
                    @media($desktop-only)
                    {
                        background-color: rgba($black, .40);
                    }
                }

                .scroll-container
                {
                    padding-top: 0;

                    &:before
                    {
                        line-height: 60px;

                        top: 50%;
                        left: 0;

                        width: 30px;
                        height: 30px;

                        transform: rotate(180deg) translateY(-50%) translateX(0);
                        text-align: center;

                        color: $yellow;

                        @media($phone-and-tablet)
                        {
                            line-height: 80px;

                            width: 40px;
                            height: 40px;

                            color: $dark-purple;
                        }
                    }
                    .text
                    {
                        opacity: 0;
                    }
                }
            }
        }
        //left side nav
        .nav
        {
            position: absolute;
            top: 0;

            width: 50%;
            height: 100vh;

            pointer-events: none;

            @media ($phone-and-tablet)
            {
                position: relative;

                width: auto;
                height: auto;

                pointer-events: auto;

                background-color: $yellow;
            }

            .nav-container
            {
                padding-top: 170px;
                padding-bottom: 40px;
                padding-left: 40px;

                @media ($tablet-only)
                {
                    @include padding(110px 20px 20px 20px);
                }
                @media ($phone-only)
                {
                    @include padding(70px 20px 10px 20px);
                }

                .nav-wrapper
                {
                    h1
                    {
                        @include t-h1;
                        @include word-break-hyphens;

                        margin-bottom: 50px;

                        color: $dark-purple;

                        @media ($phone-and-tablet)
                        {
                            margin-bottom: 20px;
                        }
                        @media($desktop-only)
                        {
                            margin-right: 4rem;
                        }
                    }

                    .browse#browse-btn
                    {
                        display: none;

                        cursor: pointer;

                        @media ($phone-and-tablet)
                        {
                            display: block;
                        }

                        &.open
                        {
                            a
                            {
                                &:before
                                {
                                    transform: rotate(0deg) scale(1);
                                }
                                &:after
                                {
                                    opacity: 0;
                                }
                            }
                        }

                        a
                        {
                            @include t-nav-button;

                            position: relative;

                            padding-top: 2px;
                            padding-right: 5px;
                            padding-bottom: 2px;

                            pointer-events: visible;

                            color: $purple;

                            @extend .noselect;

                            &:before
                            {
                                @include fontello($icon-browse-dots);

                                font-size: 1.2rem;

                                position: absolute;
                                top: 9px;
                                right: -13px;

                                width: 12px;
                                height: 12px;

                                transition: all 250ms ease-out;
                                transform: rotate(45deg) scale(.8);

                                color: $purple;
                            }
                            &:after
                            {
                                position: absolute;
                                top: 12px;
                                right: -10px;

                                width: 5px;
                                height: 5px;

                                content: '';
                                transition: all 250ms ease-out;

                                opacity: 1;
                                border-radius: 2px;
                                background-color: $yellow;
                            }
                        }
                    }

                    nav
                    {
                        ul
                        {
                            padding-left: 0;

                            list-style: none;

                            @media ($phone-and-tablet)
                            {
                                @include animate;

                                visibility: hidden;

                                box-sizing: border-box;

                                opacity: 0;

                                &.show
                                {
                                    visibility: visible;

                                    opacity: 1;
                                }
                            }

                            li
                            {
                                + li
                                {
                                    margin-top: 6px;
                                }
                                a
                                {
                                    @include t-nav-products;

                                    display: inline-block; // Microsoft Edge fix

                                    text-decoration: none;
                                    pointer-events: visible;

                                    color: rgba($subnav-color, .5) !important;

                                    &:hover
                                    {
                                        color: rgba($purple, 1) !important;
                                    }
                                }
                                &.active
                                {
                                    a
                                    {
                                        font-weight: 700 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        //right side blocks content builder
        #blocks-builder
        {
            overflow-x: hidden;
            overflow-y: scroll;

            max-width: 100%;
            height: 100vh;
            padding-left: 50%;

            @media ($phone-and-tablet)
            {
                overflow: hidden;

                height: auto;
                padding-left: 0;
            }

            .block
            {
                @media($phone-and-tablet)
                {
                    overflow: hidden;

                    width: calc(100% - 20px - 20px);
                    padding-right: 20px;
                    padding-left: 20px;
                }
                @media($desktop-only)
                {
                    width: calc(100% - 7% - 7%);
                    padding-right: 7%;
                    padding-left: 7%;
                }
            }
        }
    }
}

// Product Individual
section.products.product
{
    .container
    {
        //optional scroll
        #scroll
        {
            left: 375px;

            transform: translateX(-112px);

            opacity: 0;

            @media ($phone-and-tablet)
            {
                left: auto;

                transform: translateX(200px);
            }

            &.up
            {
                bottom: 10px;
                left: calc(375px - 50px);

                transform: translateX(0);

                opacity: 1;
            }
        }
        .nav
        {
            width: 375px;

            @media ($phone-and-tablet)
            {
                width: auto;
            }

            .nav-container
            {
                .nav-wrapper
                {
                    a#back-to
                    {
                        position: relative;

                        display: inline-block; // Microsoft Edge fix

                        text-decoration: none;
                        pointer-events: visible;

                        color: $black-30;

                        + .title
                        {
                            margin-top: 35px;
                        }

                        &:hover
                        {
                            &:before
                            {
                                transform: translateX(3px) translateY(-50%);

                                color: $black-50;
                            }
                            span
                            {
                                color: $black-50;
                            }
                        }

                        &:before
                        {
                            @include fontello($icon-arrow-dots-left);

                            font-size: 2rem;
                            line-height: 2rem;

                            position: absolute;
                            top: calc(50%);

                            width: 30px;
                            height: 20px;

                            transition: all 250ms ease;
                            transform: translateY(-50%);
                        }

                        span
                        {
                            @include font-sofia-pro-bold;

                            font-size: 1.1rem;
                            line-height: 2rem;

                            padding-left: 35px;

                            transition: all 250ms ease;
                            letter-spacing: 11px;
                            text-transform: uppercase;
                        }
                    }

                    .title
                    {
                        @include t-h2;
                        @include word-break-hyphens;

                        padding-right: 40px;

                        color: $dark-purple;

                        + .browse
                        {
                            margin-top: 20px;
                        }
                    }

                    nav
                    {
                        margin-top: 90px;

                        @media ($phone-and-tablet)
                        {
                            margin-top: 0;
                        }

                        ul
                        {
                            li
                            {
                                a
                                {
                                    @include t-nav-products;

                                    position: relative;
                                    z-index: 1;

                                    display: inline-block;

                                    text-decoration: none;
                                    pointer-events: visible;

                                    color: $grey;

                                    &:hover
                                    {
                                        color: $purple;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #blocks-builder
        {
            padding-left: 375px;

            .blocks-builder__container
            {
                @media($phone-only)
                {
                    padding-top: 60px;
                }
                @media($tablet-only)
                {
                    padding-top: 110px;
                }
            }

            p
            {
                @include font-sofia-pro;

                &.intro-text
                {
                    font-size: 24px;

                    margin-top: 0;
                }
            }

            @media ($phone-and-tablet)
            {
                padding-left: 0;
            }

            .blocks-builder__container
            {
                .block
                {
                    > p
                    {
                        font-size: 18px;
                        line-height: 30px;
                    }
                    &.banner
                    {
                        //override banner.scss
                        background-color: $white;
                        &:after
                        {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* Lunch N Learn Tab */
#tab
{
    position: absolute;
    top: 230px;
    left: 375px;

    transform: translateX(-100%);
    pointer-events: none;

    .container
    {
        padding: 10px 45px 10px 20px;

        cursor: pointer;
        transform: rotate(-90deg);
        transform-origin: right top;
        text-align: right;
        pointer-events: visible;

        color: $white;
        border-radius: 0 0 10px 10px;
        background-color: $black;
        background-repeat: no-repeat;
        background-position: calc(100% - 5px) 0;

        span
        {
            @include font-sofia-pro-bold;

            font-size: 12px;

            text-decoration: none;
            letter-spacing: 6px;
            text-transform: uppercase;

            color: $yellow;
        }
    }
}
